import React from "react";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line react/display-name
const CustomInput = forwardRef((props, ref) => {
  
  const { t } = useTranslation();

  return (
    <input
      className="form-control form-control-icon"
      ref={ref}
      {...props}
      placeholder={t(props.placeholder)}
      aria-describedby="addon-wrapping"
    />
  );
});

export default CustomInput;
