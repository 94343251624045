import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import defaultAvatar from "../../../assets/images/users/default.jpg";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import { useTranslation } from "react-i18next";
import CustomTableHeading from "../../Custom/CustomTableHeading";

const RecentOrders = ({data}) => {

  const [orders, setOrders] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    setOrders(data.last_orders)
  }, [data])

  const history = useHistory();
  
  return (
    <React.Fragment>
      <Col>
        <Card>
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">{t('Recent Orders')}</h4>
          </CardHeader>

          <CardBody>
            <div className="table-responsive table-card">
              <table className="table table-borderless table-centered align-middle table-nowrap mb-0">
                <thead className="text-muted table-light">
                  <tr>
                    <th scope="col">
                      <CustomTableHeading>Code</CustomTableHeading>
                    </th>
                    <th scope="col">
                      <CustomTableHeading>Status</CustomTableHeading>
                    </th>
                    <th scope="col">
                      <CustomTableHeading>Delivery</CustomTableHeading>
                    </th>
                    <th scope="col">
                      <CustomTableHeading>Paid</CustomTableHeading>
                    </th>
                    <th scope="col">
                      <CustomTableHeading>Payment Method</CustomTableHeading> 
                    </th>
                    <th scope="col">
                      <CustomTableHeading>User</CustomTableHeading>
                    </th>
                    <th scope="col">
                      <CustomTableHeading>Date</CustomTableHeading>
                    </th>
                    <th scope="col">
                      <CustomTableHeading>Total</CustomTableHeading>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(orders || []).map((item, key) => (
                    <tr 
                      key={key} 
                      className="homepage-table-row"
                      onClick={() => history.push(getPathname(`orders/update/${item.id}`))}
                    >
                      <td className="fw-medium link-primary">
                          {item.code}
                      </td>

                      <td>{item.status}</td>
                      <td>
                        <span className="text-muted">{item.delivery}</span>
                      </td>
                      <td>
                        {item.payment?.name || '-'}
                      </td>
                      <td>
                        <span className="text-muted fs-13 ms-1">
                          {t(item.payment_method)}
                        </span>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 me-2">
                            <img
                              src={item.user?.image || defaultAvatar}
                              alt=""
                              className="avatar-xs rounded-circle"
                            />
                          </div>
                          <div className="flex-grow-1">{item.user}</div>
                        </div>
                      </td>
                      <td>
                        <h5 className="fs-14 fw-medium mb-0">
                          {/*item.rating*/}
                          <span className="text-muted fs-13 ms-1">
                            {item.created_at}
                          </span>
                        </h5>
                      </td>
                      <td>
                        <span className="text-muted fs-13 ms-1">
                          {item.total}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default RecentOrders;
