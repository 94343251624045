import axiosInstance from "..";

export const getCategoriesWithFoods = (params) => {
  return axiosInstance.get('/page_menu/categories', {
    params
  });
};

export const getMenuPages = (params) => {
  return axiosInstance.get('/page_menu', {
    params
  });
};

export const createMenupage = (data) => {
  return axiosInstance.post('/page_menu', data);
};

export const updateMenuPage = (data) => {
  return axiosInstance.post('/page_menu/update', data);
};

export const deleteMenuPage = (id) => {
  return axiosInstance.delete(`/page_menu/${id}`);
};

export const addPageImage = (id, data) => {
  return axiosInstance.post(`/page_menu/pageImages/${id}`, data);
};

export const updateMenuImage = (id, data) => {
  return axiosInstance.post(`/page_menu/updateMenuImage/${id}`, data);
};

export const updateFoodMenuImage = (id, data) => {
  return axiosInstance.post(`/page_menu/food/updateMenuImage/${id}`, data);
};