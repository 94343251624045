import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const CustomTableHeading = ({ children, className = "", ...rest }) => {

  const {t} = useTranslation();

  return (
    <span className={classNames("font-weight-bold fs-13", className)} {...rest}>
      {typeof children === 'string' ? t(children) : children}
    </span>
  );
};

export default CustomTableHeading;
