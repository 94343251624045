import React from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import HomeSliderForm from "../../../Components/Settings/HomeSliders/Form";
import PageContainer from "../../../Components/Layout/PageContainer";
import { useFetchByLang } from "../../../hooks/useFetchByLang";

const CreateHomeSlider = () => {

  const {language, setLanguage} = useFetchByLang();

  return (
    <PageContainer>
      <BreadCrumb
        pageTitle="Home Sliders"
        title="Create"
        to="settings/home-sliders"
      />
      <HomeSliderForm 
        type="create" 
        language={language}
        setLanguage={setLanguage}
      />
    </PageContainer>
  );
};

export default CreateHomeSlider;
