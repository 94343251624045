import React from "react";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line react/display-name
const CustomTextArea = forwardRef((props, ref) => {
  
  const { t } = useTranslation();

  return (
    <textarea
        className="form-control"
        ref={ref}
        {...props}
        placeholder={t(props.placeholder)}
    />
  );
});

export default CustomTextArea;
