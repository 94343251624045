import axiosInstance from "..";

export const statisticsApi = {

    getAll: () => {
        return axiosInstance.get("/settings/stats");
    },

    revenue: () => {
        return axiosInstance.get("/settings/diagram");
    },
    customers: () => {
        return axiosInstance.get("/settings/customerStats");
    }

}