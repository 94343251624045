import React, { useState, useEffect } from "react";
import useActions from "../../../hooks/useActions";
import OrderCloseTab from "../../Orders/OnlineSettings/OrderCloseTab";
import { useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { getOnlineSettingsApi, setCloseImage, setOnlineSettingsApi } from "../../../apis/Orders/onlineSettingsApis";
import toastAlert from "../../../helpers/toastAlert";
import validateOpeningHour from "../../../validations/validateOpeningHour";
import { useFetchByLang } from "../../../hooks/useFetchByLang";
import SetupActions from "../SetupActions";

const OnlineOrderSettingsSetup = ({ id, next, skip }) => {

  const { register, reset, handleSubmit } = useForm({});
  
  const [closeImg, setCloseImg] = useState({
    url: "",
    file: null,
  })

  const [openingHours, setOpeningHours] = useState([]);

  const [data, setData] = useState(null);

  const { isLoading: isSubmitting, onLoading, onFinally } = useActions();

  const { refetch } = useQuery(["order-online-settings"], () => getOnlineSettingsApi(language),
    {
      enabled: false,
      cacheTime: 0,
      onSuccess(data) {
        setForm({ data: data.data.data });
      },
    }
  );

  const { language } = useFetchByLang(refetch);

  const onSubmit = async (requestBody) => {
    onLoading();
    try {
      for (const openingHour of openingHours) {
        const error = validateOpeningHour({
          dayName: openingHour.day.name,
          times: openingHour.times,
        });
        if (error) {
          onFinally();
          return toastAlert({ type: 'error', message: error });
        }
      }
      const body = {
        ...requestBody,
        opening_hours: openingHours.map(({ id, open, times }) => {
          return {
            id,
            open,
            times: times.map((time) => {
              return {
                id: time.isNew ? null : time.id,
                start: time.start?.slice(0, 5),
                end: time.end?.slice(0, 5),
              };
            }),
          };
        }),
      };
      const { data: { data } } = await setOnlineSettingsApi(body, language);
      if (closeImg.file) {
        const file = closeImg.file;
        const formData = new FormData();
        const blob = new Blob([file], { type: file.type });
        formData.append('close_image', blob, file.name);
        formData.append('is_close_image_transparent', body.is_close_image_transparent ? 1 : 0);
         const { data: { data: resData } } =  await setCloseImage(formData);
          setCloseImg({
            id: resData?.id,
            name: resData?.name,
            url: resData?.link,
            file: null
          })
      }
      setForm({ data });
      next(id);
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      onFinally();
    }
  };

  const setForm = ({ data }) => {
    const { opening_hours, special_time, updated_at, ...rest } = data;
    setData(rest)
    reset(rest);
    setOpeningHours(opening_hours);
  };

  useEffect(() => {
    if (data?.close_image_file) {
      setCloseImg({
          id: data?.close_image_file?.id,
          name: data?.close_image_file?.name,
          url: data?.close_image_file?.link,
          file: null
      })
    }
  }, [data])

  return (
      <div>
        <OrderCloseTab
          setOpeningHours={setOpeningHours}
          openingHours={openingHours}
          register={register}
          closeImg={closeImg}
          setCloseImg={setCloseImg}
        />

        <SetupActions id={id} submit={handleSubmit(onSubmit)} skip={skip} isLoading={isSubmitting} />
      </div>
  );
};

export default OnlineOrderSettingsSetup;
