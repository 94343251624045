import React from "react";

const OrderHeadingItem = ({ value }) => {
  return (
    <div className="border border-dashed  py-2 px-3 text-capitalize">
      <h5 className="card-title flex-grow-1 mb-0">{value}</h5>
    </div>
  );
};

export default OrderHeadingItem;
