import React from 'react'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import PageContainer from '../../../Components/Layout/PageContainer'
import TableServiceForm from '../../../Components/Orders/TableServices/Form'

const CreateTableService = () => {

  return (
    <PageContainer>
        <BreadCrumb pageTitle="Table Services" title="Create" to="orders/table-services"/>
        <TableServiceForm 
          type="create"
        />
    </PageContainer>
  )
}

export default CreateTableService