import React from 'react'
import SetupActions from '../SetupActions'
import { useSlider } from '../../../hooks/useQueryHelpers'
import BootstrapTable from '../../Common/BootstrapTable';
import CustomButton from '../../Custom/CustomButton';
import useFormModal from '../../../hooks/useFormModal';
import HomeSliderModal from './Modal';
import StatusIcon from '../../Common/StatusIcon';

const HomeslidersSetup = ({ id, next, skip }) => {

  const { sliders, createSlider, updateSlider } = useSlider();

  const { isOpen: modalOpened, toggleModal, type, openModal, initialValues } = useFormModal();

  const columns = [
    {
      name: 'Name',
      selector: row => row.title,
    },

    {
      name: 'Active',
      selector: row => <StatusIcon status={row.active} />,
    }
  ]

  return (
    <div>

      <HomeSliderModal 
          type={type}
          isOpen={modalOpened}
          toggleModal={toggleModal}
          initialValues={initialValues}
          createSlider={createSlider}
          updateSlider={updateSlider}
      />

      <div className='hstack gap-2'>
          <CustomButton 
            className="btn-success"
            onClick={() => openModal("create")}
          >
            Create
          </CustomButton>
      </div>

      <div className='mt-3'>
        <BootstrapTable 
          columns={columns}
          data={sliders}
          clickableRows
          onRowClick={(row) => openModal('update', row)}
        />
      </div>

      <SetupActions id={id} submit={() => next(id)} skip={skip} />

    </div>
  )
}

export default HomeslidersSetup