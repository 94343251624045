export const sortFromIndex = (idxA, idxB) => {
  
    if (idxA !== -1 && idxB !== -1) {
        return idxA - idxB; // Compare the indices
      } else if (idxA === -1 && idxB === -1) {
        // If neither item is found, leave them in their current order
        return 0;
      } else if (idxA === -1) {
        // If breadA is not found, move breadB to the front
        return 1;
      } else {
        // If breadB is not found, move breadA to the front
        return -1;
      }
}