export const triggerSound = async (soundName) => {
    try {
      const sound = await import(`../assets/sounds/${soundName.toLowerCase()}.mp3`);
      if (sound) {
        const audio = new Audio(sound.default);
        // Create an AudioContext on user interaction
        const context = new (window.AudioContext || window.webkitAudioContext)();
        // Play the audio using the created context
        const source = context.createMediaElementSource(audio);
        source.connect(context.destination);
        audio.play();
      }
    } catch (error) {
      console.log('error', error);
    }
  };