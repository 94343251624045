import React, { useState } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import PagesMenuForm from "../../../Components/Settings/PagesMenu/Form";
import PageContainer from "../../../Components/Layout/PageContainer";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getOnePageMenuApi } from "../../../apis/Settings/pageMenuApi";
import { useFetchByLang } from "../../../hooks/useFetchByLang";

const UpdatePagesMenu = () => {
  const { id } = useParams();

  const [initValues, setInitValues] = useState(null);

  const { refetch, isLoading, isRefetching } = useQuery(["pages-menu", id],  () => getOnePageMenuApi(id, language),
    {
      enabled: false,
      cacheTime: 0,
      onSuccess(data) {
        const {
          id,
          title,
          active,
          class: c,
          label,
          file,
          icon,
          top_nav,
          footer_nav,
          same_page,
          text,
          seo_title,
          seo_keywords,
          seo_description,
        } = data.data.data;
        setInitValues({
          form: {
            id,
            title,
            class: c,
            label,
            active: !!active,
            seo_title: seo_title || "",
            seo_keywords: seo_keywords || "",
            seo_description: seo_description || "",
            top_nav: !!top_nav,
            footer_nav: !!footer_nav,
            same_page: !!same_page,
            text,
          },
          icon: {
            file: null,
            url: icon || "",
            ...(file
              ? {
                  id: file.id,
                  name: file.name,
                }
              : {}),
          },
        });
      },
    }
  );

  const { language, setLanguage } = useFetchByLang(refetch);

  return (
    <PageContainer isLoading={isLoading || isRefetching}>
      <BreadCrumb
        pageTitle="Pages Menu"
        title="Update"
        to="settings/pages-menu"
      />
      {initValues && (
        <PagesMenuForm
          type="update"
          initialValues={{
            form: initValues.form,
            icon: initValues.icon,
          }}
          language={language}
          setLanguage={setLanguage}
        />
      )}
    </PageContainer>
  );
};

export default UpdatePagesMenu;
