import React, { useEffect, useState } from 'react';

import CategoriesChart from './Chart';
import { useTranslation } from 'react-i18next';

const TopCategories = ({data}) => {

    const [chartData, setChartData] = useState(null);

    const { t } = useTranslation();

    useEffect(() => {
        if (data) {
            setChartData(data.top_cats?.map(cat => ({
                id: cat.id,
                name: cat.name,
                score: cat.count
            })))
        }
    }, [data])

    return (
        <React.Fragment>
                <div className="card">
                    <div className="card-header border-0 align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">{t('Top Categories')}</h4>
                    </div>
                    <div className="card-body">

                        <div id="portfolio_donut_charts" className="apex-charts" dir="ltr">
                            {chartData ? 
                                <CategoriesChart data={chartData} />
                            : <></>}
                        </div>
                    </div>
                </div>
        </React.Fragment>
    );
};

export default TopCategories;