import React from 'react'
import { useSelector } from 'react-redux'
import { isAllowed } from '../../../helpers/isAllowed'
import { getPathname } from '../../../Layouts/LayoutMenuData'
import DataTableContainer from '../../Common/DataTableContainer'
import TableImage from '../../Common/TableImage'
import CustomInput from '../../Custom/CustomInput'
import CustomTableHeading from '../../Custom/CustomTableHeading'

const DoughAndBreadTable = ({navigateToPage, pageSize, setPageSize, setSelectedItems, modal, setModal, isLoading, handleDelete, loading, name, setName}) => {

    const { doughAndBread } = useSelector(state => state.food);

    const { user } = useSelector(state => state.admin);

    const columns = [
        {
            name: <CustomTableHeading>ID</CustomTableHeading>,
            selector: row => row.id || '-',
            grow: -1,
        },

        {
            selector: row => <TableImage src={row.icon} />,
        },

        {
            name: 
            <CustomInput 
                placeholder='Name' 
                type="text" 
                value={name || ''}
                onChange={(e) => setName(e.target.value)}
                data-filtered={!!name}
            />,
            selector: row => row.name || '-',
        },

        {
            name: <CustomTableHeading>Description</CustomTableHeading>,
            selector: row => row.name || '-',
        },

        {
            name: <CustomTableHeading>Note</CustomTableHeading>,
            selector: row => row.name || '-',
        },
   
    ]

  return (
    <DataTableContainer 
        loading={loading}
        data={doughAndBread.list}
        columns={columns}
        paginationServer
        navigateToPage={navigateToPage}
        paginationTotalRows={doughAndBread.total && doughAndBread.total}
        path={getPathname('food/dough-bread')}
        pageSize={pageSize}
        setPageSize={setPageSize}
        selectableRows={isAllowed(user)}
        selectItems={setSelectedItems}
        modal={modal}
        setModal={setModal}
        isDeleting={isLoading}
        handleDelete={handleDelete}
    />
  )
}

export default DoughAndBreadTable