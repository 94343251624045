import React, { useState } from 'react'
import CustomButton from '../../../../../Custom/CustomButton'
import { useFetchByLang } from '../../../../../../hooks/useFetchByLang';
import ItemModal from '../../../../Components/Update/ItemModal';

const AddComponentItem = ({ id, setData }) => {

  const [isOpen, setIsOpen] = useState(false);

    const toggleModal = () => {
        setIsOpen(!isOpen);
    }

    const onSubmit = (data) => {

      const { icon, file, component_id, ...rest } = data;
      
      setData(prev => prev.map(component => {
        if (component.component_id === id) return {
          ...component,
          items: [
            ...component.items,
              {
                ...rest,
                component_item_id: data.id,
                basic: 0,
                selected: false,
                price: data.price,
                price_extra: data.price_extra,
                default_qty: 1,
                max_qty: 1,
              }
          ]
        }
        return component
      }))
      toggleModal();
    }

    const [initialValues, setInitialValues] = useState(null);

    const { language } = useFetchByLang();

  return (
    <>
        <CustomButton 
          type="button" 
          className="btn-success btn-sm mx-2"
          onClick={toggleModal}
        >
            <i className="ri-add-line fs-16"></i>
        </CustomButton>

        <ItemModal 
            isOpen={isOpen}
            toggleModal={toggleModal}
            type="create"
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            language={language}
            componentId={id}
            afterSubmit={onSubmit}
        />
    </>
  )
}

export default AddComponentItem