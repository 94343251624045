import React from "react";
import { Col, Row } from "reactstrap";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";

const SmsSenderTab = ({ value, handleChange }) => {
  return (
    <Row>
      <Col xs={12}>
        <CustomLabel>Name</CustomLabel>
        <CustomInput onChange={handleChange} value={value} />
      </Col>
    </Row>
  );
};

export default SmsSenderTab;
