import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useState } from 'react'
import { getSocialLogins } from '../../apis/Settings/socialLoginsApi';
import PageContainer from '../../Components/Layout/PageContainer';
import { Card, CardHeader, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from "classnames";
import BreadCrumb from '../../Components/Common/BreadCrumb';
import SocialLoginForm from '../../Components/Settings/SocialLogins/Form';

const SocialLogins = () => {

    const [activeTab, setActiveTab] = useState(null);
    const [data, setData] = useState(null);

    const { isLoading, isRefetching } = useQuery(['social-logins'], getSocialLogins, {
        onSuccess({data: {data}}) {
            setData(data);
            setActiveTab(data[0].id)
        }
    })

  return (
    <PageContainer isLoading={isLoading || isRefetching}>
        <BreadCrumb pageTitle="Settings" title="Social Logins"/>
        <Card>
            <CardHeader>
                <Nav className="nav-tabs-custom card-header-tabs border-bottom-0">
                    {data?.map((item) => {
                    return (
                        <NavItem key={item.id}>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={`${classnames({active: activeTab === item.id})} text-capitalize`}
                                onClick={() => { setActiveTab(item.id) }}
                            >
                                {item.name}
                            </NavLink>
                        </NavItem>
                    );
                    })}
                </Nav>
            </CardHeader>
            {activeTab && 
                <SocialLoginForm 
                    data={data?.find(item => item.id === activeTab)}
                    setData={setData}
                />
            }
      </Card>
    </PageContainer>
  )
}

export default SocialLogins