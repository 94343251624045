import React from 'react'
import DraggableCategoryItem from './DraggableCategoryItem'
import { Droppable } from 'react-beautiful-dnd'

const CategoryItemsContainer = ({ category, expanded, handleItemChange }) => {

  return (

    <Droppable 
        droppableId={`category-items-${category.id}`} 
        type={`category-item-${category.id}`}
    >
        {provided => (
            <div 
                className={`category-items-container ${expanded ? 'expanded' : ''}`}
                ref={provided.innerRef} 
                {...provided.droppableProps}
            >
                {category.children.map((item, index) =>  (
                    <DraggableCategoryItem 
                        key={`category-${category.id}-item-${item.id}`}
                        item={item}
                        index={index}
                        handleItemChange={handleItemChange}
                    />
                ))}
                {provided.placeholder}
            </div>
        )}
    </Droppable>
  )
}

export default CategoryItemsContainer