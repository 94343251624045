export const days = [
    {
        id: 8,
        day: 'All Days',
    },
    {
        id: 1,
        day: 'Monday',
    },
    {
        id: 2,
        day: 'Tuesday',
    },
    {
        id: 3,
        day: 'Wednesday',
    },
    {
        id: 4,
        day: 'Thirsday',
    },
    {
        id: 5,
        day: 'Friday',
    },
    {
        id: 6,
        day: 'Saturday',
    },
    {
        id: 7,
        day: 'Sunday',
    },
]