import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import PageContainer from '../../../Components/Layout/PageContainer'
import ShelfForm from '../../../Components/Orders/Shelves/Form'
import { getOneShelf } from '../../../apis/Orders/shelvesApi'

const UpdateShelf = () => {

  const {id} = useParams();

  const [initialValues, setInitialValues] = useState(null);

  const { isLoading, isRefetching } = useQuery(['one-shelf'], () => getOneShelf(id), {
    onSuccess({data: { data }}) {
      setInitialValues(data);
    }
  })

  return (
    <PageContainer isLoading={isLoading || isRefetching}>
        <BreadCrumb pageTitle="Shelves" title="Update" to="orders/shelves"/>
        {initialValues && 
          <ShelfForm
            type="update"
            initialValues={initialValues}
          />
        } 
    </PageContainer>
  )
}

export default UpdateShelf