import React, { useState } from "react";

const useStateFile = (initValue = { file: null, url: "" }) => {
  const [file, setFile] = useState(initValue);

  return {
    file,
    setFile,
  };
};

export default useStateFile;
