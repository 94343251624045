import React from 'react'
import { useSelector } from 'react-redux'
import { isAllowed } from '../../../helpers/isAllowed'
import { getPathname } from '../../../Layouts/LayoutMenuData'
import DataTableContainer from '../../Common/DataTableContainer'
import CustomTableHeading from '../../Custom/CustomTableHeading'
import CustomInput from '../../Custom/CustomInput'
import CustomOption from '../../Custom/CustomOption'

const BlackAndWhiteTable = ({navigateToPage, pageSize, setPageSize, setSelectedItems, modal, setModal, isLoading, handleDelete, loading, data, keyQuery, setKey, localFilterValues, setLocalFilterValues }) => {

    const { user } = useSelector(state => state.admin);

    const columns = [

        {
            name: <CustomTableHeading>ID</CustomTableHeading>,
            selector: row => row.id || '-',
            grow: -1,
        },

        {
            name:
            <CustomInput
                type="text"
                className="form-control"
                placeholder="Key"
                value={keyQuery ?? ''}
                onChange={(e) => setKey(e.target.value)}
                data-filtered={!!keyQuery}
            />,
            selector: row => row.key || '-',
        },

        {
            name: 
            <select
                className='form-select'
                value={localFilterValues.type}
                onChange={(e) => setLocalFilterValues({
                    blackAndWhiteFilter: {
                        type: e.target.value
                    }
                })}
                data-filtered={!!localFilterValues.type}
            >
                <CustomOption value="">
                    Type
                </CustomOption>
                {['black', 'white'].map((opt, index) => (
                    <CustomOption key={index} value={opt}>
                        {opt}
                    </CustomOption>
                ))}
            </select>,
            selector: row => row.type || '-',
        },
    ]

  return (
    <DataTableContainer 
        loading={loading}
        data={data || []}
        path={getPathname('orders/black-white')}
        columns={columns}
        paginationServer
        navigateToPage={navigateToPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        selectableRows={isAllowed(user)}
        selectItems={setSelectedItems}
        modal={modal}
        setModal={setModal}
        isDeleting={isLoading}
        handleDelete={handleDelete}
    />
  )
}

export default BlackAndWhiteTable