import React, { useState } from 'react'
import Dropzone from 'react-dropzone';
import generateUniqueID from '../../../helpers/generateUniqueID';
import ImageItem from '../../Common/ImageItem';
import { deleteFileApi } from '../../../apis/Settings/webSettingsApi';

const MenuPageImages = ({ pageId, setPages, images }) => {

    const [deleting, setDeleting] = useState([]);

    const handleFileChange = (files) => {
      const newFiles = files.map((file) => {
        return {
          id: generateUniqueID(),
          url: URL.createObjectURL(file),
          file,
          name: file.name,
          isNew: true,
        };
      });
      setPages((prev) => ({
        ...prev,
        menu: prev.menu.map(pg => {
          if (pg.id === pageId) return {
            ...pg,
            images: [...(pg.images || []), ...newFiles]
          }
          return pg
        })
      }));
    };

    const removeFile = async (file) => {
        const existingFile = images.find((fl) => fl.id === file.id);
        const isNew = existingFile.isNew;
        try {
          setDeleting(prev => [...prev, file.id]);
          if (!isNew) {
            await deleteFileApi({
              ids: [file.id],
            });
          }
          setPages((prev) => ({
            ...prev,
            menu: prev.menu.map(pg => {
              if (pg.id === pageId) return {
                ...pg,
                images: pg.images.filter(img => img.id !== file.id)
              }
              return pg
            })
          }));
        } catch (error) {
          console.log('error', error);
        } finally {
          setDeleting(prev => prev.filter(id => id !== file.id));
        }
    };

  return (
    <div>
        
      <Dropzone
        onDrop={(acceptedFiles) => {
          handleFileChange(acceptedFiles);
        }}
        accept={["image/*"]}
        multiple
      >
        {({ getRootProps }) => (
          <div className="dropzone dz-clickable" {...getRootProps()}>
            <div className="dz-message needsclick">
              <div className="mb-3">
                <i className="text-muted ri-upload-cloud-2-fill display-6" />
              </div>
              {/* <h5>Drop files here or click to upload.</h5> */}
            </div>
          </div>
        )}
      </Dropzone>
      <div className="d-flex gap-2 justify-content-center flex-wrap mt-3">
        {images.map((item, index) => (
            <ImageItem
              key={index}
              f={item}
              removeFile={removeFile}
              deleting={deleting.find(id => id === item.id)}
            />
          ))}
       </div>
    </div>

  )
}

export default MenuPageImages