import React from 'react'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import AllergyForm from '../../../Components/Food/Allergy/Form'
import PageContainer from '../../../Components/Layout/PageContainer'
import { useFetchByLang } from '../../../hooks/useFetchByLang'

const CreateAllergy = () => {
  
  const { language, setLanguage } = useFetchByLang();

  return (
    <PageContainer>
        <BreadCrumb pageTitle="Allergy" title="Create" to="food/allergy"/>
        <AllergyForm 
          type="create"
          language={language}
          setLanguage={setLanguage}
        />
    </PageContainer>
  )
}

export default CreateAllergy