import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import { formatExample } from "../../../helpers/formatExample";
import useActions from "../../../hooks/useActions";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import { createFoodAction, updateFoodAction } from "../../../redux/actions/foodActions";
import SelectFile from "../../Common/SelectFile";
import CustomButton from "../../Custom/CustomButton";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import CustomTextArea from "../../Custom/CustomTextArea";
import SelectLanguage from "../../Common/SelectLanguage";
import { useBranches } from "../../../hooks/useQueryHelpers";
import Select from 'react-select';
import formatFormData from "../../../helpers/formatFormData";
import { objectFilter } from "../../../helpers/objectFilter";

const CategoryForm = ({ type, initialValues = null, language, setLanguage }) => {
  
  const { register, handleSubmit, setValue, reset, watch } = useForm();

  const [icon, setIcon] = useState({
    url: "",
    name: "",
    file: null,
  });

  const [background, setBackground] = useState({
    url: "",
    name: "",
    file: null,
  });

  const [menuImg, setMenuImg] = useState({
    url: "",
    name: "",
    file: null,
  });

  const selectedBranches = watch('branches');

  const dispatch = useDispatch();

  const history = useHistory();

  const { isLoading, onLoading, onFinally, onError } = useActions();
  
  const { branches } = useBranches();

  const submitForm = (data) => {

    onLoading();

    const requestBody = formatFormData(objectFilter({
      ...data,
      icon: icon.file,
      background: background.file,
      menu_image: menuImg.file,
    }));

    if (type === "create") {
      dispatch(
        createFoodAction(
          {
            field: "categories",
            data: requestBody,
            language
          },
          {
            onSuccess() {
              onFinally();
              history.push(getPathname("food/categories"));
            },
            onError,
          }
        )
      );
    } else {
      dispatch(
        updateFoodAction(
          {
            field: "categories",
            id: initialValues.id,
            data: requestBody,
            language
          },
          {
            onSuccess() {
              onFinally();
              setIcon({
                id: icon.id,
                name: icon.name,
                url: icon.url,
                file: null,
              });
              setBackground({
                id: background.id,
                name: background.name,
                url: background.url,
                file: null,
              });
              setMenuImg({
                id: menuImg.id,
                name: menuImg.name,
                url: menuImg.url,
                file: null,
              });
            },
            onError,
          }
        )
      );
    }
  };

  useEffect(() => {
    if (initialValues) {
      reset(
        (() => {
          const { file, icon, background, branches, background_file, menu_image, menu_file,  ...rest } =
            initialValues;
            return rest;
        })()
      );

      if (initialValues.file) {
        setIcon({
          id: initialValues.file.id,
          url: initialValues.file.link,
          name: initialValues.file.name,
          file: null,
        });
      }
  
      if (initialValues.background_file) {
        setBackground({
          id: initialValues.background_file.id,
          url: initialValues.background,
          name: initialValues.background_file.name,
          file: null,
        });
      }
  
      if (initialValues.menu_file) {
        setMenuImg({
          id: initialValues.menu_file.id,
          url: initialValues.menu_file.link,
          name: initialValues.menu_file.name,
          file: null,
        });
      }

    }
  }, [initialValues, branches]);

  useEffect(() => {
    if (initialValues?.branches?.length && branches.length) {
      setValue('branches', initialValues.branches.map(branch => branch.id))
    }
  }, [initialValues, branches])

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Row>
        <Col lg={3}>
          <Row>
            <Col lg={12}>
              <Card className="p-3">
                <CustomLabel>Icon</CustomLabel>

                <SelectFile
                  type="image"
                  max={1}
                  display
                  selectedFiles={icon}
                  setSelectedFiles={setIcon}
                />

              {
                !!icon.url && 
                  <div className="form-check form-switch mt-3">
                      <input 
                        {...register('is_icon_transparent')} 
                        className="form-check-input fs-16" 
                        type="checkbox" 
                        role="switch" 
                        id="is_icon_transparent" 
                      />
                      <CustomLabel className="form-check-label" htmlFor="is_icon_transparent">Is transparent</CustomLabel>
                  </div>
              }

              </Card>

            </Col>

            <Col lg={12}>
              <Card className="p-3">
                <CustomLabel>Background</CustomLabel>

                <SelectFile
                  type="image"
                  max={1}
                  display
                  selectedFiles={background}
                  setSelectedFiles={setBackground}
                />

                {
                  !!background.url &&
                    <div className="form-check form-switch mt-3">
                        <input 
                          {...register('is_background_transparent')} 
                          className="form-check-input fs-16" 
                          type="checkbox" 
                          role="switch" 
                          id="is_background_transparent" 
                        />
                        <CustomLabel className="form-check-label" htmlFor="is_background_transparent">Is transparent</CustomLabel>
                    </div>
                }

              </Card>
            </Col>

            <Col lg={12}>
              <Card className="p-3">
                <CustomLabel>Menu image</CustomLabel>

                <SelectFile
                  type="image"
                  max={1}
                  display
                  selectedFiles={menuImg}
                  setSelectedFiles={setMenuImg}
                />

              {
                !!menuImg.url && 
                  <div className="form-check form-switch mt-3">
                      <input 
                        {...register('is_menu_image_transparent')} 
                        className="form-check-input fs-16" 
                        type="checkbox" 
                        role="switch" 
                        id="is_menu_image_transparent" 
                      />
                      <CustomLabel className="form-check-label" htmlFor="is_menu_image_transparent">Is transparent</CustomLabel>
                  </div>
              }

              </Card>

            </Col>

          </Row>
        </Col>

        <Col lg={9}>
          <Card className="p-3">

            {type === 'update' && 
              <div className='d-flex justify-content-end'>
                  <SelectLanguage
                  language={language}
                  onChange={lang => setLanguage(lang)}
                  /> 
              </div>
            }

            <Row className="gy-3 mt-1">

              <Col lg={6}>

                <CustomLabel translatable>Name</CustomLabel>
                <CustomInput
                  type="text"
                  {...register("name")}
                  placeholder={formatExample("Fried Chicken")}
                />
              </Col>

              <Col lg={6}>
                <CustomLabel>Branches</CustomLabel>
                <Select 
                    value={branches.filter(branch => selectedBranches?.includes(branch.id)).map(branch => ({
                        label: branch.name,
                        value: branch.id
                    }))}
                    options={branches.map(branch => ({
                        label: branch.name,
                        value: branch.id
                    }))}
                    onChange={(values) => setValue('branches', values.map(value => value.value))}
                    menuPortalTarget={document.body}
                    isMulti
                />
              </Col>

              <Col lg={12}>

                <div className="d-flex flex-wrap gap-3">

                  <div
                    className="form-check form-switch"
                    style={{ width: "max-content" }}
                  >
                    <input
                      {...register("active")}
                      className="form-check-input fs-16"
                      type="checkbox"
                      role="switch"
                      id="active"
                    />
                    <CustomLabel className="form-check-label" htmlFor="active">
                      Active
                    </CustomLabel>
                  </div>

                  <div
                    className="form-check form-switch"
                    style={{ width: "max-content" }}
                  >
                    <input
                      {...register("is_entrees")}
                      className="form-check-input fs-16"
                      type="checkbox"
                      role="switch"
                      id="entrees"
                    />
                    <CustomLabel className="form-check-label" htmlFor="entrees">
                      Entry
                    </CustomLabel>
                  </div>

                  <div
                    className="form-check form-switch"
                    style={{ width: "max-content" }}
                  >
                    <input
                      {...register("show_menu")}
                      className="form-check-input fs-16"
                      type="checkbox"
                      role="switch"
                      id="show-in-menu"
                    />
                    <CustomLabel
                      className="form-check-label"
                      htmlFor="show-in-menu"
                    >
                      Show in menu
                    </CustomLabel>
                  </div>

                </div>
              </Col>

              <Col>
                <CustomLabel translatable>Note</CustomLabel>
                <CustomTextArea 
                  {...register('note')}
                />
              </Col>

            </Row>

            <Row className="gy-2 my-1">
              <Col md={6}>
                <CustomLabel>Seo Title</CustomLabel>
                <CustomInput {...register("seo_title")} />
              </Col>
              <Col md={6}>
                <CustomLabel>Seo Keywords</CustomLabel>
                <CustomInput {...register("seo_keywords")} />
              </Col>
              <Col xs={12}>
                <CustomLabel>Seo Description</CustomLabel>
                <CustomTextArea rows={3} {...register("seo_description")} />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <CustomButton className="btn-success" isLoading={isLoading}>
                  {type === "create" ? "Create" : "Update"}
                </CustomButton>
              </Col>
            </Row>

          </Card>

        </Col>
      </Row>
    </form>
  );
};

export default CategoryForm;
