import React from "react";
import { createPortal } from "react-dom";
import { Spinner } from "reactstrap";

const CoverSpinner = ({ loading }) => {
  return loading
    ? createPortal(
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: 50000,
            background: "rgba(255,255,255,.6)",
          }}
        >
          <Spinner />
        </div>,
        document.getElementById("cover-spinner")
      )
    : null;
};

export default CoverSpinner;
