import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import { addNewGalleryApi, updateGalleryApi } from "../../../apis/Settings/galleryApi";
import extractError from "../../../helpers/extractError";
import { formatExample } from "../../../helpers/formatExample";
import formatFormData from "../../../helpers/formatFormData";
import { renderErrorAsString } from "../../../helpers/renderErrors";
import toastAlert from "../../../helpers/toastAlert";
import useActions from "../../../hooks/useActions";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import SelectFile from "../../Common/SelectFile";
import CustomButton from "../../Custom/CustomButton";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import SelectLanguage from "../../Common/SelectLanguage";
import GalleryImages from "./GalleryImages";

const GalleryForm = ({ type, initialValues = null, language, setLanguage }) => {

  const { register, handleSubmit, reset } = useForm();

  const { isLoading, onLoading, onFinally } = useActions();

  const { push } = useHistory();

  const [media, setMedia] = useState({
    url: "",
    file: null,
  });

  const submitForm = async (data) => {
    onLoading();
    let body = {...data};
    if (media.file) {
      body.file = media.file;
    }
    const formData = formatFormData(body);
    try {
      if (type === "create") {
        await addNewGalleryApi(formData, language);
        push(getPathname("settings/gallery"));
      } else {
        const { data } = await updateGalleryApi(initialValues.id, formData, language);
        if (data.data.file) {
          setMedia({
            ...data.data.file,
            url: data.data.file.link,
          });
        }
        onFinally();
        toastAlert({ type: 'success' });
      }
    } catch (error) {
      onFinally();
      toastAlert({ type: 'error', message: renderErrorAsString(extractError(error))});
    }
  };

  useEffect(() => {
    if (initialValues) {
      reset((() => {
          const { id, file, ...rest } = initialValues;
          reset(rest);
          if (file) {
            setMedia({ ...file, url: file.link })
          }
      })())
    } 
  }, [initialValues])

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Row>

          <Col lg={3}>
            <Card className="p-3">
              <CustomLabel>Image</CustomLabel>
              <SelectFile
                type="image"
                max={1}
                display
                selectedFiles={media}
                setSelectedFiles={setMedia}
              />
            </Card>
          </Col>

          <Col lg={9}>
            <Card className="p-3">
                {type === 'update' && 
                  <div className='d-flex justify-content-end'>
                      <SelectLanguage
                        language={language}
                        onChange={lang => setLanguage(lang)}
                      /> 
                  </div>
                }
                <Row className="gy-4 mt-1">
                  <Col md={6}>
                    <CustomLabel translatable>Name</CustomLabel>
                    <CustomInput 
                      type="text" 
                      {...register("name")} 
                      placeholder={formatExample("My Collection")}
                    />
                  </Col>

                  <Col md={6} className="d-flex align-items-end ">
                    <div className="form-check form-switch mb-2">
                      <input
                        {...register("active")}
                        className="form-check-input fs-16"
                        type="checkbox"
                        role="switch"
                        id="active"
                      />
                      <CustomLabel className="form-check-label" htmlFor="active">
                        Active
                      </CustomLabel>
                    </div>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col>
                    <CustomButton isLoading={isLoading} className="btn-success">
                      {type === "create" ? "Create" : "Update"}
                    </CustomButton>
                  </Col>
                </Row>
            </Card>

            {type === 'update' && 
              <Card className="p-3">
                <GalleryImages 
                  initialValues={initialValues}
                />
              </Card>
            }

          </Col>
      </Row>
    </form>
  );
};

export default GalleryForm;
