import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { foodFormTabs } from '../../../../../../data/foodFormTabs';
import { getFoodDataAction } from '../../../../../../redux/actions/foodActions';
import PricesList from './List';
import { Col, Row } from 'reactstrap';
import CustomLabel from '../../../../../Custom/CustomLabel';
import CustomInput from '../../../../../Custom/CustomInput';

const Price = ({initialValues, foodType, register, setValue}) => {

  const dispatch = useDispatch();

  const {sizes: {list: sizes}, doughAndBread: {list: doughAndBread}} = useSelector(state => state.food);

  const [data, setData] = useState(null);

  useEffect(() => {
    if (foodFormTabs.find(item => item.id === foodType)?.tabs?.some(tab => tab.value === 'price')) {
      dispatch(getFoodDataAction({
        field: 'sizes',
        params: {
          type_id: foodType,
          page_size: 1000,
        }
      }))
      dispatch(getFoodDataAction({
        field: 'doughAndBread',
        params: {
          type_id: foodType,
          page_size: 1000,
        }
      }))
  } else {
    setValue('prices', null);
    setValue('alt_bread_label', null);
    setValue('alt_size_label', null);
  }
  }, [foodType])

  useEffect(() => {
    if (sizes.length && doughAndBread.length) {
      setData(doughAndBread.map(item => {
        const initialVal = initialValues?.prices?.find(item2 => item2.bread_id === item.id);
        return {
          id: item.id,
          name: item.name,
          ...(item.icon ? {icon: item.icon} : {}),
          selected: (foodType === initialValues?.type_id)  ? !!initialVal : false,
          display: initialVal?.display ?? true,
          prices: sizes.map(size => {
            const initialSizeVal = initialVal?.sizes?.find(sz => sz.size_id === size.id);
            if (foodType === initialValues?.type_id)  return {
              id: size.id,
              name: size.name,
              ...(size.icon ? {icon: size.icon} : {}),
              price: initialSizeVal?.price ?? null,
              price_in: initialSizeVal?.price_in ?? null,
              price_id: initialSizeVal?.price_id ?? null,
              file: initialSizeVal?.file ?? null,
              selected: !!initialSizeVal || false,
              display: initialSizeVal?.display ?? true,
            }
            return {
              id: size.id,
              name: size.name,
              ...(size.icon ? {icon: size.icon} : {}),
              price: null,
              price_in: null,
              selected: false,
              display: true,
            }
          })
        }
      }))
    }
  }, [sizes, doughAndBread, foodType, initialValues])

  useEffect(() => {
    if (data) {
      setValue('prices', data.filter(item => item.selected && item.prices.some(it => it.selected)).map(item => ({
        bread_id: item.id,
        display: item.display,
        sizes: item.prices.filter(item => (item.selected) && (item.price ?? item.price_in)).map(size => ({
          name: size.name,
          size_id: size.id,
          price: size.price || '0',
          price_in: size.price_in || '0',
          display: size.display ?? true,
          image_id: size.file?.id ?? null
        }))
      })))
    }
  }, [data])

  return (
    <div className='d-flex flex-column gap-3'>
      <Row>
        <Col lg={6}>
          <CustomLabel translatable>
              Alternative Bread Label
          </CustomLabel>
          <CustomInput 
            {...register('alt_bread_label')}
          />
        </Col>
        <Col lg={6}>
          <CustomLabel translatable>
              Alternative Size Label
          </CustomLabel>
          <CustomInput 
            {...register('alt_size_label')}
          />
        </Col>
      </Row>
      {data && 
        <PricesList 
          data={data}
          setData={setData}
          canAddImage={!!initialValues}
        />
      }
    </div>
  )
}

export default Price