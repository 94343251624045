import React, { useState } from 'react'
import DeliveryZones from '../../../Orders/DeliverySettings/Zones/Zones'
import { getDeliverySettingsApi } from '../../../../apis/Orders/deliverySettingsApis';
import { useQuery } from "@tanstack/react-query";

const BranchZones = ({ data }) => {

    const [shopLocation, setShopLocation] = useState(null);

    const [mapsApiKey, setMapsApiKey] = useState(null);

    useQuery(["order-delivery"], () => getDeliverySettingsApi('en'), {
        cacheTime: 0,
        onSuccess({ data: { data } }) {
            if (data.shop_location) {
                const coords = data.shop_location.split(',');
                if (coords?.length === 2) setShopLocation({ lat: +coords[0], lng: +coords[1] })
            }
      
            if (data.google_api_key) {
              setMapsApiKey(data.google_api_key)
            }
        },
    });

  return (
    <DeliveryZones 
        shopLocation={shopLocation}
        mapsApiKey={mapsApiKey}
        branchId={data.id}
    />
  )
}

export default BranchZones