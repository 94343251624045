import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Card, Col, Modal, ModalBody, Row } from "reactstrap";
import extractError from "../../../helpers/extractError";
import { formatExample } from "../../../helpers/formatExample";
import formatFormData from "../../../helpers/formatFormData";
import {
  renderErrorAsString,
} from "../../../helpers/renderErrors";
import toastAlert from "../../../helpers/toastAlert";
import useActions from "../../../hooks/useActions";
import SelectFile from "../../Common/SelectFile";
import CustomButton from "../../Custom/CustomButton";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import { createBranchApi, updateBranchApi } from "../../../apis/Settings/branchesApi";
import TextEditor from "../../TextEditor";

const BranchModal = ({isOpen, toggleModal, type, initialValues = null, createBranch, updateBranch}) => {

  const { register, handleSubmit, control, reset, watch } = useForm();

  const { isLoading, onLoading, onFinally } = useActions();

  const [media, setMedia] = useState({
    url: "",
    file: null,
  });

  const submitForm = async (data) => {
    onLoading();

    let body = {...data};

    if (media.file) {
      body.file = media.file;
    }
    
    const formData = formatFormData(body);
    try {
      if (type === "create") {
        const {data: { data: response }} = await createBranchApi(formData, 'en');
        createBranch(response);
        toggleModal();
      } else {
        const { data: response } = await updateBranchApi(initialValues.id, formData, 'en');
        if (response.data.file) {
          setMedia({
            ...response.data.file,
            url: response.data.file.link,
          });
        }
        updateBranch(initialValues.id, response.data);
        toggleModal();
        toastAlert({ type: 'success' });
      }
    } catch (error) {
      toastAlert({ type: 'error', message: renderErrorAsString(extractError(error))});
    } finally {
      onFinally();
    }
  };

  const clearForm = () => {

    reset({
        name: '',
        description: '',
        active: ''
    });

    setMedia({
      url: "",
      file: null,
    })
  }

  useEffect(() => {
    if (initialValues) {
      reset((() => {
          const { id, file, ...rest } = initialValues;
          reset(rest);
          if (file) {
            setMedia({ ...file, url: file.link })
          }
      })())
    } 
  }, [initialValues])

  return (
    <React.Fragment>
        <Modal
            centered 
            isOpen={isOpen}
            toggle={toggleModal}
            onClosed={clearForm}
            size='xl'
        >
            <ModalBody>
                <form onSubmit={handleSubmit(submitForm)}>
                    <Row>

                        <Col lg={3}>
                            <Card className="p-3">
                            <CustomLabel>Image</CustomLabel>
                            <SelectFile
                                type="image"
                                max={1}
                                display
                                selectedFiles={media}
                                setSelectedFiles={setMedia}
                            />
                            </Card>
                        </Col>

                        <Col lg={9}>
                            <Card className="p-3">
                                <Row className="gy-4 mt-1">
                                <Col md={6}>
                                    <CustomLabel translatable>Name</CustomLabel>
                                    <CustomInput 
                                    type="text" 
                                    {...register("name")} 
                                    placeholder={formatExample("My Collection")}
                                    />
                                </Col>

                                <Col md={6} className="d-flex align-items-end ">
                                    <div className="form-check form-switch mb-2">
                                    <input
                                        {...register("active")}
                                        className="form-check-input fs-16"
                                        type="checkbox"
                                        role="switch"
                                        id="active"
                                    />
                                    <CustomLabel className="form-check-label" htmlFor="active">
                                        Active
                                    </CustomLabel>
                                    </div>
                                </Col>

                                <Col md={12}>
                                    <CustomLabel translatable>Description</CustomLabel>
                                    <Controller 
                                        control={control}
                                        name="description"
                                        render={({name, field: {onChange}}) => 
                                        <TextEditor
                                            name={name}
                                            onChange={(value) => onChange(value)}
                                            value={watch('description')}
                                        />
                                        }      
                                    />
                                </Col>

                                </Row>

                                <Row className="mt-3">
                                    <Col>
                                        <CustomButton isLoading={isLoading} className="btn-success">
                                        {type === "create" ? "Create" : "Update"}
                                        </CustomButton>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </form>
            </ModalBody>
        </Modal>
    </React.Fragment>
    
  )
}

export default BranchModal