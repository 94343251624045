import React from 'react'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import FoodForm from '../../../Components/Food/Menu/Form'
import PageContainer from '../../../Components/Layout/PageContainer'
import { useFetchByLang } from '../../../hooks/useFetchByLang'

const CreateFood = () => {

  const { language, setLanguage } = useFetchByLang();
    
  return (
    <PageContainer>
      <BreadCrumb pageTitle="Food Menu" title="Create" to="food/menu"/>
      <FoodForm 
        type="create"k
        language={language}
        setLanguage={setLanguage}
      />
    </PageContainer>
  )
}

export default CreateFood