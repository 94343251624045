import React from 'react'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import PageContainer from '../../../Components/Layout/PageContainer'
import RoomForm from '../../../Components/Orders/Rooms/Form'

const CreateRoom = () => {

  return (
    <PageContainer>
        <BreadCrumb pageTitle="Rooms" title="Create" to="orders/rooms"/>
        <RoomForm 
          type="create"
        />
    </PageContainer>
  )
}

export default CreateRoom