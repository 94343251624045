import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: 'notifications',
    initialState: {
        list: [],
        total: 0,
        new: 0,
        sounds: localStorage.getItem('notificationSounds') ? JSON.parse(localStorage.getItem('notificationSounds')) : {
            newNotifications: true,
            unseenNotifications: true
        }
    },
    reducers: {

        setData: (state, { payload }) => {
            return {
                ...state,
                ...payload
            };
        },

        setNotifications: (state, { payload }) => {
            return {
                ...state,
                list: payload.data,
                total: payload.total,
                new: payload.new,
            }
        },
        
        getNextPage: (state, { payload }) => {
            state.list = [...state.list, ...payload.data]
        },

        markAsSeen: (state, { payload }) => {
            state.list = state.list.map(item => {
                if (item.id === payload.id) return {
                    ...item,
                    read: true
                };
                return item;
            });
            state.new = state.new - 1 || 0
        },

        markAllAsSeen: (state) => {
            state.list = state.list.map(item => ({
                ...item,
                read: true
            }))
            state.new = 0;
        },

        addNotification: (state, { payload }) => {
            if (payload && !state.list.some(item => item.id === payload?.id)) return {
                ...state,
                list: [payload, ...state.list],
                total: state.total + 1,
                new: state.new + 1,
            }
        },

        toggleSound: (state, { payload }) => {
            state.sounds = {
                ...state.sounds,
                ...payload
            }
            localStorage.setItem('notificationSounds', JSON.stringify({
                ...state.sounds,
                ...payload
            }))
        },
 
    }
})

export const notificationActions = slice.actions;

export default slice.reducer;