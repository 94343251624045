import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Col } from "reactstrap";
import {
  getFooterApi,
  setFooterApi,
} from "../../../apis/Settings/webSettingsApi";
import formatFormData from "../../../helpers/formatFormData";
import toastAlert from "../../../helpers/toastAlert";
import useActions from "../../../hooks/useActions";
import useCustomForm from "../../../hooks/useCustomForm";
import { settingsActions } from "../../../redux/slices/settingsSlice";
import LoaderComponent from "../../Common/LoaderComponent";
import SelectFile from "../../Common/SelectFile";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import WebSettingsBody from "./WebSettingsBody";
import WebSettingsFooter from "./WebSettingsFooter";
import generateUniqueID from "../../../helpers/generateUniqueID";

const formatFiles = (files) => {
  return files.map((file) => {
    return {
      ...file,
      url: file.link,
    };
  });
};

const FooterTab = () => {
  const [files, setFiles] = useState([]);
  const { values, handleChange, setValues } = useCustomForm({
    footer: "",
    copyright: "",
  });

  const dispatch = useDispatch();

  const { isLoading: isSubmitting, onLoading, onFinally } = useActions();

  const { isLoading } = useQuery(["web-settings", "footer"], getFooterApi, {
    onSuccess(data) {
      const { footer, copyright, tags } = data.data.data;
      dispatch(settingsActions.setFooter({ footer, copyright }));
      setValues({ footer, copyright });
      setFiles(formatFiles(tags));
    },
  });

  const onSubmit = async () => {
    onLoading();
    const body = {
      ...values,
      tags: files
        .filter((file) => file.isNew === true)
        .map((file) => file.file),
    };
    const formDataBody = formatFormData(body);
    try {
      const { data } = await setFooterApi(formDataBody);
      const { footer, copyright, tags } = data.data;
      dispatch(settingsActions.setFooter({ footer, copyright }));
      setValues({ footer, copyright });
      setFiles(formatFiles(tags));
      toastAlert({ type: 'success' });
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      onFinally();
    }
  };

  const handleAcceptedFiles = (files) => {
    setFiles((current) => {
      return [
        ...current,
        ...files.map((file) => {
          return {
            id: generateUniqueID(),
            url: URL.createObjectURL(file),
            file,
            isNew: true,
          };
        }),
      ];
    });
  };

  return (
    <LoaderComponent isLoading={isLoading}>
      <WebSettingsBody>
        <Col md={6}>
          <CustomLabel>Footer</CustomLabel>
          <CustomInput
            name="footer"
            value={values.footer}
            onChange={handleChange}
          />
        </Col>
        <Col md={6}>
          <CustomLabel>Copyright</CustomLabel>
          <CustomInput
            name="copyright"
            value={values.copyright}
            onChange={handleChange}
          />
        </Col>
        <Col md={6}>
          <CustomLabel>Tags</CustomLabel>
          <SelectFile 
            type='image'
            multiple
            display
            selectedFiles={files}
            setSelectedFiles={setFiles}
          />
        </Col>
      </WebSettingsBody>
      <WebSettingsFooter isSubmitting={isSubmitting} onSubmit={onSubmit} />
    </LoaderComponent>
  );
};

export default FooterTab;
