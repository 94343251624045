import React from "react";
import { CardFooter } from "reactstrap";
import CustomButton from "../../Custom/CustomButton";

const WebSettingsFooter = ({ isSubmitting, onSubmit }) => {
  return (
    <CardFooter>
      <CustomButton color="success" isLoading={isSubmitting} onClick={onSubmit}>
        Save
      </CustomButton>
    </CardFooter>
  );
};

export default WebSettingsFooter;
