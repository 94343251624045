import React, { useState } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import PageContainer from "../../../Components/Layout/PageContainer";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useFetchByLang } from "../../../hooks/useFetchByLang";
import { getOneBranchApi } from "../../../apis/Settings/branchesApi";
import BranchForm from "../../../Components/Settings/Branches/Form";

const UpdateBranch = () => {

  const { id } = useParams();

  const [initialValues, setInitialValues] = useState(null);

  const { refetch, isLoading, isRefetching } = useQuery(["branch", id], () => getOneBranchApi(id, language), {
    enabled: false,
    cacheTime: 0,
    onSuccess({data: { data }}) {
      setInitialValues(data);
    },
  });

  const { language, setLanguage } = useFetchByLang(refetch);

  return (
    <PageContainer isLoading={isLoading || isRefetching}>
      <BreadCrumb pageTitle="Branches" title="Update" to="settings/branches" />
      {initialValues && 
        <BranchForm
          type="update" 
          initialValues={initialValues} 
          language={language}
          setLanguage={setLanguage}
        />
      }
    </PageContainer>
  );
};

export default UpdateBranch;
