import React from "react";
import CommonForm from "./CommonForm";

const AcceptedTab = ({ values, handleChange }) => {

  return (
    <>
      <CommonForm
        handleChange={handleChange}
        values={values}
        name="accepted"
        checkboxName="accepted_send_sms"
        inputName="accepted_sms_text"
        keywordsName="accepted_sms_keywords"
      />
    </>
  );

};

export default AcceptedTab;
