import { useState } from "react";

export default function useActions(initial = null) {
  const [data, setData] = useState(initial);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const onLoading = () => {
    setError(null);
    setIsLoading(true);
  };

  const onError = (error) => {
    setError(error);
    setIsLoading(false);
  };

  const onFinally = (cb) => {
    setIsLoading(false);
    if (cb) {
      cb();
    }
  };

  const onSuccess = (data) => {
    setIsLoading(false);
    setData(data);
  };

  return {
    isLoading,
    error,
    onLoading,
    onError,
    onFinally,
    onSuccess,
    setData,
    data,
  };
}
