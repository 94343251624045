import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import PageContainer from "../../Components/Layout/PageContainer";
import classnames from "classnames";
import CustomButton from "../../Components/Custom/CustomButton";
import useActions from "../../hooks/useActions";
import DiscountTab from "../../Components/Orders/OnlineSettings/DiscountTab";
import OrderCloseTab from "../../Components/Orders/OnlineSettings/OrderCloseTab";
import OrderDateList from "../../Components/Orders/OnlineSettings/OrderDateList";
import { useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import {
  getOnlineSettingsApi,
  setCloseImage,
  setOnlineSettingsApi,
} from "../../apis/Orders/onlineSettingsApis";
import toastAlert from "../../helpers/toastAlert";
import { useTranslation } from "react-i18next";
import validateOpeningHour from "../../validations/validateOpeningHour";
import { useFetchByLang } from "../../hooks/useFetchByLang";
import SelectLanguage from "../../Components/Common/SelectLanguage";

const OnlineSettings = () => {

  const [activeTab, setActiveTab] = useState(1);

  const { t } = useTranslation();

  const { register, reset, handleSubmit } = useForm({
    defaultValues: {
      order_to_day: '0'
    }
  });

  const [closeImg, setCloseImg] = useState({
    url: "",
    file: null,
  })

  const [data, setData] = useState(null);
  
  const [specialDays, setSpecialDays] = useState([]);

  const [openingHours, setOpeningHours] = useState([]);

  const { isLoading: isSubmitting, onLoading, onFinally } = useActions();

  const toggleActiveTab = (tab) => {
    setActiveTab(tab);
  };

  const { refetch, isLoading, isRefetching } = useQuery(["order-online-settings"], () => getOnlineSettingsApi(language),
    {
      enabled: false,
      cacheTime: 0,
      onSuccess({ data: { data }}) {
          setForm({ data });
      },
    }
  );

  const { language, setLanguage } = useFetchByLang(refetch);

  const onSubmit = async (requestBody) => {
    onLoading();
    try {
      for (const openingHour of openingHours) {
        const error = validateOpeningHour({
          dayName: openingHour.day.name,
          times: openingHour.times,
        });
        if (error) {
          onFinally();
          return toastAlert({ type: 'error', message: error });
        }
      }
      const body = {
        ...requestBody,
        opening_hours: openingHours.map(({ id, open, times }) => {
          return {
            id,
            open,
            times: times.map((time) => {
              return {
                id: time.isNew ? null : time.id,
                start: time.start?.slice(0, 5),
                end: time.end?.slice(0, 5),
              };
            }),
          };
        }),
      };
      const { data: { data } } = await setOnlineSettingsApi(body, language);
      if (closeImg.file) {
        const file = closeImg.file;
        const formData = new FormData();
        const blob = new Blob([file], { type: file.type });
        formData.append('close_image', blob, file.name);
        formData.append('is_close_image_transparent', body.is_close_image_transparent ? 1 : 0);
         const { data: { data: resData } } =  await setCloseImage(formData);
          setCloseImg({
            id: resData?.id,
            name: resData?.name,
            url: resData?.link,
            file: null
          })
      }
      setForm({ data });
      toastAlert({ type: 'success' });
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      onFinally();
    }
  };


  const TABS = [
    {
      name: "Order close",
      children: (
        <OrderCloseTab
          setOpeningHours={setOpeningHours}
          openingHours={openingHours}
          register={register}
          closeImg={closeImg}
          setCloseImg={setCloseImg}
        />
      ),
    },
    {
      name: "Discount",
      children: <DiscountTab register={register} />,
    },
  ];

  const setForm = ({ data }) => {
    const { opening_hours, special_time, updated_at, ...rest } = data;
    setData(rest);
    setOpeningHours(opening_hours);
    setSpecialDays(special_time);
  }

  useEffect(() => {
    if (data) reset(data);
  }, [data])

  useEffect(() => {
    if (data?.close_image_file) {
      setCloseImg({
          id: data?.close_image_file?.id,
          name: data?.close_image_file?.name,
          url: data?.close_image_file?.link,
          file: null
      })
    }
}, [data])

  return (
    <PageContainer isLoading={isLoading || isRefetching}>
      <Card>

        <div className="d-flex gap-3 p-2">

          <Nav className="nav-tabs mb-3 nav-primary flex-1">
              {TABS.map((tab, index) => {
                const i = index + 1;
                return (
                  <NavItem key={tab.name}>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === i,
                      })}
                      onClick={() => {
                        toggleActiveTab(i);
                      }}
                    >
                      {t(tab.name)}
                    </NavLink>
                  </NavItem>
                );
              })}
          </Nav>

          <SelectLanguage
              language={language}
              onChange={lang => setLanguage(lang)}
          /> 
          
        </div>

        <CardBody>

          <TabContent activeTab={activeTab}>
            {TABS.map((tab, index) => {
              const i = index + 1;
              return (
                <TabPane key={tab.name} tabId={i}>
                  {tab.children}
                </TabPane>
              );
            })}
          </TabContent>
        </CardBody>
        
        <CardFooter>
          <CustomButton
            color="success"
            onClick={handleSubmit(onSubmit)}
            isLoading={isSubmitting}
          >
            Save
          </CustomButton>
        </CardFooter>

      </Card>

      <Card>
        <OrderDateList
          specialDays={specialDays}
          setData={setForm}
          setSpecialDays={setSpecialDays}
        />
      </Card>
    </PageContainer>
  );
};

export default OnlineSettings;
