import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Col, Modal, ModalBody, Row } from 'reactstrap'
import CustomLabel from '../../../../../../Custom/CustomLabel';
import CustomButton from '../../../../../../Custom/CustomButton';
import Flatpickr from "react-flatpickr";
import { format } from "date-fns";
import generateUniqueID from '../../../../../../../helpers/generateUniqueID';
import moment from 'moment';

const FoodDateModal = ({isOpen, toggleModal, type, initialValues = null, setData}) => {

  const {register, handleSubmit, reset, control, getValues} = useForm();

  const submitForm = (data) => {
    if (type === 'create') {
        setData(prev => [...(prev || []), {
            id: generateUniqueID(),
            ...data,
        }])
    } else {
        setData(prev => prev.map(item => {
            if (item.id === initialValues?.id) return data
            return item
        }))
    }
    toggleModal();
  }
  
  const clearForm = () => {
      reset({
          date_from: '',
          date_to: '',
          time_start: '',
          time_end: '',
      });
  }

  useEffect(() => {
      if (initialValues) {
          reset(initialValues);
      }
  }, [initialValues])

  return (
    <Modal
        centered 
        isOpen={isOpen}
        toggle={toggleModal}
        onClosed={clearForm}
    >
        <ModalBody>
                <Row className='gy-3'>
                    <Col md={6}>
                        <CustomLabel>From</CustomLabel>
                        <Controller 
                            name="date_from"
                            control={control}
                            render={({name, field: {onChange}}) => (
                                <Flatpickr
                                    name={name}
                                    className="form-control"
                                    autoFocus={false}
                                    options={{
                                        dateFormat: "d M, Y",
                                    }}
                                    value={moment(getValues('date_from'))?.format("D MMM, YYYY") || ''}
                                    onChange={([date]) =>  onChange(moment(date).format("YYYY-MM-DD"))}
                                />
                            )}
                        />
                    </Col>

                    <Col md={6}>
                        <CustomLabel>To</CustomLabel>
                        <Controller 
                            name="date_to"
                            control={control}
                            render={({name, field: {onChange}}) => (
                                <Flatpickr
                                    name={name}
                                    className="form-control"
                                    autoFocus={false}
                                    options={{
                                        dateFormat: "d M, Y",
                                    }}
                                    value={moment(getValues('date_to'))?.format("D MMM, YYYY") || ''}
                                    onChange={([date]) =>  onChange(moment(date).format("YYYY-MM-DD"))}
                                />
                            )}
                        />
                    </Col>

                    <Col md={6}>
                        <CustomLabel>Start Time</CustomLabel>
                        <Controller 
                            name="time_start"
                            control={control}
                            render={({name, field: {onChange}}) => (
                                <Flatpickr
                                    name={name}
                                    className="form-control"
                                    autoFocus={false}
                                    options={{
                                        enableTime: true,
                                        noCalendar: true,
                                        dateFormat: "H:i",
                                        time_24hr: true,
                                        mode: "time",
                                    }}
                                    value={getValues('time_start') || ''}
                                    onChange={([date]) =>  onChange(format(date, "HH:mm"))}
                                />
                            )}
                        />
                    </Col>

                    <Col md={6}>
                        <CustomLabel>End Time</CustomLabel>
                        <Controller 
                            name="time_end"
                            control={control}
                            render={({name, field: {onChange}}) => (
                                <Flatpickr
                                    name={name}
                                    className="form-control"
                                    autoFocus={false}
                                    options={{
                                        enableTime: true,
                                        noCalendar: true,
                                        dateFormat: "H:i",
                                        time_24hr: true,
                                        mode: "time",
                                    }}
                                    value={getValues('time_end') || ''}
                                    onChange={([date]) => onChange(format(date, "HH:mm"))}
                                />
                            )}
                        />
                    </Col>

                    <Col md={12}>
                        <div className="form-check form-switch">
                            <input {...register('active')} className="form-check-input fs-16" type="checkbox" role="switch" id="active-nets" />
                            <CustomLabel className="form-check-label" htmlFor="active-nets">Active</CustomLabel>
                        </div>
                    </Col>

                </Row>

                <Row className='mt-3'>
                    <Col>
                        <CustomButton
                          className="btn-success text-capitalize"
                          type="button"
                          onClick={handleSubmit(submitForm)}
                        >
                            {type}
                        </CustomButton>
                    </Col>
                </Row>

        </ModalBody>
    </Modal>
  )
  
}

export default FoodDateModal

