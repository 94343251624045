import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Col, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import CustomButton from "../../../Custom/CustomButton";
import CustomLabel from "../../../Custom/CustomLabel";
import Flatpickr from "react-flatpickr";
import { format } from "date-fns";
import toastAlert from "../../../../helpers/toastAlert";
import { yupResolver } from "@hookform/resolvers/yup";
import specialDaySchema from "../../../../schemas/specialDaySchema";
import { errorMessage } from "../../../../helpers/errorMessage";
import { useTranslation } from "react-i18next";
import generateUniqueID from "../../../../helpers/generateUniqueID";

const SpecialDayModal = ({ toggle, initValues, type, setData }) => {
  
  const { t } = useTranslation();

  const { register, handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: type === "Update" ? initValues : {},
    resolver: yupResolver(specialDaySchema),
  });

  const onSubmit = async (body) => {
    const requestBody = {
      id: generateUniqueID(),
      open: body.open,
      date_from: body.date_from,
      date_to: body.date_to,
      time_start: body.time_start?.slice(0, 5),
      time_end: body.time_end?.slice(0, 5),
    };
    try {
      if (type === "Create") {
        setData(prev => [...prev, requestBody]);
      } else {
        setData(prev => prev.map(item => {
            if (item.id === initValues.id) return requestBody;
            return item
        }))
      }
      toggle();
    } catch (error) {
      toastAlert({ type: 'error' });
    }
  };

  return (
    <>
      <ModalHeader toggle={toggle}>{t("Date")}</ModalHeader>
      <ModalBody>
        <Row className="gy-2">
          <Col md={6}>
            <CustomLabel>From</CustomLabel>
            <Controller
              name="date_from"
              control={control}
              render={({ field }) => {
                return (
                  <Flatpickr
                    className="form-control"
                    value={field.value}
                    onChange={([date]) =>
                      field.onChange(format(date, "yyyy-MM-dd"))
                    }
                  />
                );
              }}
            />
            {errorMessage(errors, "date_from")}
          </Col>
          <Col md={6}>
            <CustomLabel>To</CustomLabel>
            <Controller
              name="date_to"
              control={control}
              render={({ field }) => {
                return (
                  <Flatpickr
                    className="form-control"
                    value={field.value}
                    onChange={([date]) =>
                      field.onChange(format(date, "yyyy-MM-dd"))
                    }
                  />
                );
              }}
            />
            {errorMessage(errors, "date_to")}
          </Col>
          <Col md={6}>
            <CustomLabel>Start</CustomLabel>
            <Controller
              name="time_start"
              control={control}
              render={({ field }) => {
                return (
                  <Flatpickr
                    className="form-control"
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i",
                      time_24hr: true,

                      mode: "time",
                    }}
                    value={field.value}
                    onChange={([date]) => field.onChange(format(date, "HH:mm"))}
                  />
                );
              }}
            />
            {errorMessage(errors, "time_start")}
          </Col>
          <Col md={6}>
            <CustomLabel>End</CustomLabel>
            <Controller
              name="time_end"
              control={control}
              render={({ field }) => {
                return (
                  <Flatpickr
                    className="form-control"
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i",
                      time_24hr: true,
                      mode: "time",
                    }}
                    value={field.value}
                    onChange={([date]) => field.onChange(format(date, "HH:mm"))}
                  />
                );
              }}
            />
            {errorMessage(errors, "time_end")}
          </Col>
          <Col md={6}>
            <div className="form-check form-switch">
              <input
                name="active"
                className="form-check-input fs-16"
                type="checkbox"
                role="switch"
                {...register("open")}
              />
              <CustomLabel>Open</CustomLabel>
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          onClick={handleSubmit(onSubmit)}
          color="success"
        >
          {type}
        </CustomButton>
      </ModalFooter>
    </>
  );
};

export default SpecialDayModal;
