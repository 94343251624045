import { useMemo, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const TextEditor = (props) => {

  const quillRef = useRef(null);

  const modules = useMemo(() => {

    return {
      toolbar: {
        container: [
          [{ font: [] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ script: "sub" }, { script: "super" }],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
          ["link", "image", "video"],
          ["clean"],
        ],
      },
    };
  }, []);
  
  return (
    <ReactQuill
        {...props}
        ref={quillRef}
        theme="snow"
        className="editor-textarea limit-2"
        modules={modules}
        style={{ flex: 1 }}
    />
  );
};

export default TextEditor;
