import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Card, CardBody, CardFooter, Col, Row } from "reactstrap";
import {
  createCouponApi,
  updateCouponApi,
} from "../../../apis/Orders/couponApis";
import { errorMessage } from "../../../helpers/errorMessage";
import { formatExample } from "../../../helpers/formatExample";
import toastAlert from "../../../helpers/toastAlert";
import useActions from "../../../hooks/useActions";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import couponSchema from "../../../schemas/couponSchema";
import CustomButton from "../../Custom/CustomButton";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import CouponFormTabs from "./CouponFormTabs";
import CouponDiscount from "./CouponDiscount/CouponDiscount";
import { couponRqBody } from "../../../helpers/requests/Order/couponRqBody";

const CouponForm = ({ type, initialValues, setCouponValues }) => {

  const { isLoading, onLoading, onFinally } = useActions();

  const [discount, setDiscount] = useState(null);

  const { push } = useHistory();

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type_delivery: '2',
      for_delivery: '0',
      all_products: 1,
      users: 0
    },
    resolver: yupResolver(couponSchema(discount)),
  });

  const onSubmit = async (data) => {
    onLoading();
    try {
      if (type === "Create") {
        const rqBody = couponRqBody(data);
        await createCouponApi(rqBody);
        push(getPathname("orders/coupons"));
      } else {
        const rqBody = couponRqBody(data);
        const { data: { data: resData } } = await updateCouponApi(initialValues.id, rqBody);
        toastAlert({ type: 'success' });
        setCouponValues(resData);
        onFinally();
      }
    } catch (error) {
      onFinally();
      toastAlert({ type: 'error' });
    }
  };

  useEffect(() => {
    if (initialValues) {
      reset((() => {
        const { for_delivery, type_delivery, ...rest } = initialValues;
        let all_products = initialValues.all_products?.key ? 1 : 0;
        let users = initialValues.users?.key ?? 0;
        return {
          ...rest,
          for_delivery: for_delivery?.key ?? '0',
          type_delivery: type_delivery?.key ?? '2',
          all_products,
          users
        }
      })());
    }
  }, [initialValues]);

  return (
    <Card>
      <CardBody>
        <Row className="gy-3">

          <Col md={6}>
            <CustomLabel>Code</CustomLabel>
            <CustomInput
              {...register("code")}
              placeholder={formatExample("etb1XfHS")}
            />
            {errorMessage(errors, "code")}
          </Col>

          <Col md={6} className="d-flex align-items-center">
            <div className="form-check form-switch">
              <input
                className="form-check-input fs-16"
                type="checkbox"
                role="switch"
                {...register("status")}
              />
              <CustomLabel className="form-check-label">Active</CustomLabel>
            </div>
          </Col>

          <Col md={6}>
            <CustomLabel>Title</CustomLabel>
            <CustomInput
              {...register("title")}
              placeholder={formatExample("Happy Holidays")}
            />
            {errorMessage(errors, "title")}
          </Col>

          <Col md={6}>
            <CustomLabel>Description</CustomLabel>
            <CustomInput
              {...register("description")}
              placeholder={formatExample(
                "Celebrating the holidays, with this coupon we offer you 10% off of everything."
              )}
            />
            {errorMessage(errors, "description")}
          </Col>

          <Col>
            <CouponDiscount
              register={register}
              setValue={setValue}
              discount={discount}
              setDiscount={setDiscount}
              initialValues={initialValues}
              errors={errors}
            />
          </Col>

          <Col md={6}>
            <CustomLabel>Count use</CustomLabel>
            <CustomInput
              {...register("count_use")}
              type="number"
              placeholder={formatExample("432")}
            />
            {errorMessage(errors, "count_use")}
          </Col>

          <Col md={6}>
            <CustomLabel>Min price</CustomLabel>
            <CustomInput
              {...register("min_price")}
              type="number"
              placeholder={formatExample("322")}
            />
            {errorMessage(errors, "min_price")}
          </Col>

          <Col xs={12}>
            <div className="form-check form-switch">
              <input
                className="form-check-input fs-16"
                type="checkbox"
                role="switch"
                {...register("user_multi_use")}
              />
              <CustomLabel className="form-check-label">Multi use</CustomLabel>
            </div>
          </Col>

          <CouponFormTabs
            register={register}
            control={control}
            errors={errors}
            initialValues={initialValues}
            type={type}
            watch={watch}
            setValue={setValue}
          />
          
        </Row>
      </CardBody>
      <CardFooter>
        <CustomButton
          color="success"
          isLoading={isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {type}
        </CustomButton>
      </CardFooter>
    </Card>
  );
};

export default CouponForm;
