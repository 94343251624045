import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const PageTitle = ({ children, className = "" }) => {
  const { t } = useTranslation();
  return (
    <h4 className={classNames("fs-16 text-capitalize", className)}>
      {t(children)}
    </h4>
  );
};

export default PageTitle;
