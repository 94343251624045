export const validateValues = (values, setErrors) => {

    let isError = false;

    let errors = {
    }

    Object.keys(values).forEach(key => {
        if (!values[key]) {
            errors[key] = 'Required';
            isError = true
        } else {
          errors[key] = null
        }
    })
    
    setErrors(prev => ({
      ...prev,
      ...errors,
    }))

    return { isError }
  }
