import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import API_URL from "./config/api";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `${API_URL}/dash/langkey`,
      addPath: `${API_URL}/dash/langkey`,
      parse: (data, lng) => {
        const langData =  Object.fromEntries(Object.entries(JSON.parse(data).data).map(([key, val]) => {
          return [key, val[lng]]
        }))
        return langData
      },
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
