import React, { useState } from 'react'
import PageContainer from '../../../Components/Layout/PageContainer'
import { Card, CardBody, CardHeader } from 'reactstrap';
import Nestable from 'react-nestable';
import { foodApi } from '../../../apis/Food/api';
import toastAlert from '../../../helpers/toastAlert';
import CustomButton from '../../../Components/Custom/CustomButton';
import Text from '../../../Components/Custom/Text';
import { useQuery } from '@tanstack/react-query';

const SortSizes = () => {

  const [sortedList, setSortedList] = useState(null);

  const [submitting, setSubmitting] = useState(false);

  useQuery([], () => foodApi.types('sizes'), {
    onSuccess({data: { data }}) {
      let types = {};
      data.forEach(type => {
        types[type.name] = type.sizes?.map(size => ({
          id: size.id,
          name: size.name,
          image: size.icon || null,
          class: 'nested-1'
        })) ?? []
      })
      setSortedList(types);
    }
  })

  const submitForm = async () => {
    setSubmitting(true);
    const requestBody = Object.values(sortedList).reduce((accum, items) => {
      return [...accum, ...items.map((item, index) => ({
        id: item.id,
        sort: index + 1
      }))]
    }, [])
    try {
      await foodApi.sortItems({
        type: 'sizes',
        items: requestBody
      });
      toastAlert({ type: 'success' });
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <PageContainer>
       <Card>
          <CardHeader className='d-flex align-items-center justify-content-between'>
              <span className='fs-18 fw-bold'><Text>Sort Sizes</Text></span>

              <div className='hstack gap-2'>
                <CustomButton
                  type="button"
                  className="btn btn-dark"
                  onClick={() => window.history.go(-1)}
                >
                    Back
                </CustomButton>

                <CustomButton
                  type="button"
                  className="btn btn-success"
                  onClick={submitForm}
                  isLoading={submitting}
                >
                    Save
                </CustomButton>
              </div>

          </CardHeader>

          <CardBody className='d-flex flex-column gap-4'>
              {sortedList && Object.entries(sortedList).filter(([key, val]) => val.length).map(([key, val], index) => (
                  <div key={index} className='border'>
                      <div className='border-bottom fs-18 text-uppercase fw-bold py-3 px-4'>
                          {key}
                      </div>
                      <Nestable
                        items={val}
                        renderItem={({item}) => {
                          return (
                            <div className="item-title-container">
                                {item.image && <img src={item.image} alt={item.image} />}
                                <span>{item.name}</span>
                            </div>
                          )
                        }}
                        onChange={({ items }) => {
                          setSortedList(prev => ({
                            ...prev,
                            [key]: items
                          }))
                        }}
                        maxDepth={1}
                        className='px-4 py-3'
                      />
                  </div>
              ))
            }
          </CardBody>
       </Card>
    </PageContainer>
  )
}

export default SortSizes