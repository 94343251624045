import React from "react";
import PageContainer from "../../../Components/Layout/PageContainer";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import PrinterForm from "../../../Components/Food/Printers/Form";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { foodApi } from "../../../apis/Food/api";
import { useFetchByLang } from "../../../hooks/useFetchByLang";

const UpdatePrinter = () => {
  
  const { id } = useParams();

  const [initialValues, setInitialValues] = useState(null);

  const { isLoading, isRefetching, refetch } = useQuery(["printers", id], () => foodApi.printers.getOne(id, language),
    {
      enabled: false,
      cacheTime: 0,
      onSuccess({data: {data}}) {
        setInitialValues(data);
      },
    }
  );

  const { language, setLanguage } = useFetchByLang(refetch);

  return (
    <PageContainer isLoading={isLoading || isRefetching}>
      <BreadCrumb title="Update" pageTitle="Printers" to={"food/printers"} />
      {initialValues && 
        <PrinterForm 
          type="update"  
          initialValues={initialValues} 
          language={language}
          setLanguage={setLanguage}
        />
      }
    </PageContainer>
  );
};

export default UpdatePrinter;
