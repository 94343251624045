import React, { useState } from "react";
import { Col, Nav, NavItem, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import CustomNavLink from "../../../Custom/CustomNavLink";
import SliderURLs from "./URL";
import SliderFiles from "./Files";
import { useTranslation } from "react-i18next";

const CreateSliderTabs = ({ register, setValue, media, setMedia }) => {
  const { t } = useTranslation();

  const [activeTab, setactiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  return (
    <Col md={12} className="mt-4">
      <Nav tabs className="nav-tabs mb-3">
        <NavItem>
          <CustomNavLink
            style={{ cursor: "pointer" }}
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
          >
            Files
          </CustomNavLink>
        </NavItem>

        <NavItem>
          <CustomNavLink
            style={{ cursor: "pointer" }}
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggle("2");
            }}
          >
            URLs
          </CustomNavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab} className="text-muted">
        <TabPane tabId="1" id="files">
          <SliderFiles media={media} setMedia={setMedia} />
        </TabPane>

        <TabPane tabId="2" id="urls">
          <SliderURLs register={register} />
        </TabPane>
      </TabContent>
    </Col>
  );
};

export default CreateSliderTabs;
