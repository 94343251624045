import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'react-phone-input-2/lib/style.css'
import "react-nestable/dist/styles/index.css";
//import Scss
import "./assets/scss/themes.scss";
// Import Wrapper
import AppWrapper from "./Components/Wrappers/AppWrapper";
//imoprt Route
import Route from "./Routes";
import SetupWrapper from "./Components/Wrappers/SetupWrapper";
import NotificationWrapper from "./Components/Wrappers/NotificationWrapper";
import ErrorBoundary from "./Components/Guard/ErrorBoundary";
import 'grapesjs/dist/css/grapes.min.css';

function App() {

  return (
    <ErrorBoundary>
      <AppWrapper>
          <SetupWrapper>
            <NotificationWrapper>
              <Route />
            </NotificationWrapper>
            <ToastContainer />
          </SetupWrapper>
      </AppWrapper>
    </ErrorBoundary>
  );
}

export default App;
