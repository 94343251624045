import React from 'react'
import { Col, Row } from 'reactstrap'
import CreateButton from '../../../../../../Common/Buttons/CreateButton'
import DeleteButton from '../../../../../../Common/Buttons/DeleteButton'
import FoodDaysTable from './Table'
import useDeleteModal from '../../../../../../../hooks/useDeleteModal'
import FoodDayModal from './Modal'
import useFormModal from '../../../../../../../hooks/useFormModal'

const FoodDays = ({data, setData}) => {

  const {selectedItems, setSelectedItems, modal, setModal} = useDeleteModal();

  const {isOpen: modalOpened, toggleModal, type,  openModal, initialValues} = useFormModal();

  const handleDelete = () => {
      const ids = selectedItems.map(item => item.id);
      setData(prev => prev.filter(item => !ids.includes(item.id)));
      setModal(false);
      setSelectedItems([]);
  }

  return (
    <div>
       <Row className='justify-content-between gy-3'>

        <Col md={3} xs={12} className="d-flex gap-2">
            <CreateButton 
              type="button"
              className="btn-sm"
              onClick={() => openModal('create')}
            >
              Create Day
            </CreateButton>

            <DeleteButton 
              type="button"
              className="btn-sm"
              disabled={!selectedItems.length}
              onClick={() => setModal(true)}
            >
              Delete Day
            </DeleteButton>
        </Col>

      </Row>

      <FoodDayModal 
          type={type}
          isOpen={modalOpened}
          toggleModal={toggleModal}
          initialValues={initialValues}
          setData={setData}
      />

      <div className='mt-3'>
        <FoodDaysTable 
          data={data}
          setSelectedItems={setSelectedItems}
          modal={modal}
          setModal={setModal}
          handleDelete={handleDelete}
          openFormModal={openModal}
        />
      </div>
    </div>
  )
}

export default FoodDays