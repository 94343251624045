import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Col } from "reactstrap";
import {
  getContactApi,
  setContactApi,
} from "../../../apis/Settings/webSettingsApi";
import toastAlert from "../../../helpers/toastAlert";
import useActions from "../../../hooks/useActions";
import useCustomForm from "../../../hooks/useCustomForm";
import LoaderComponent from "../../Common/LoaderComponent";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import WebSettingsBody from "./WebSettingsBody";
import WebSettingsFooter from "./WebSettingsFooter";

const ContactTab = () => {
  
  const { values, handleChange, setValues } = useCustomForm({
    address: "",
    email: "",
    phone: "",
    lat_long: "",
  });

  const { isLoading: isSubmitting, onLoading, onFinally } = useActions();

  const { isLoading } = useQuery(["web-settings", "contact"], getContactApi, {
    onSuccess(data) {
      setValues(data.data.data);
    },
  });

  const onSubmit = async () => {
    onLoading();
    try {
      await setContactApi(values);
      toastAlert({ type: 'success' });
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      onFinally();
    }
  };

  return (
    <LoaderComponent isLoading={isLoading}>
      <WebSettingsBody>
        <Col md={6}>
          <CustomLabel>Email</CustomLabel>
          <CustomInput
            name="email"
            value={values.email}
            onChange={handleChange}
          />
        </Col>
        <Col md={6}>
          <CustomLabel>Phone</CustomLabel>
          <CustomInput
            name="phone"
            value={values.phone}
            onChange={handleChange}
          />
        </Col>
        <Col md={6}>
          <CustomLabel>Address</CustomLabel>
          <CustomInput
            name="address"
            value={values.address}
            onChange={handleChange}
          />
        </Col>
        <Col md={6}>
          <CustomLabel>Latitude, Longitude</CustomLabel>
          <CustomInput
            name="lat_long"
            value={values.lat_long}
            onChange={handleChange}
          />
        </Col>
      </WebSettingsBody>
      <WebSettingsFooter isSubmitting={isSubmitting} onSubmit={onSubmit} />
    </LoaderComponent>
  );
};

export default ContactTab;
