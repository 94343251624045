import React, { useEffect } from "react";
import { Card, CardBody, CardFooter, Col, Row } from "reactstrap";
import CustomLabel from "../../Custom/CustomLabel";
import CustomInput from "../../Custom/CustomInput";
import { useForm } from "react-hook-form";
import CustomButton from "../../Custom/CustomButton";
import { errorMessage } from "../../../helpers/errorMessage";
import toastAlert from "../../../helpers/toastAlert";
import { useHistory } from "react-router-dom";
import { foodApi } from "../../../apis/Food/api";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import useActions from "../../../hooks/useActions";
import SelectLanguage from "../../Common/SelectLanguage";

const PrinterForm = ({ type, initialValues, language, setLanguage }) => {

  const { push } = useHistory();

  const { isLoading, onLoading, onFinally } = useActions();

  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const onSubmit = async (data) => {
    onLoading();
    try {
      if (type === "create") {
        await foodApi.printers.create(data, language);
        push(getPathname("food/printers"));
        toastAlert({ type: 'success' });
      } else if (type === 'update') {
        await foodApi.printers.update(initialValues.id, data, language);
        toastAlert({ type: 'success' });
        onFinally();
      }
    } catch (error) {
      onFinally();
      toastAlert({ type: 'error' });
    }
  };

  useEffect(() => {
    if (initialValues) {
      reset(initialValues);
    }
  }, [initialValues])

  return (
    <Card>
      <CardBody>

        {type === 'update' && 
              <div className='d-flex justify-content-end'>
                  <SelectLanguage
                    language={language}
                    onChange={lang => setLanguage(lang)}
                  /> 
              </div>
          }
          
        <Row className="gy-2">

          <Col md={6}>
            <CustomLabel translatable>Name</CustomLabel>
            <CustomInput 
              {...register("name")} 
            />
            {errorMessage(errors, "name")}
          </Col>

          <Col md={6}>
            <CustomLabel>Paper Size</CustomLabel>
            <CustomInput 
              type="number"
              {...register("paper_size")} 
            />
            {errorMessage(errors, "paper_size")}
          </Col>
          
          <Col md={6}>
            <CustomLabel>IP Address</CustomLabel>
            <CustomInput 
              {...register("ip")} 
            />
            {errorMessage(errors, "ip")}
          </Col>

          <Col md={6}>
            <CustomLabel>Port</CustomLabel>
            <CustomInput 
              type="number"
              {...register("port")} 
            />
            {errorMessage(errors, "port")}
          </Col>

          <Col md={6} className="d-flex align-items-center">
            <div className="form-check form-switch">
              <input
                className="form-check-input fs-16"
                type="checkbox"
                role="switch"
                id="is_stick"
                {...register("is_stick")}
              />
              <CustomLabel htmlFor="is_stick" className="form-check-label">
                Is Stick
              </CustomLabel>
            </div>
          </Col>

          <Col md={6} className="d-flex align-items-center">
            <div className="form-check form-switch">
              <input
                className="form-check-input fs-16"
                type="checkbox"
                role="switch"
                id="active"
                {...register("active")}
              />
              <CustomLabel htmlFor="active" className="form-check-label">
                Active
              </CustomLabel>
            </div>
          </Col>

        </Row>
      </CardBody>
      <CardFooter>
        <CustomButton
          isLoading={isLoading}
          onClick={handleSubmit(onSubmit)}
          color="success"
        >
          {type === 'create' ? 'Create' : 'Update'}
        </CustomButton>
      </CardFooter>
    </Card>
  );
};

export default PrinterForm;
