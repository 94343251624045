import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import { foodApi } from '../../../../../../apis/Food/api';
import { foodFormTabs } from '../../../../../../data/foodFormTabs';
import ProductsSelection from './Selection';

const Products = ({foodType, initialValues, setValue}) => {

  const [categories, setCategories] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState(null);

  const [allItems, setAllItems] = useState(null);

  const [data, setData] = useState(null);

  const { refetch } = useQuery(['all-categories'], () => foodApi.categories.getAll({ page_size: 'all' }), {
    enabled: false,
    onSuccess({data: {data}}) {
      setCategories(data);
    }
  })

  const fetchAllItems = async () => {
    const data = await Promise.all(categories.map(async (item) => {
      const {data: { data }} = await foodApi.menu.getAll({
        category_id: item.id,
        page_size: 'all'
      })
      let newData = data.filter(it => it.id !== initialValues?.id).map(it => ({
        ...it,
        category_id: item.id
      }))
      return newData
    }))
   setAllItems(data.flat());
  }
  
  // Format list of categories with it's poducts

  useEffect(() => {
    if (allItems) {
        setData(categories.map(category => {
          const initialVal = initialValues?.multi?.find(multi => multi.category_id === category.id);
          return {
            category_id: category.id,
            count: initialVal?.count ?? 0,
            products: allItems.filter(item => item.category_id === category.id).map(item => ({
              id: item.id,
              name: item.name,
              included: initialVal?.foods?.some(food => food.id === item.id) ?? false,
              is_fix: initialVal?.foods?.find(food => food.id === item.id)?.is_fix ?? false,
            }))
          }
        }))
    }
  }, [allItems, initialValues])

  useEffect(() => {
    if (data) {
      setValue('multi', data.filter(item => item.products.some(product => product.included)).map(item => ({
        category_id: item.category_id,
        count: item.count,
        food_objs: item.products.filter(prod => prod.included).map(prod => ({
          id: prod.id,
          is_fix: prod.is_fix
        }))
      })))
    }
  }, [data])

  useEffect(() => {
    if (categories?.length) {
      setSelectedCategory(categories[0].id);
      fetchAllItems();
    }
  }, [categories])


  useEffect(() => {
    if (foodFormTabs.find(item => item.id === foodType)?.tabs?.some(tab => tab.value === 'products')) {
      refetch()
    } else {
      setValue('multi', null);
    }
  }, [foodType])

  return (
    <div>
        <div className='selectable-tabs-container'>
          {categories && categories.map(category => (
              <div
                key={category.id} 
                className={`selectable-tab heading-tab ${selectedCategory === category.id ? 'selected-tab' : ''} py-1`}
                onClick={() => setSelectedCategory(category.id)}
              >
                {category.name}
              </div>
            ))}
        </div>
        {data && data.map(item => (
          <ProductsSelection 
            key={item.category_id}
            category={item.category_id}
            selectedCategory={selectedCategory}
            data={data?.find(item => item.category_id === selectedCategory) || []}
            setData={setData}
          />
        ))}
    </div>
  )
}

export default Products