import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Card, Col, Row } from 'reactstrap'
import { formatExample } from '../../../helpers/formatExample'
import useActions from '../../../hooks/useActions'
import { getPathname } from '../../../Layouts/LayoutMenuData'
import { createFoodAction, updateFoodAction } from '../../../redux/actions/foodActions'
import CustomButton from '../../Custom/CustomButton'
import CustomInput from '../../Custom/CustomInput'
import CustomLabel from '../../Custom/CustomLabel'
import SelectLanguage from '../../Common/SelectLanguage'

const DonenessForm = ({type, initialValues = null, language, setLanguage}) => {

    const {register, handleSubmit, reset} = useForm();
  
    const {isLoading, onLoading, onFinally, onError} = useActions();
  
    const dispatch = useDispatch();
  
    const history = useHistory();
  
    const submitForm = (data) => {
      onLoading();
      if (type === 'create') {
        dispatch(createFoodAction({
          field: 'doneness',
          data,
          language
        }, {
          onSuccess() {
            onFinally();
            history.push(getPathname('food/doneness'))
          },
          onError
        }))
      } else {
        dispatch(updateFoodAction({
          field: 'doneness',
          id: initialValues.id,
          data,
          language
        }, {
          onSuccess: onFinally,
          onError
        }))
      }
      
    }

    useEffect(() => {
      if (initialValues) {
        reset(initialValues)
      }
    }, [initialValues])

    return (
        <Card className='p-3'>
            {type === 'update' && 
                <div className='d-flex justify-content-end'>
                    <SelectLanguage
                      language={language}
                      onChange={lang => setLanguage(lang)}
                    /> 
                </div>
            }

            <form onSubmit={handleSubmit(submitForm)}>
                <Row>
                  <Col md={6}>
                    <CustomLabel translatable>
                        Name
                    </CustomLabel>
                    <CustomInput 
                        type="text"
                        {...register('name')}
                        placeholder={formatExample("Medium Rare")}
                    />
                  </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <CustomButton
                            className='btn-success'
                            isLoading={isLoading}
                        >
                            {type === 'create' ? 'Create' : 'Update'}
                        </CustomButton>
                    </Col>
                </Row>
            </form>
        </Card>
      )
}

export default DonenessForm