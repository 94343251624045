import { useTranslation } from "react-i18next";

const Text = ({ children }) => {

  const { t } = useTranslation();

  return typeof children === 'string' ? t(children) : children
};

export default Text;
