import { getAuthenticatedAdmin, loginApi } from "../../apis/Admin/adminApis";
import extractError from "../../helpers/extractError";
import { adminActions } from "../slices/adminSlice";

export const loginAction = (body, actions) => async (dispatch) => {
  try {
    actions.onLoading();
    const { data } = await loginApi(body);
    dispatch(
      adminActions.login({
        token: data.data.token,
        user: data.data.user,
      })
    );
    actions.onSuccess();
  } catch (error) {
    actions.onError(extractError(error));
  }
};

export const getAdminAction = (actions) => async (dispatch) => {
  try {
    if (actions?.onLoading) {
      actions.onLoading();
    }
    const { data } = await getAuthenticatedAdmin();
    dispatch(
      adminActions.getAdminData(data.data)
    );
    if (actions?.onSuccess) {
      actions.onSuccess();
    }
  } catch (error) {
    if (actions?.onError) {
      actions.onError(extractError(error));
    }
  }
};

