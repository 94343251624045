import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Card, Col, Row } from 'reactstrap'
import toastAlert from '../../../helpers/toastAlert'
import useActions from '../../../hooks/useActions'
import { getPathname } from '../../../Layouts/LayoutMenuData'
import CustomButton from '../../Custom/CustomButton'
import CustomInput from '../../Custom/CustomInput'
import CustomLabel from '../../Custom/CustomLabel'
import { createTableService, updateTableService } from '../../../apis/Orders/tableServicesApi'
import CustomOption from '../../Custom/CustomOption'
import { useState } from 'react'
import SelectFile from '../../Common/SelectFile'

const TableServiceForm = ({ type, initialValues = null }) => {

    const [image, setImage] = useState({
        id: null,
        file: null,
        url: ''
    })

    const {register, handleSubmit, reset}  = useForm();

    const {isLoading, onLoading, onFinally, onError} = useActions();

    const history = useHistory();

    const submitForm = async (data) => {
        onLoading();
        try {
            const requestBody = new FormData();
            Object.entries(data).forEach(([key, val]) => {
                if (key === 'active' || key === 'is_image_transparent') requestBody.append(key, val ? 1 : 0);
                else requestBody.append(key, val);
            })
            if (image.file) {
                const file = image.file;
                const blob = new Blob([file], { type: file.type });
                requestBody.append("image", blob, file.name);
            }
            if (type === 'create') {
                await createTableService(requestBody);
                toastAlert({ type: 'success' });
                history.push(getPathname('orders/table-services'))
            } else {
                const { data: { data }} = await updateTableService(initialValues?.id, requestBody);
                if (image.file && data.file) {
                    setImage({
                        id: data.file.id,
                        url: data.file.link,
                        file: null
                    })
                }
                toastAlert({ type: 'success' });
            }
        } catch (error) {
            toastAlert({ type: 'error' });
            onError();
        } finally {
            onFinally();
        }
    }

    useEffect(() => {
        if (initialValues) {
            const { image, file, ...rest } = initialValues
            reset(rest);
            if (initialValues.file) {
                setImage({
                    id: initialValues.file.id,
                    url: initialValues.file.link,
                    file: null,
                })
            }
        }
    }, [initialValues])

  return (

    <form onSubmit={handleSubmit(submitForm)}>
        <Row>

            <Col lg={3}>
                <Card className='p-3'>
                    <CustomLabel>Image</CustomLabel>
                    <SelectFile 
                        selectedFiles={image}
                        setSelectedFiles={setImage}
                        type='image'
                    />
                    {!!image.url &&
                        <div className="form-check form-switch mt-3">
                            <input 
                                {...register('is_image_transparent')} 
                                type="checkbox" 
                                className="form-check-input fs-16" 
                                role="switch" 
                                id="is_image_transparent" 
                                disabled={!image.url} 
                            />
                            <CustomLabel className="form-check-label" htmlFor="is_image_transparent">Is transparent</CustomLabel>
                        </div>
                }
                </Card>
            </Col>

            <Col lg={9}>
                <Card className='p-3'>

                    <Row className='gy-4'>

                        <Col lg={6}>
                            <CustomLabel>
                                Title
                            </CustomLabel>
                            <CustomInput 
                                type="text"
                                {...register('title')}
                            />
                        </Col>

                        <Col lg={6}>
                            <CustomLabel>
                                Type
                            </CustomLabel>
                            <select
                                className='form-select'
                                {...register('type')}
                            >
                                {['main', 'other'].map((opt, index) => (
                                    <CustomOption key={index} value={opt}>
                                        {opt}
                                    </CustomOption>
                                ))}
                            </select>
                        </Col>

                        <Col lg={12}>
                            <div className='d-flex gap-4'>
                                <div className="form-check form-switch" style={{width: 'max-content'}}>
                                    <input {...register('active')} className="form-check-input fs-16" type="checkbox" role="switch" id="active" />
                                    <CustomLabel className="form-check-label" htmlFor="active">Active</CustomLabel>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className='mt-3'>
                        <Col className='d-flex gap-2'>
                            <CustomButton
                                className="btn-success text-capitalize"
                                isLoading={isLoading}
                            >
                                {type}
                            </CustomButton>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    </form>
  )
}

export default TableServiceForm