import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Nav,
  NavItem,
  Row,
} from "reactstrap";
import useActions from "../../../hooks/useActions";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import {
  createFoodAction,
  getFoodDataAction,
  updateFoodAction,
} from "../../../redux/actions/foodActions";
import CustomButton from "../../Custom/CustomButton";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import CustomNavLink from "../../Custom/CustomNavLink";
import classnames from "classnames";
import CustomTextArea from "../../Custom/CustomTextArea";
import Sidebar from "./Create/Sidebar";
import Prices from "./Create/Prices";
import SpiceLevels from "./Create/SpiceLevels";
import ComponentTabs from "./Create/Tabs";
import { foodRqBody } from "../../../helpers/requests/Food/foodRqBody";
import { formatExample } from "../../../helpers/formatExample";
import { yupResolver } from '@hookform/resolvers/yup';
import Doneness from "./Create/Doneness";
import SelectLanguage from "../../Common/SelectLanguage";
import foodSchema from "../../../schemas/foodSchema";
import DefaultBreadSize from "./Create/TabContents/DefaultBreadSize/DefaultBreadSize";
import CustomOption from "../../Custom/CustomOption";
import Text from "../../Custom/Text";

const FoodForm = ({ type, initialValues = null, language, setLanguage }) => {

  const [activeTab, setactiveTab] = useState(null);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  const { register, handleSubmit, reset, setValue, unregister, watch, formState: { errors } } = useForm({
    resolver: yupResolver(foodSchema),
    defaultValues: {
      active: true,
      best: true,
      takeaway: true,
      restaurent: true,
    }
  });

  const [image, setImage] = useState({
    url: "",
    file: null,
  });

  const [menuImg, setMenuImg] = useState({
    url: "",
    file: null,
  });

  const dispatch = useDispatch();

  const history = useHistory();

  const { isLoading, onLoading, onFinally, onError } = useActions();

  const { foodTypes: { list: foodTypes }, vat: { list: vat }} = useSelector((state) => state.food);

  const submitForm = (data) => {
    const requestBody = foodRqBody(data);
    onLoading();
    if (type === "create") {
      dispatch(
        createFoodAction(
          {
            field: "menu",
            data: {
              ...requestBody,
              isFood: true,
              ...(image.file ? { image: image.file } : {}),
              ...(menuImg.file ? { menu_image: menuImg.file } : {}),
            },
            language
          },
          {
            onSuccess() {
              onFinally();
              history.push(getPathname("food/menu"));
            },
            onError,
          }
        )
      );
    } else {
      dispatch(
        updateFoodAction(
          {
            field: "menu",
            id: initialValues.id,
            data: {
              ...requestBody,
              isFood: true,
              ...(image.file ? { image: image.file } : {}),
              ...(menuImg.file ? { menu_image: menuImg.file } : {}),
            },
            language
          },
          {
            onSuccess() {
              onFinally();
              history.push(getPathname("food/menu"));
            },
            onError,
          }
        )
      );
    }
  };

  useEffect(() => {
    dispatch(
      getFoodDataAction({
        field: "foodTypes",
      })
    );
    dispatch(
      getFoodDataAction({
        field: "vat",
      })
    );
  }, []);

  useEffect(() => {
    if (type === 'update' && initialValues) {
      if (activeTab !== initialValues.type_id?.toString()) {
        setactiveTab(initialValues.type_id?.toString());
      }
    } else if (type === 'create') {
      setactiveTab('1');
    }
  }, [initialValues]);

  useEffect(() => {
    if (initialValues) {
      reset(
        (() => {
          const { file, file_menu, menu_image, image, icon, ...rest } = initialValues;
          return rest;
        })()
      );
    }
  }, [initialValues]);

  useEffect(() => {
    if (activeTab) {
      setValue("type_id", +activeTab);
    }
  }, [activeTab]);

  useEffect(() => {
    if (initialValues && vat.length) {
      setValue("tax_id", initialValues?.tax_id);
      setValue("tax_in_id", initialValues?.tax_in_id);
    }
  }, [initialValues, vat]);

  return (
      <div>
        <Row>
          <Col lg={3} md={4}>
            <Sidebar
              register={register}
              setValue={setValue}
              initialValues={initialValues}
              image={image}
              setImage={setImage}
              menuImg={menuImg}
              watch={watch}
              setMenuImg={setMenuImg}
              foodType={+activeTab}
              errors={errors}
            />
          </Col>

          <Col lg={9} md={8}>
              <Card className="p-3">
                <Row className="gy-3">
                    <div className="d-flex gap-3">
                      <Nav tabs className="nav-tabs mb-3 nav-primary flex-1">
                        {foodTypes.map((type) => (
                          <NavItem key={type.id}>
                            <CustomNavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: activeTab === type.id.toString(),
                              })}
                              onClick={() => {
                                toggle(type.id.toString());
                              }}
                            >
                              {type.name}
                            </CustomNavLink>
                          </NavItem>
                        ))}
                      </Nav>

                      {type === 'update' && 
                        <SelectLanguage
                          language={language}
                          onChange={lang => setLanguage(lang)}
                        /> 
                      }
                    </div>

                  <Col md={6}>
                    <CustomLabel translatable>Name</CustomLabel>
                    <CustomInput
                      type="text"
                      {...register("name")}
                      placeholder={formatExample("World Famous Fries")}
                    />
                  </Col>

                  <Col md={6}>
                    <CustomLabel>Number</CustomLabel>
                    <CustomInput
                      type="number"
                      {...register("number")}
                      placeholder={formatExample("7842494")}
                    />
                  </Col>

                  <Col lg={12} className="d-flex flex-column border-top">
                    
                    <div className="py-3 text-center fs-18 text-success">
                      <Text>OFFER</Text>
                    </div>

                    <Row className="py-3 border-top border-bottom">

                      <Col lg={4}>
                        <CustomLabel translatable>Offer Name</CustomLabel>
                        <CustomInput
                          type="text"
                          {...register("offer_name")}
                          placeholder={formatExample(
                            "Special Offer."
                          )}
                        />
                      </Col>

                      <Col lg={4}>
                        <CustomLabel translatable>Offer Name Display</CustomLabel>
                        <select className="form-select" {...register('offer_name_display')}>
                            <CustomOption value="offer_name">Offer name</CustomOption>
                            <CustomOption value="product_name">Product name</CustomOption>
                            <CustomOption value="both">Both</CustomOption>
                        </select>
                      </Col>

                      <Col lg={4}>
                        <CustomLabel>Discount</CustomLabel>
                        <CustomInput
                          type="number"
                          {...register("discount")}
                        />
                      </Col>

                    </Row>

                  </Col>

                  <Col md={12}>
                    <CustomLabel translatable>Note</CustomLabel>
                    <CustomTextArea
                      {...register("note")}
                      placeholder={formatExample(
                        "World Famous Fries are made with premium potatoes such as the Russet Burbank and the Shepody."
                      )}
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col>
                    <CustomLabel>Vat</CustomLabel>
                    <select className="form-select" {...register("tax_id")}>
                    <CustomOption value="">Select Vat...</CustomOption>
                      {vat.map((vt) => (
                        <option key={vt.id} value={vt.id}>
                          {vt.name}
                        </option>
                      ))}
                    </select>
                  </Col>

                  <Col>
                    <CustomLabel>Vat in</CustomLabel>
                    <select className="form-select" {...register("tax_in_id")}>
                    <CustomOption value="">Select Vat in...</CustomOption>
                      {vat.map((vt) => (
                        <option key={vt.id} value={vt.id}>
                          {vt.name}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>

                <Row className="gy-3 mt-1">
                  {["1", "2", "3", "5", "6", "9"].includes(activeTab) ? (
                    <Col md={6}>
                      <SpiceLevels
                        foodType={+activeTab}
                        initialValues={initialValues}
                        setValue={setValue}
                      />
                    </Col>
                  ) : (
                    <></>
                  )}

                  {["1", "2", "3", "5", "6", "9"].includes(activeTab) ? (
                    <Col md={6}>
                      <Doneness
                        foodType={+activeTab}
                        initialValues={initialValues}
                        setValue={setValue}
                      />
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>

                {["4", "6", "7", "8"].includes(activeTab) ? (
                  <Prices register={register} unregister={unregister} />
                ) : (
                  <></>
                )}

                <Row className="mt-1 gy-3">
                  <Col lg={6}>
                    <CustomLabel>Min Person</CustomLabel>
                    <CustomInput
                      type="number"
                      {...register("min_person")}
                      placeholder={formatExample("5")}
                    />
                  </Col>

                  <Col lg={6}>
                    <CustomLabel translatable>Min Alert</CustomLabel>
                    <CustomInput
                      type="text"
                      {...register("min_person_alert")}
                      placeholder={formatExample(
                        "A Minimum of 5 is required to order this product."
                      )}
                    />
                  </Col>

                </Row>

                {["4"].includes(activeTab) ? (
                  <DefaultBreadSize 
                    initialValues={initialValues}
                    setValue={setValue}
                    watch={watch}
                  />
                ) : (
                  <></>
                )}

                <Row className="mt-3">
                  <ComponentTabs
                    initialValues={initialValues}
                    foodType={+activeTab}
                    setValue={setValue}
                    register={register}
                    watch={watch}
                  />
                </Row>
              </Card>
            <Card>
              <CardHeader>
                <CardTitle>Seo</CardTitle>
              </CardHeader>
              <CardBody>
                <Row className="gy-2">
                  <Col md={6}>
                    <CustomLabel>Title</CustomLabel>
                    <CustomInput {...register("seo_title")} />
                  </Col>
                  <Col md={6}>
                    <CustomLabel>Keywords</CustomLabel>
                    <CustomInput {...register("seo_keywords")} />
                  </Col>
                  <Col xs={12}>
                    <CustomLabel>Description</CustomLabel>
                    <CustomTextArea rows={3} {...register("seo_description")} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="mb-3 inner-submit-button">
              <CustomButton 
                className="btn-success" 
                isLoading={isLoading}
                onClick={handleSubmit(submitForm)}
              >
                {type === "create" ? "Create" : "Update"}
              </CustomButton>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="mb-3 outer-submit-button">
            <CustomButton 
              type='button'
              className="btn-success" 
              isLoading={isLoading}
              onClick={handleSubmit(submitForm)}
            >
              {type === "create" ? "Create" : "Update"}
            </CustomButton>
          </div>
        </Row>
      </div>
  );
};

export default FoodForm;
