import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import PageContainer from "../../../Components/Layout/PageContainer";
import Table from "../../../Components/Users/Users/Table";
import { useGetUsers } from "../../../hooks/useQueryHelpers";
import useSelectItems from "../../../hooks/useSelectItems";
import useModal from "../../../hooks/useModal";
import { deleteUsersApi } from "../../../apis/users/usersApi";
import useActions from "../../../hooks/useActions";
import usePagination from "../../../hooks/usePagination";
import useDebounceValue from "../../../hooks/useDebouneValue";
import ListHeader from "../../../Components/Common/ListHeader";

const Users = () => {
  
  const {
    isLoading: isDeleting,
    onLoading: onDeleting,
    onError,
    onFinally,
  } = useActions();

  const { isDisable, selectedItems, selectItems, getSelectedIds } = useSelectItems();
  
  const { isModalOpen, setIsModalOpen, openModal, closeModal } = useModal();

  const { currentPage, pageSize, setPageSize, navigateToPage } = usePagination();

  const [searchQuery, setSearchQuery] = useState("");

  const debouncedValue = useDebounceValue({
    value: searchQuery,
    cb: () => navigateToPage(1),
  });

  const { isLoading, users, deleteUsers, isRefetching, refetch } = useGetUsers({
    currentPage,
    pageSize,
    name: debouncedValue,
  });

  const onDeleteClick = async () => {
    onDeleting();
    try {
      const ids = getSelectedIds();
      await deleteUsersApi(ids);
      closeModal();
      deleteUsers(ids);
      refetch();
      selectItems([]);
    } catch (error) {
      onError();
    } finally {
      onFinally();
    }
  };

  useEffect(() => {
    refetch();
  }, [currentPage, pageSize, debouncedValue]);

  return (
    <PageContainer isLoading={isLoading}>
      <Row className="gy-4">
        <Col xs={12}>
          <ListHeader
            isDisable={isDisable}
            openModal={openModal}
            pathname="users/create"
          />
        </Col>
        <Col xs={12}>
          <Table
            isRefetching={isRefetching}
            data={users}
            selectItems={selectItems}
            modal={isModalOpen}
            selectedItems={selectedItems}
            setModal={setIsModalOpen}
            onDeleteClick={onDeleteClick}
            isDeleting={isDeleting}
            setPageSize={setPageSize}
            navigateToPage={navigateToPage}
            query={searchQuery}
            setQuery={setSearchQuery}
          />
        </Col>
      </Row>
    </PageContainer>
  );
};

export default Users;
