import React, { useCallback, useEffect, useState } from "react";
import { GoogleMap, Marker, LoadScript, InfoWindow } from "@react-google-maps/api";
import deliveryLocationImg from '../../../assets/images/icons/delivery-location.png'
import shopLocationImg from '../../../assets/images/icons/shop-location.png'
import { useSelector } from "react-redux";
import Pusher from 'pusher-js';
import { useTranslation } from "react-i18next";
import { isEmpty } from "../../../helpers/objectFilter";
import { Link } from 'react-router-dom'
import { getPathname } from "../../../Layouts/LayoutMenuData";
import Text from "../../Custom/Text";

const TrackDeliveryAgentsMap = ({ shopLocation, apiKey }) => {

    const { t } = useTranslation();

    const [map, setMap] = useState(null);

    const [pusher, setPusher] = useState(null);

    const [channel, setChannel] = useState(null);

    const { keysSettings } = useSelector(state => state.settings);

    const [pusherConfig, setPusherConfig] = useState(null);

    const [centerCoords, setCenterCoords] = useState(null);

    const [shopCoords, setShopCoords] = useState(null);

    const [agentsCoords, setAgentsCoords] = useState([]);

    const onLoad = useCallback(function callback(map) {
        setMap(map)
    }, [])

    // Register pusher to listen for agent location change event

    useEffect(() => {

      if (!pusher && pusherConfig) {

        const pusher = new Pusher(pusherConfig?.app_key, {
          cluster: pusherConfig?.cluster,
          forceTLS: true,
          encrypted: true,
        });

        setPusher(pusher);
          
        if (pusher && !channel) {
          const channel = pusher.subscribe("restaurant-delivery");
          setChannel(channel);
        }
      }

      () => {
        channel?.unbind();
        pusher?.disconnect();
        setChannel(null);
        setPusher(null);
      }

    }, [pusher, pusherConfig])

    // Get Pusher Configurations
    
    useEffect(() => {
      if (!isEmpty(keysSettings) && pusherConfig === null) {
        setPusherConfig({
          app_key: keysSettings.pusher_web?.app_key ?? 'eu',
          cluster: keysSettings.pusher_web?.app_cluster || ''
        }) 
      }
    }, [keysSettings])

    useEffect(() => {
        if (channel) {
            channel.unbind();
            channel.bind("map-changed", (data) => {
                const message = data.message;
                const newAgentData = {
                    id: message.delivery_id,
                    name: message.delivery_name,
                    coords: {
                        lat: message.lat,
                        lng: message.lang
                    },
                    orders: message.orders
                }
                if (agentsCoords.some(agent => agent.id === newAgentData.id)) {
                    setAgentsCoords(prev => prev.map(agent => {
                        if (agent.id === newAgentData.id) return newAgentData
                        return agent
                    }))
                } else {
                    setAgentsCoords(prev => [...prev, newAgentData])
                }
            });
        }
    }, [channel, agentsCoords])

    // Get shop coords

    useEffect(() => {
      let location = { lat: 62.058473593872534, lng: 9.501845677652613 };

      if (shopLocation) {
          const coords = shopLocation.split(',');
          if (coords?.length === 2) {
            location = { lat: +coords[0], lng: +coords[1] };
          }
      }
      setShopCoords(location);
      setCenterCoords(location);

    }, [shopLocation])

  if (!centerCoords) return <></>

  return (
    <LoadScript
        googleMapsApiKey={apiKey}
        onLoad={onLoad}
    >
        <GoogleMap
            mapContainerStyle={{
                height: "100vh",
                width: "100%",
            }}
            center={centerCoords}
            zoom={20}
            version="weekly"
        >

        {shopCoords && 
          <Marker 
            position={shopCoords} 
            icon={{
              url: shopLocationImg,
              scaledSize: window.google && new window.google.maps.Size(60, 60),        
            }}
            options={{
              label: {
                text: t('Shop'),
                className: 'google-maps-marker-label'
              }
            }}
          />
        }

        {agentsCoords?.map((agent, index) => (
              <React.Fragment key={agent.id ?? index}>

                <InfoWindow
                      position={agent.coords}
                      options={{ pixelOffset: new window.google.maps.Size(0, -70) }}
                  >
                      <div className="px-1">
                          <h4 className="text-danger fs-18">{agent.name}</h4>
                          <div className="d-flex gap-2 align-items-center">
                              <span className="fs-16 text-black"><Text>Orders:</Text></span>
                              <div className="d-flex gap-3">
                                  {agent.orders.map(order => (
                                      <Link className="fs-14 text-primary" key={order} to={getPathname(`orders/update/${order}`)} target="_blank">
                                          {order}
                                      </Link>
                                  ))}
                              </div>
                          </div>
                      </div>
                </InfoWindow>

                <Marker 
                    position={agent.coords} 
                    icon={{
                        url: deliveryLocationImg,
                        scaledSize: window.google && new window.google.maps.Size(60, 60),        
                    }}
                />
              </React.Fragment>
        ))}

        </GoogleMap>
    </LoadScript>
  )
}

export default TrackDeliveryAgentsMap;
