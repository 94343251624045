import React, { useState } from 'react'
import CustomButton from '../../../../../Custom/CustomButton'
import { Modal } from 'reactstrap'
import ComponentForm from '../../../../Components/Form'
import { useFetchByLang } from '../../../../../../hooks/useFetchByLang'

const AddComponent = ({ foodType, setData, setSelectedComponent }) => {

    const [isOpen, setIsOpen] = useState(false);

    const toggleModal = () => {
        setIsOpen(!isOpen);
    }

    const onSubmit = (data) => {
        if (data?.types?.find(item => +item.id === +foodType)) {
            setData(prev => ([...prev, 
                {
                    name: data.name,
                    component_id: data.id,
                    max_compo: '',
                    single_select: '0',
                    items: []
                }
            ]))
            setSelectedComponent(data.id);
        }
        toggleModal();
    }

    const { language } = useFetchByLang();

  return (
    <>
        <CustomButton 
            type="button" 
            className="btn-success btn-sm mx-2"
            onClick={toggleModal}
        >
            <i className="ri-add-line fs-16"></i>
        </CustomButton>

        <Modal
            centered 
            isOpen={isOpen}
            toggle={toggleModal}
        >
            <ComponentForm
                type="create"
                language={language}
                afterSubmit={onSubmit}
                preSelectedType={foodType}
            />
        </Modal>
    </>
  )
}

export default AddComponent