const { createSlice } = require("@reduxjs/toolkit");

const slice = createSlice({
  name: "food",
  initialState: {
    menu: {
      list: [],
      total: null,
    },
    categories: {
      list: [],
      total: null,
    },
    components: {
      list: [],
      total: null,
    },
    allergy: {
      list: [],
      total: null,
    },
    options: {
      list: [],
      total: null,
    },
    sizes: {
      list: [],
      total: null,
    },
    doughAndBread: {
      list: [],
      total: null,
    },
    doneness: {
      list: [],
      total: null,
    },
    spiceLevels: {
      list: [],
      total: null,
    },
    vat: {
      list: [],
      total: null,
    },
    printers: {
      list: [],
      total: null,
    },
    foodTypes: {
      list: [],
      total: null,
    },
  },
  reducers: {
    setData: (state, { payload }) => {
      if (state[payload.field]) {
        state[payload.field] = {
          list: payload.data,
          total: payload.total,
        };
      }
    },

    createData: (state, { payload }) => {
      if (state[payload.field]) {
        state[payload.field] = {
          ...state[payload.field],
          list: [...state[payload.field].list, payload.data],
        };
      }
    },

    updateData: (state, { payload }) => {
      if (state[payload.field]) {
        state[payload.field] = {
          ...state[payload.field],
          list: state[payload.field].list.map((item) =>
            item.id === payload.id ? payload.data : item
          ),
        };
      }
    },

    deleteData: (state, { payload }) => {
      if (state[payload.field]) {
        state[payload.field] = {
          total: state[payload.field].total - payload.ids.length,
          list: state[payload.field].list.filter(
            (item) => !payload.ids.includes(item.id)
          ),
        };
      }
    },
  },
});

export const foodActions = slice.actions;

export default slice.reducer;
