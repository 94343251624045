import React from 'react'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import ComponentForm from '../../../Components/Food/Components/Form'
import PageContainer from '../../../Components/Layout/PageContainer'
import { useFetchByLang } from '../../../hooks/useFetchByLang'

const CreateComponent = () => {

  const { language, setLanguage } = useFetchByLang();

  return (
    <PageContainer>
        <BreadCrumb pageTitle="Components" title="Create" to="food/components"/>
        <ComponentForm
          type="create"
          language={language}
          setLanguage={setLanguage}
        />
    </PageContainer>
  )
}

export default CreateComponent