import React from "react";
import { Col, Row } from "reactstrap";
import { formatExample } from "../../../../helpers/formatExample";
import CustomInput from "../../../Custom/CustomInput";
import CustomLabel from "../../../Custom/CustomLabel";

const SliderURLs = ({ register }) => {

  return (
    <Row className="gy-3">
      <Col md={6}>
        <CustomLabel translatable>Text</CustomLabel>
        <CustomInput 
          type="text" {...register("text")} 
          placeholder={formatExample("Order Now")}
        />
      </Col>

      <Col md={6}>
        <CustomLabel>Link</CustomLabel>
        <CustomInput 
          type="text" 
          {...register("link")} 
          placeholder={formatExample(`facebook.com/order`)}
        />
      </Col>

      <Col md={6}>
        <div className="form-check form-switch mb-2">
          <input
            {...register("android_active")}
            className="form-check-input fs-16"
            type="checkbox"
            role="switch"
            id="active"
          />
          <CustomLabel className="form-check-label" htmlFor="active">
            Android
          </CustomLabel>
        </div>
      </Col>

      <Col md={6}>
        <div className="form-check form-switch mb-2">
          <input
            {...register("ios_active")}
            className="form-check-input fs-16"
            type="checkbox"
            role="switch"
            id="active"
          />
          <CustomLabel className="form-check-label" htmlFor="active">
            IOS
          </CustomLabel>
        </div>
      </Col>
    </Row>
  );
};

export default SliderURLs;
