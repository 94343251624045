import JsBarcode from 'jsbarcode';

export const printBarcode = async (shelves, name) => {

  const options = {
    format: 'CODE128', // You can change the barcode format here
  };

  const barCodes = [];

  shelves.forEach(shelf => {
    const canvas = document.createElement('canvas');

    JsBarcode(canvas, shelf, options);
  
    barCodes.push(canvas.toDataURL('image/png'));
  })

  const win = window.open('', 'Barcode', 'height=1000, width=1000');

  win.document.write(/*html*/`
    <html>

      <head>
        <title>Barcodes</title>
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap" rel="stylesheet">
        <style>
            body {
                min-height: 100vh;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .barcodes-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 2rem;
            }
        </style>
      </head>

      <body>
          <div class="barcodes-container">
            ${barCodes.map(barcode => (
                /*html*/`
                <img
                    class="barcode"
                    src="${barcode}"
                >
                `
            )).join('')}
          </div>
      </body>

    </html>
  `);
};
