import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router-dom";
import { getOneUserApi } from "../../../apis/users/usersApi";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import PageContainer from "../../../Components/Layout/PageContainer";
import UserForm from "../../../Components/Users/Users/Form";

const UpdateUser = () => {
  const { id } = useParams();

  const { isLoading, data } = useQuery(["users", id], () => getOneUserApi(id));

  return (
    <PageContainer isLoading={isLoading}>
      <BreadCrumb pageTitle="Users" title="Update" to="users" />
      {data && <UserForm type="Update" initValue={data.data.data} />}
    </PageContainer>
  );
};

export default UpdateUser;
