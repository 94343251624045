import axiosInstance from "..";

export const getDeliverySettingsApi = (lang) => {
  return axiosInstance.get(`/order_delivery?lang=${lang}`);
};

export const setDeliverySettingsApi = (body, lang) => {
  return axiosInstance.post(`/order_delivery?lang=${lang}`, body);
};

export const getZones = (query) => {
  return axiosInstance.get("/zones", {
    params: query
  });
};

export const setZones = (data) => {
  return axiosInstance.post("/zones", data);
};

export const deleteZone = (ids) => {
  return axiosInstance.delete("/zones", {
    data: {
      ids,
    },
  });
};

export const getDeliveryAgents = () => {
  return axiosInstance.get(`/dash/orders/onDelivery`);
};


