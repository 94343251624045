import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import {
  getContactApi,
  setContactApi,
} from "../../../apis/Settings/webSettingsApi";
import toastAlert from "../../../helpers/toastAlert";
import useActions from "../../../hooks/useActions";
import useCustomForm from "../../../hooks/useCustomForm";
import LoaderComponent from "../../Common/LoaderComponent";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import SetupActions from "../SetupActions";
import { validateValues } from "../../../helpers/validateValues";

const ContactsSetup = ({ id, next, skip }) => {

  const { values, handleChange, setValues } = useCustomForm({
    address: "",
    email: "",
    phone: "",
    lat_long: "",
  });

  const [errors, setErrors] = useState({
    address: null,
    email: null,
    phone: null,
    lat_long: null,
  })

  const { isLoading: isSubmitting, onLoading, onFinally } = useActions();

  const { isLoading } = useQuery(["web-settings", "contact"], getContactApi, {
    onSuccess({data: { data }}) {
      setValues(data);
    },
  });

  const onChange = (e) => {
      handleChange(e)
      if (errors[e.target.name]) {
        setErrors({
          ...errors,
          [e.target.name]: null
        })
      }
  }

  const onSubmit = async () => {
    const { isError } = validateValues(values, setErrors);
    if (isError) return
    onLoading();
    try {
      await setContactApi(values);
      next(id);
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      onFinally();
    }
  };

  return (
    <LoaderComponent isLoading={isLoading}>
      <Row className="gy-3">
        <Col md={6}>
          <CustomLabel>Email</CustomLabel>
          <CustomInput
            name="email"
            value={values.email}
            onChange={onChange}
          />
          {errors['email'] && <span className="text-danger">{errors['email']}</span>}
        </Col>
        <Col md={6}>
          <CustomLabel>Phone</CustomLabel>
          <CustomInput
            name="phone"
            value={values.phone}
            onChange={onChange}
          />
          {errors['phone'] && <span className="text-danger">{errors['phone']}</span>}
        </Col>
        <Col md={6}>
          <CustomLabel>Address</CustomLabel>
          <CustomInput
            name="address"
            value={values.address}
            onChange={onChange}
          />
          {errors['address'] && <span className="text-danger">{errors['address']}</span>}
        </Col>
        <Col md={6}>
          <CustomLabel>Latitude, Longitude</CustomLabel>
          <CustomInput
            name="lat_long"
            value={values.lat_long}
            onChange={onChange}
          />
          {errors['lat_long'] && <span className="text-danger">{errors['lat_long']}</span>}
        </Col>
      </Row>

      <SetupActions id={id} submit={onSubmit} skip={skip} isLoading={isSubmitting} />

    </LoaderComponent>
  );
};

export default ContactsSetup;
