import { useEffect, useState } from "react";

export default function useFormModal() {
    // const [data, setData] = useState(initState);
    const [isOpen, setIsOpen] = useState(false);
    const [type, setType] = useState('create');
    const [initialValues, setInitialValues] = useState(null);

    const closeModal = () => {
        setIsOpen(false)
    }

    const toggleModal = () => {
        setIsOpen((current) => !current)
    }

    const openModal = (type, data) => {
        setType(type)
        if (data) {
            setInitialValues(data);
        }
        setIsOpen(true);
    }

    useEffect(() => {
        if (!isOpen) {
            setInitialValues(null);
            setType('create');
        }
    }, [isOpen])

    return {
        type, isOpen, openModal, closeModal, toggleModal, initialValues
    }

}