export default function formatFormData(data) {
  
  const formData = new FormData();

  Object.entries(data).forEach(([key, val]) => {
    if ( val !== null || val !== undefined ||  val !== "null" || val !== "undefined") {
      if (Array.isArray(val)) {
        val.forEach((value) => {
          formData.append(`${key}[]`, value);
        });
      } else if (typeof val === "boolean") {
        formData.append(key, +val);
      } else if (val instanceof File) {
        const blob = new Blob([val], { type: val.type });
        formData.append(key, blob, val.name);
      } else {
        formData.append(key, val)
      }
    }
  });

  return formData;
}
