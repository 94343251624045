import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const OrderItemEntrees = ({ list }) => {

  const { t } = useTranslation();

  const { paymentSettings } = useSelector(state => state.settings);

  const symbol = paymentSettings?.settings?.view_currency;

  return (
    list.length > 0 && (
      <div className="mb-0">
        <p className="mb-0 text-muted">{t("Entrees")}: </p>

        {list.map((item, idx) => {
          return (
            <p key={idx} className="fw-medium fs-12 ms-2 mb-1">
              + {item.name} {!!item.price ? `(${item.price}${symbol})` : ""}
            </p>
          );
        })}
      </div>
    )
  );
};

export default OrderItemEntrees;
