import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFoodDataAction } from '../../../../redux/actions/foodActions';
import CustomLabel from '../../../Custom/CustomLabel';

const SpiceLevels = ({foodType, initialValues, setValue}) => {

    const dispatch = useDispatch();

    const [selected,setSelected] = useState(null);

    const { spiceLevels: {list: spiceLevels}} = useSelector(state => state.food);

    useEffect(() => {
        dispatch(getFoodDataAction({
            field: 'spiceLevels'
        }))
    }, [])

  useEffect(() => {

    if (selected) {
      setValue('hot_level_id', selected);
    } else {
      setValue('hot_level_id', null);
    }

    return () => {
      setValue('hot_level_id', null);
    }
  }, [selected])

  useEffect(() => {
    if (initialValues?.hot_level_id) {
        setSelected(initialValues?.hot_level_id);
    }
  }, [foodType, initialValues])
    
  return (
    <React.Fragment>
      <div>
        <CustomLabel>Spice Level</CustomLabel>
        <div className='selectable-tabs-container'>
          {spiceLevels.map(spice => (
              <div
                key={spice.id} 
                className={`selectable-tab ${selected === spice.id ? 'selected-tab' : ''}`}
                onClick={() => {
                  if (selected === spice.id) return setSelected(null);
                  return setSelected(spice.id)
                }}
               >
                {spice.name}
              </div>
            ))}
        </div>
      </div>
    </React.Fragment>
  )
}

export default SpiceLevels