import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { getFoodDataAction } from "../../../redux/actions/foodActions";
import CustomLabel from "../../Custom/CustomLabel";
import CustomOption from "../../Custom/CustomOption";
import CouponProductItem from "./CouponProductItem";
import classNames from "classnames";
import { useQuery } from "@tanstack/react-query";
import { foodApi } from "../../../apis/Food/api";
import LoaderComponent from "../../Common/LoaderComponent";
import { useTranslation } from "react-i18next";

const CouponProductsTab = ({
  register,
  initialValues,
  setValue,
  watch,
}) => {
  
  const { categories } = useSelector((state) => state.food);

  const [products, setProducts] = useState(null);

  const [selectedProducts, setSelectedProducts] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState(null);

  const { t } = useTranslation();

  const dispatch = useDispatch();
  
  const all_products = watch('all_products');

  const { isRefetching, isFetching } = useQuery(["products", selectedCategory], () => foodApi.menu.getAll({
        category_id: selectedCategory,
        page_size: "all",
      }),
    {
      enabled: !!selectedCategory,
      cacheTime: 1,
      onSuccess({data: { data }}) {
        setProducts(data);
      }
    }
  );

  const onSelectProduct = (id) => {
    const exists = selectedProducts?.includes(id);
    if (exists) onDeleteSelectedProduct(id);
    else setSelectedProducts([...(selectedProducts ?? []), id]);
  };

  const onDeleteSelectedProduct = (id) => {
    setSelectedProducts(prev => prev.filter(item => item !== id));
  };

  useEffect(() => {
    dispatch(
      getFoodDataAction({
        field: "categories",
        params: {
          page_size: "all",
        },
      })
    );
  }, []);

  useEffect(() => {
    setValue('products', selectedProducts);
  }, [selectedProducts])

  useEffect(() => {
    if (initialValues?.products) {
      setSelectedProducts(initialValues.products);
    }
  }, [initialValues])

  return (
    <Row className="gy-4">

      <Col md={6}>
        <CustomLabel>Apply to</CustomLabel>
        <select
          className="form-select"
          {...register("all_products")}
        >
          <CustomOption value={1}>All products</CustomOption>
          <CustomOption value={0}>Selected products</CustomOption>
        </select>
      </Col>

      {+all_products !== 1 && 
        <>
          <Col xs={12}>
            <div className="selectable-tabs-container">
              {categories.list.map((category) => (
                <div
                  key={category.id}
                  className={classNames("selectable-tab py-1", {
                    "selected-tab": selectedCategory === category.id,
                  })}
                  onClick={() => setSelectedCategory(category.id)}
                >
                  {t(category.name)}
                </div>
              ))}
            </div>
          </Col>

          <Col xs={12}>
            <LoaderComponent isLoading={isRefetching || isFetching}>
              <div className="d-flex flex-wrap align-items-stretch gap-2">
                {products?.map(product => {
                  return (
                    <CouponProductItem
                      key={product.id}
                      img={product.file?.link}
                      title={product.name}
                      onClick={() => onSelectProduct(product.id)}
                      selected={selectedProducts?.includes(product.id)}
                    />
                  );
                })}
              </div>
            </LoaderComponent>
          </Col>
        </>
      }

    </Row>
  );
};

export default CouponProductsTab;
