import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import OrdersChart from "./Chart";
import { useTranslation } from "react-i18next";

const OrderStatistics = ({ statistics }) => {

  const [chartData, setChartData] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (statistics) {
      setChartData({
        [t('Delivery')]: {
          [t('Takeaway')]: statistics.delivery_type.takeaway,
          [t('Walk In')]: statistics.delivery_type.restaurant,
          [t('Delivery')]: statistics.delivery_type.delivery
        },
        [t('Status')]: {
          [t('New')]: statistics.status.New,
          [t('Accepted')]: statistics.status.Accepted,
          [t('Declined')]: statistics.status.Decline,
          [t('Ready')]: statistics.status.Ready,
        },
        [t('Payment')]: {
          [t('Paid')]: statistics.payment.Paid,
          [t('Not Paid')]: statistics.payment['Not paid'],
          [t('Returned')]: statistics.payment.Returned,
          [t('Cancelled')]: statistics.payment.Canceled,
        },
        [t('From')]: {
          [t('Web')]: statistics.platform.web,
          [t('App')]: statistics.platform.app,
          [t('Mweb')]: statistics.platform.mweb,
          [t('POS')]: statistics.platform.pos,
        },
      });
    }
  }, [statistics]);

  return (
    <Row className="mt-3 justify-content-center gy-2">
      {chartData ? (
        Object.keys(chartData).map((key, index) => (
          <Col key={index} lg={3} md={6} sm={6}>
            <div className="card h-100 mb-0">
              <div
                id="portfolio_donut_charts"
                className="apex-charts py-1"
                dir="ltr"
              >
                <OrdersChart data={chartData[key]} />
              </div>
            </div>
          </Col>
        ))
      ) : (
        <></>
      )}
    </Row>
  );
};

export default OrderStatistics;
