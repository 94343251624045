import React, { useState } from "react";
import { Card, Col, Row } from "reactstrap";
import {
  deleteSlidersApi,
} from "../../../apis/Settings/sliderApi";
import ListHeader from "../../../Components/Common/ListHeader";
import SortButton from "../../../Components/Common/Buttons/SortButton";
import PageContainer from "../../../Components/Layout/PageContainer";
import HomeSlidersTable from "../../../Components/Settings/HomeSliders/Table";
import toastAlert from "../../../helpers/toastAlert";
import useActions from "../../../hooks/useActions";
import useFilterData, { FILTER_TYPES } from "../../../hooks/useFilterData";
import useModal from "../../../hooks/useModal";
import { useSlider } from "../../../hooks/useQueryHelpers";
import useSelectItems from "../../../hooks/useSelectItems";

const HomeSliders = () => {
  
  const { selectItems, getSelectedIds, isDisable } = useSelectItems();

  const {
    isLoading: isDeleting,
    onLoading: onDeleting,
    onFinally: onDeletingFinally,
  } = useActions();

  const [query, setQuery] = useState("");

  const { isModalOpen, setIsModalOpen, closeModal, openModal } = useModal();

  const { isLoading, sliders, deleteSliders } = useSlider();

  const handleDelete = async () => {
    onDeleting();
    const ids = getSelectedIds();
    try {
      await deleteSlidersApi(ids);
      deleteSliders(ids);
      closeModal();
      selectItems([]);
      toastAlert({ type: 'success' });
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      onDeletingFinally();
    }
  };

  const list = useFilterData(sliders, {
    title: {
      key: "title",
      type: FILTER_TYPES.MATCH,
      value: query,
    },
  });

  return (
    <PageContainer isLoading={isLoading}>
      <Row className="gy-4">
        <Col xs={12}>
          <ListHeader
            isDisable={isDisable}
            openModal={openModal}
            pathname="settings/home-sliders/create"
            query={query}
            setQuery={setQuery}
            afterChildren={<SortButton to="settings/home-sliders/sort" />}
          />
        </Col>
        <Col xs={12}>
          <Card>
            <HomeSlidersTable
              data={list}
              selectItems={selectItems}
              modal={isModalOpen}
              setModal={setIsModalOpen}
              isDeleting={isDeleting}
              handleDelete={handleDelete}
            />
          </Card>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default HomeSliders;
