import { Draggable, Droppable } from 'react-beautiful-dnd';
import React from 'react'
import CustomLabel from '../../Custom/CustomLabel';
import DraggableSize from './DraggableSize';

const DraggableBread = ({ bread, category, pageId, index, handleSizeChange }) => {

  return (
    <Draggable draggableId={`page-${pageId}-category-${category.id}-bread-${bread.id}`} index={index} type={`page-${pageId}-category-${category.id}-bread`}>
        {provided => (
            <div    
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                <div key={index} className='d-flex gap-2 flex-column border p-3 px-3'>
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex gap-2'>
                            <span>
                                <i className="ri-menu-line"></i>
                            </span>

                            <CustomLabel 
                                className="d-flex gap-2"
                            >
                                {bread.icon && 
                                    <img 
                                        src={bread.icon} 
                                        alt={bread.icon} 
                                        style={{
                                            height: '20px',
                                        }} 
                                    />
                                }
                                {bread.name}
                            </CustomLabel>
                        </div>
                    </div>

                    <Droppable droppableId={`page-${pageId}-category-${category.id}-bread-${bread.id}-sizes`} type={`page-${pageId}-category-${category.id}-bread-${bread.id}-size`}>
                        {provided => (
                            <div className='d-flex flex-column gap-3 dropzone p-3 mt-3' ref={provided.innerRef} {...provided.droppableProps}>
                                {bread.sizes.map((size, index) => (
                                    <DraggableSize 
                                        key={size.id}
                                        index={index}
                                        handleSizeChange={handleSizeChange}
                                        bread={bread}
                                        size={size}
                                        category={category}
                                        pageId={pageId}
                                    />
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>

                </div>
            </div>
        )}
    </Draggable>
  )
}

export default DraggableBread