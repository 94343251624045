import React from 'react'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import PageContainer from '../../../Components/Layout/PageContainer'
import ShelfForm from '../../../Components/Orders/Shelves/Form'

const CreateShelf = () => {

  return (
    <PageContainer>
        <BreadCrumb pageTitle="Shelves" title="Create" to="orders/shelves"/>
        <ShelfForm 
          type="create"
        />
    </PageContainer>
  )
}

export default CreateShelf