import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { foodApi } from '../../../apis/Food/api'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import OptionsForm from '../../../Components/Food/Options/Form'
import PageContainer from '../../../Components/Layout/PageContainer'
import { useFetchByLang } from '../../../hooks/useFetchByLang'

const UpdateOption = () => {

  const {id} = useParams();

  const [initialValues, setInitialValues] = useState(null);

  const { isLoading, isRefetching, refetch } = useQuery(['one-option'], () => foodApi.options.getOne(id, language), {
    enabled: false,
    cacheTime: 0,
    onSuccess({data: {data}}) {
      setInitialValues(data);
    }
  })

  const { language, setLanguage } = useFetchByLang(refetch);

  return (
    <PageContainer isLoading={isLoading || isRefetching}>
      <BreadCrumb pageTitle="Options" title="Update" to="food/options"/>
      {initialValues && 
        <OptionsForm 
          type="update"
          initialValues={initialValues}
          language={language}
          setLanguage={setLanguage}
        />
      }
    </PageContainer>
  )
}

export default UpdateOption