import React from 'react'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import SpiceLevelForm from '../../../Components/Food/SpiceLevels/Form'
import PageContainer from '../../../Components/Layout/PageContainer'
import { useFetchByLang } from '../../../hooks/useFetchByLang'

const CreateSpiceLevel = () => {

  const { language, setLanguage } = useFetchByLang();
    
  return (
    <PageContainer>
      <BreadCrumb pageTitle="Spice Levels" title="Create" to='food/spice-levels'/>
      <SpiceLevelForm 
        type="create"
        language={language}
        setLanguage={setLanguage}
      />
    </PageContainer>
  )
}

export default CreateSpiceLevel