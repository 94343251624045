import React from 'react'
import { useSelector } from 'react-redux'
import { isAllowed } from '../../../helpers/isAllowed'
import { getPathname } from '../../../Layouts/LayoutMenuData'
import DataTableContainer from '../../Common/DataTableContainer'
import StatusToggle from '../../Common/StatusToggle'
import TableImage from '../../Common/TableImage'
import CustomInput from '../../Custom/CustomInput'
import CustomTableHeading from '../../Custom/CustomTableHeading'

const CategoriesTable = ({navigateToPage, pageSize, setPageSize, setSelectedItems, modal, setModal, isLoading, handleDelete, loading, name ,setName}) => {

    const { categories } = useSelector(state => state.food);

    const { user } = useSelector(state => state.admin);

    const columns = [
        
        {
            name: <CustomTableHeading>ID</CustomTableHeading>,
            selector: row => row.id || '-',
            grow: -1,
        },

        {
            selector: row => <TableImage src={row.icon} />,
        },

        {
            name: <CustomInput
                placeholder='Name' 
                type="text" 
                value={name || ''}
                onChange={(e) => setName(e.target.value)}
                data-filtered={!!name}
            />,
            selector: row => row.name || '-',
        },

        {
            name: <CustomTableHeading>Entry</CustomTableHeading>,
            selector: row => <StatusToggle type='categories' id={row.id} field="is_entrees" status={row.is_entrees} />,
        },

        {
            name: <CustomTableHeading>Show in menu</CustomTableHeading>,
            selector: row => <StatusToggle type='categories' id={row.id} field="show_menu" status={row.show_menu} />,
        },

        {
            name: <CustomTableHeading>Active</CustomTableHeading>,
            selector: row => <StatusToggle type='categories' id={row.id} field="active" status={row.active} />,
        },
    ]

  return (
    <DataTableContainer 
        data={categories.list}
        paginationTotalRows={categories.total && categories.total}
        columns={columns}
        paginationServer
        navigateToPage={navigateToPage}
        path={getPathname('food/categories')}
        pageSize={pageSize}
        setPageSize={setPageSize}
        selectableRows={isAllowed(user)}
        selectItems={setSelectedItems}
        modal={modal}
        setModal={setModal}
        isDeleting={isLoading}
        handleDelete={handleDelete}
        loading={loading}
    />
  )
}

export default CategoriesTable