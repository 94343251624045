import React from "react";
import CommonForm from "./CommonForm";

const DeliveryTab = ({ values, handleChange }) => {
  return (
    <>
      <CommonForm
        handleChange={handleChange}
        values={values}
        name="delivery"
        checkboxName="delivery_send_sms"
        inputName="delivery_sms_text"
        keywordsName="delivery_sms_keywords"
      />
    </>
  );
};

export default DeliveryTab;
