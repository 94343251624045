import React from "react";
import { useSelector } from "react-redux";


const OrderItemFoodComponent = ({ icon, type, color, list, imgStyle = {} }) => {

  const { paymentSettings } = useSelector(state => state.settings);

  const symbol = paymentSettings?.settings?.view_currency;

  const renderItem = (item) => {
    if (type === "black") {
      return `Extra ${item.name}${
        item.extra_price ? ` (${item.extra_price}${symbol})` : ""
      }`;
    } else if (type === "green") {
      return (
        <p className="mb-0">
          {!!item.extra
            ? `Extra ${item.name} (${
                item.price + (item.extra_price || 0)
              }${symbol})`
            : `${item.name} (${item.price}${symbol})`}
        </p>
      );
    } else if (type === "half") {
      // console.log(item);
      return `${item.name} ${!!item.extra ? `(${item.extra}${symbol})` : ""}`;
    } else {
      return item.name;
    }
  };
  return list.length > 0 ? (
    <div className="order-item-food-component">
      <img src={icon} alt="" style={imgStyle} />
      <div
        className="d-flex flex-wrap align-items-center"
        style={{
          gap: 2,
        }}
      >
        {list.map((item, idx) => {
          return (
            <p
              key={idx}
              className={`mb-0 fs-13 ${color ? `text-${color}` : ""}`}
              style={{
                fontWeight: 500,
              }}
            >
              {renderItem(item)}
              {idx !== list.length - 1 && (
                <span className="text-black">, </span>
              )}
            </p>
          );
        })}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default OrderItemFoodComponent;
