import React, { useEffect, useRef, useState } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd';
import CategoryItemsContainer from './CategoryItemsContainer';
import CustomLabel from '../../Custom/CustomLabel';
import CustomInput from '../../Custom/CustomInput';
import CustomTextArea from '../../Custom/CustomTextArea';
import CustomOption from '../../Custom/CustomOption';
import DraggableBread from './DraggableBread';
import SelectFile from '../../Common/SelectFile';
import { updateMenuImage } from '../../../apis/Settings/menuApi';
import toastAlert from '../../../helpers/toastAlert';

const layoutTypes = {
    flow: 'Flow',
    column: 'Column'
}

const DraggableCategory = ({ pageId, category, index, setData }) => {

    const [expanded, setExpanded] = useState(false);

    const [uploadingImage, setUploadingImage] = useState(false);

    const contentRef = useRef(null);

    useEffect(() => {
      if (expanded) {
        contentRef.current.style.height = `${contentRef.current.scrollHeight}px`;
      } else {
        contentRef.current.style.height = "0";
      }
    }, [expanded]);

    const uploadImage = (image) => {
        const formData = new FormData();
        const file = image.file;
        const blob = new Blob([file], { type: file.type });
        formData.append("image", blob, file.name);
        formData.append("is_image_transparent", 0);
        return updateMenuImage(category.id, formData);
    }

    
    const handleCategoryChange = async (field, value) => {
        try {
            let imageData = null;
            if (field === 'image' && value.file) {
                setUploadingImage(true);
                const { data: { data } } = await uploadImage(value);
                imageData = {
                    id: data.id,
                    url: data.link,
                };
            }
            setData(prev => ({
                ...prev,
                menu: prev.menu.map(page => {
                    if (page.id === pageId) return {
                        ...page,
                        items: page.items.map(cat => {
                            if (cat.id === category.id) return {
                                ...cat,
                                [field]: field === 'image' ? (imageData ?? value) : value
                            }
                            return cat
                        })
                    }
                    return page
                })
            }))
        } catch (error) {
            toastAlert({ type: 'error' })
            console.log('error', error);
        } finally {
            setUploadingImage(false);
        }
    }

    const handleItemChange = (itemId, field, value) => {
        setData(prev => ({
            ...prev,
            menu: prev.menu.map(page => {
                if (page.id === pageId) return {
                    ...page,
                    items: page.items.map(cat => {
                        if (cat.id === category.id) return {
                            ...cat,
                            children: cat.children.map(item => {
                                if (item.id === itemId) return {
                                    ...item,
                                    [field]: value
                                }
                                return item
                            })
                        }
                        return cat
                    })
                }
                return page
            })
        }))
    }

    const handleSizeChange = (breadId, sizeId, active) => {
        setData(prev => ({
            ...prev,
            menu: prev.menu.map(page => {
                if (page.id === pageId) return {
                    ...page,
                    items: page.items.map(cat => {
                        if (cat.id === category.id) return {
                            ...cat,
                            breads: cat.breads.map(bread => {
                                if (bread.id === breadId) return {
                                    ...bread,
                                    sizes: bread.sizes.map(size => {
                                        if (size.id === sizeId) return {
                                            ...size,
                                            active
                                        }
                                        return size
                                    })
                                }
                                return bread
                            })
                        }
                        return cat
                    })
                }
                return page
            })
        }))
    }

  return (
    <Draggable draggableId={`category-${category.id}`} index={index} type="category">
        {provided => (
                <div    
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <div className='draggable-category-card'>

                        <div className='category-header-container p-3'>
                            <span>
                                <i className="ri-menu-line"></i>
                            </span>

                            {category.name}

                            <div onClick={() => setExpanded(!expanded)} className='expand-arrow cursor-pointer'>
                               <i className="ri-arrow-down-s-line"></i>
                            </div>
                        </div>

                        <div ref={contentRef} className='category-content-wrapper'>

                            <div className='p-3'>
                                
                                <div className='my-3 d-flex flex-column gap-3'>

                                    <div>
                                        <CustomLabel>
                                            Subtitle
                                        </CustomLabel>
                                        <CustomInput 
                                            value={category.subtitle ?? ''}
                                            onChange={(e) => handleCategoryChange('subtitle', e.target.value)}
                                        />
                                    </div>

                                    <div>
                                        <CustomLabel>
                                            Description
                                        </CustomLabel>
                                        <CustomTextArea 
                                            value={category.description ?? ''}
                                            onChange={(e) => handleCategoryChange('description', e.target.value)}
                                        />
                                    </div>

                                    <div>
                                        <CustomLabel>
                                            Layout type
                                        </CustomLabel>
                                        <select 
                                            className='form-select'
                                            value={category.layout_type}
                                            onChange={(e) => handleCategoryChange('layout_type', e.target.value)}
                                        >
                                            {Object.keys(layoutTypes).map((key, index) => (
                                                <CustomOption key={index} value={key}>{layoutTypes[key]}</CustomOption>
                                            ))}
                                        </select>
                                    </div>

                                    <div>
                                        <CustomLabel>Menu Image</CustomLabel>
                                        <SelectFile
                                            selectedFiles={category.image}
                                            setSelectedFiles={(newVal) => handleCategoryChange('image', newVal)}
                                            type='image'
                                            loading={uploadingImage}
                                        />
                                    </div>

                                    <div className='d-flex gap-2 align-items-center my-2'>
                                        <div className="form-check form-switch">
                                            <input 
                                                checked={category.show_allergy}
                                                onChange={(e) => handleCategoryChange('show_allergy', e.target.checked)}
                                                className="form-check-input fs-16" 
                                                type="checkbox" 
                                                role="switch" 
                                            />
                                            <CustomLabel className="form-check-label" htmlFor="is_menu">Show Allergies</CustomLabel>
                                        </div>

                                        
                                        <div className="form-check form-switch">
                                            <input 
                                                checked={category.show_comp}
                                                onChange={(e) => handleCategoryChange('show_comp', e.target.checked)}
                                                className="form-check-input fs-16" 
                                                type="checkbox" 
                                                role="switch" 
                                            />
                                            <CustomLabel className="form-check-label" htmlFor="is_menu">Show Components</CustomLabel>
                                        </div>
                                    </div>
                                </div>

                                <Droppable droppableId={`page-${pageId}-category-${category.id}-breads`} type={`page-${pageId}-category-${category.id}-bread`}>
                                    {provided => (
                                        <div className='d-flex flex-column gap-3 dropzone p-3 mt-3' ref={provided.innerRef} {...provided.droppableProps}>
                                            {category.breads.map((bread, index) => (
                                                <DraggableBread 
                                                    key={bread.id}
                                                    index={index}
                                                    handleSizeChange={handleSizeChange}
                                                    category={category}
                                                    bread={bread}
                                                    pageId={pageId}
                                                />
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                     
                            <CategoryItemsContainer
                                    category={category}
                                    expanded={expanded}
                                    handleItemChange={handleItemChange}
                                />

                            </div>

                        </div>
                    </div>
                </div>
            )
        }
    </Draggable>
  )
}

export default DraggableCategory