import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Card, CardBody, Col, Row } from 'reactstrap'
import CustomButton from '../../Custom/CustomButton'
import CustomLabel from '../../Custom/CustomLabel'
import Flatpickr from 'react-flatpickr';
import moment from 'moment'
import CustomInput from '../../Custom/CustomInput'
import useActions from '../../../hooks/useActions'
import { formatExample } from '../../../helpers/formatExample'
import { reportsApi } from '../../../apis/Reports/api'
import toastAlert from '../../../helpers/toastAlert'
import ReportSettingsEmails from './Emails'

const ReportsSettingsForm = ({ initialValues }) => {

    const {register, handleSubmit, control, reset, setValue} = useForm();

    const {isLoading, onLoading, onFinally, onError} = useActions();

    const submitForm = async (data) => {
        onLoading();
        try {
            await reportsApi.settings.update(data);
            toastAlert({ type: 'success' });
        } catch (error) {
            onError();
            console.log('error', error);
            toastAlert({ type: 'error' });
        } finally {
            onFinally();
        }
    }

    useEffect(() => {
        if (initialValues) {
            reset(initialValues)
        }
    }, [initialValues])

  return (
    <form onSubmit={handleSubmit(submitForm)}>
        <Card>
            <CardBody>

                <Row className='gy-3'>

                    <Col md={6}>
                        <CustomLabel htmlFor="company_name">Company Name</CustomLabel>
                        <CustomInput 
                          type="text"
                          id="company_name"
                          {...register('company_name')}
                          placeholder={formatExample('Google')}
                        />
                    </Col>

                    <Col md={6}>
                        <CustomLabel htmlFor="org_nr">Org Nr.</CustomLabel>
                        <CustomInput 
                          type="text"
                          id="org_nr"
                          {...register('org_nr')}
                          placeholder={formatExample('34452')}
                        />
                    </Col>

                    <Col md={6}>
                        <CustomLabel htmlFor="address">Address</CustomLabel>
                        <CustomInput 
                          type="text"
                          id="address"
                          {...register('address')}
                          placeholder={formatExample(`186 Plumb Branch St. El Dorado, AR 71730`)}
                        />
                    </Col>

                    <Col md={6}>
                        <CustomLabel htmlFor="z_reporter">Z Rapporter</CustomLabel>
                        <Controller 
                            control={control}
                            name="z_reporter"
                            render={({ name, field: { onChange } }) =>       
                            <Flatpickr
                                name={name}
                                className="form-control"
                                id="z_reporter"
                                value={initialValues?.z_reporter ? moment(initialValues?.z_reporter).format("D MMM, YYYY") : ''}
                                options={{
                                dateFormat: "d M, Y",
                                }}
                                placeholder={formatExample('02 Mar, 2023')}
                                onChange={(dates) => onChange(moment(dates[0]).format("YYYY-MM-DD"))}
                            />
                            }
                        />
                    </Col>

                    <Col>
                        <ReportSettingsEmails 
                            initialValues={initialValues}
                            setValue={setValue}
                        />
                    </Col>

                </Row>

                <Row className='mt-3'>
                    <Col>
                        <CustomButton
                            type="submit"
                            className="btn-success"
                            isLoading={isLoading}
                        >
                            Save
                        </CustomButton>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    </form>
  )
}

export default ReportsSettingsForm