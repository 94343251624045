import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//import Components
import Header from './Header';
import Footer from './Footer';
//import actions
import {
    changeLayout,
    changeLayoutMode,
} from "../redux/slices/layoutSlice";
import { layoutModeTypes } from '../Components/Constants/layout';
//redux
import { useSelector, useDispatch } from "react-redux";
import { useLocalStorage } from '../hooks/useLocalStorage';


const Layout = (props) => {

    const {values: {layoutModeTypes: localLayoutModeValues}} = useLocalStorage({
        layoutModeTypes: {
            theme: 'LIGHTMODE'
        }
    })

    const dispatch = useDispatch();
    const {
        layoutType,
        layoutModeType,
    } = useSelector(state => ({
        layoutType: state.layout.layoutType,
        layoutModeType: state.layout.layoutModeType,
    }));

    /*
    layout settings
    */
    useEffect(() => {
        if (layoutType) {
            dispatch(changeLayout(layoutType));
        }
    }, [layoutType]);

    useEffect(() => {
        dispatch(changeLayoutMode(layoutModeTypes[localLayoutModeValues.theme]));
    }, [localLayoutModeValues])
    /*
    call dark/light mode
    */
    const onChangeLayoutMode = (value) => {
        if (changeLayoutMode) {
            dispatch(changeLayoutMode(value));
        }
    };

    const [headerClass, setHeaderClass] = useState("");
    // class add remove in header
    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true);
    });
    function scrollNavigation() {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > 50) {
            setHeaderClass("topbar-shadow");
        } else {
            setHeaderClass("");
        }
    }

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header
                    headerClass={headerClass}
                    layoutModeType={layoutModeType}
                    onChangeLayoutMode={onChangeLayoutMode} 
                />
                <div className="main-content">
                    <div className='page-content'>
                        {props.children}
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>

    );
};

Layout.propTypes = {
    children: PropTypes.object,
};

export default withRouter(Layout);
