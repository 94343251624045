import React from 'react'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import PageContainer from '../../../Components/Layout/PageContainer'
import BlackAndWhiteForm from '../../../Components/Orders/BlackAndWhite/Form'

const CreateBlackAndWhite = () => {

  return (
    <PageContainer>
        <BreadCrumb pageTitle="Black & White" title="Create" to="orders/black-white"/>
        <BlackAndWhiteForm 
          type="create"
        />
    </PageContainer>
  )
}

export default CreateBlackAndWhite