import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const titleCase = str => str.split(/\s+/).map(w => w[0].toUpperCase() + w.slice(1)).join(' ');

const ToggleSwitch = ({ values, selected, onChange, rowId, selectedRow }) => {

    const [selectedVal, setSelectedVal] = useState(null);

    const { t } = useTranslation();

    const handleChange = val => {
      setSelectedVal(val);
      onChange(rowId, val)
    };

    useEffect(() => {
      setSelectedVal(selected);
    }, [selected])

    return (
      <div className={`switch-container ${selectedRow ? '' : 'disabled'}`}>
        {values.map(item => {
          return (
            <span key={item.value} className={selected === item.value ? 'selected-switch-tab' : ''}>
              <p 
                onClick={() => handleChange(item.value)} 
                className={`switch-label ${selectedVal === item.value ? 'selected' : ''}`}
              >
                {t(titleCase(item.label))}
              </p>
            </span>
          );
        })}
      </div>
    );
}

export default ToggleSwitch
