import React from 'react'
import { Col, Row } from 'reactstrap'
import CustomInput from '../../../../../Custom/CustomInput'
import CustomLabel from '../../../../../Custom/CustomLabel'
import ImageInput from '../../../../../Common/ImageInput'

const PricesList = ({data, setData, canAddImage}) => {

  const handleChange = ({itemId, priceId, field, value}) => {
    setData(prev => prev.map(item => {
        if (item.id === itemId) {
            if (priceId) return {
              ...item,
              prices: item.prices.map(price => {
                if (price.id === priceId) return {
                  ...price,
                  [field]: value,
                }
                return price
              })
            }

            return {
              ...item,
              [field]: value,
            }
        }
        return item
    }))
  }

  const evaluate = (string) => {
    try {
      const sum = eval(string);
      return sum
    } catch (err) {
      return null
    }
  }

  const handlePressEnter = ({key, value, item}) => {
    if (key === 'Enter') {
      const sum = evaluate(value);
      if (sum) handleChange({ ...item, value: sum });
    }
  }


  return (
    <Row className='gy-3'>
        {data.map(item => (
          <Col key={item.id} lg={12}>
              <div className="p-3 px-3 border">
                <div className='d-flex justify-content-between'>
                 <div className='form-check'>
                    <input
                        className="form-check-input"
                        style={{marginLeft: 0}}
                        type="checkbox"
                        checked={item.selected ?? false}
                        id={`$item-${item.id}-selected`}
                        onChange={e => handleChange({itemId: item.id, field: 'selected', value: e.target.checked})}
                    />

                    <div className='d-flex gap-2'>
                      <CustomLabel 
                        htmlFor={`$item-${item.id}-selected`}
                        className="d-flex gap-2"
                      >
                        {item.icon && 
                          <img 
                            src={item.icon} 
                            alt={item.icon} 
                            style={{
                                height: '20px',
                            }} 
                          />
                        }
                        {item.name}
                      </CustomLabel>
                    </div>
                 </div>

                  <div className="form-check form-switch">
                      <input 
                        className="form-check-input fs-16" 
                        id={`item-${item.id}-display`}
                        type="checkbox" 
                        role="switch" 
                        checked={item.selected && item.display}
                        disabled={!item.selected}
                        onChange={(e) => {
                          if (item.selected) handleChange({itemId: item.id, field: 'display', value: e.target.checked})
                        }}
                      />
                      <CustomLabel 
                        className="form-check-label" 
                        htmlFor={`item-${item.id}-display`}
                      >
                        On
                      </CustomLabel>
                  </div>

                </div>

                {item.selected ? 
                  <div className='mt-4'>
                      {item.prices.map(price => (

                        <div key={price.id} className="d-flex gap-3 border my-2 p-3 ">

                          <input
                              className="form-check-input"
                              style={{ marginLeft: 0 }}
                              type="checkbox"
                              checked={price.selected || ''}
                              id={`price-${item.id}-${price.id}`}
                              onChange={e => handleChange({itemId: item.id, priceId: price.id, field: 'selected', value: e.target.checked})}
                          />

                          {
                            (canAddImage && price.price_id) && <ImageInput bread_id={item.id} item={price} setData={setData} />
                          }

                          <div className='d-flex flex-column gap-3 border p-2'>

                              <div>
                                <CustomLabel className="form-check-label" htmlFor={`price-${item.id}-${price.id}`}>
                                  {price.name}
                                </CustomLabel>
                              </div>

                              <div className='d-flex gap-2' style={{ marginTop: -10 }}>

                                <CustomInput 
                                  placeholder="Price"
                                  value={price.price ?? ''}
                                  onChange={e => handleChange({itemId: item.id, priceId: price.id, field: 'price', value: e.target.value})}
                                  onKeyUp={(e) => handlePressEnter({ key: e.key, value: e.target.value, item: { itemId: item.id, priceId: price.id, field: 'price' } })}
                                  disabled={!price.selected}
                                />

                                <CustomInput 
                                  placeholder="Price In"
                                  value={price.price_in ?? ''}
                                  onChange={e => handleChange({itemId: item.id, priceId: price.id, field: 'price_in', value: e.target.value})}
                                  onKeyUp={(e) => handlePressEnter({ key: e.key, value: e.target.value, item: { itemId: item.id, priceId: price.id, field: 'price_in' } })}
                                  disabled={!price.selected}
                                />

                              </div>

                              <div className="form-check form-switch">

                                  <input 
                                    className="form-check-input fs-16" 
                                    id={`price-${item.id}-${price.id}-display`}
                                    type="checkbox" 
                                    role="switch" 
                                    checked={item.selected && price.selected && price.display}
                                    disabled={!item.selected || !price.selected}
                                    onChange={(e) => {
                                      if (item.selected && price.selected) handleChange({itemId: item.id, priceId: price.id, field: 'display', value: e.target.checked})
                                    }}
                                  />

                                  <CustomLabel 
                                    className="form-check-label" 
                                    htmlFor={`price-${item.id}-${price.id}-display`}
                                  >
                                    On
                                  </CustomLabel>

                              </div>

                          </div>

                        </div>
                    ))}
                  </div>
                : <></>}

              </div>
          </Col>
        ))}
    </Row>
  )
}

export default PricesList