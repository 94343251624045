import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query';
import { getDashboardSetup } from '../../apis/Settings/setupApi';
import { useHistory, useLocation } from 'react-router-dom';

const SetupWrapper = ({ children }) => {

    const [setupProgress, setSetupProgress] = useState(null);

    const location = useLocation();

    const { isLoading } = useQuery(['dashboard-setup'], getDashboardSetup, {
      onSuccess({data: { data }}) {
        setSetupProgress(data)
      }
    })

    const history = useHistory();

    const exceptions = ['/setup', '/login']

    useEffect(() => {
      if (setupProgress !== null && !setupProgress?.completed &&  !exceptions.includes(location.pathname)) {
        history.push({ pathname: '/setup', state: setupProgress })
      }
    }, [setupProgress, location])

    if (!isLoading && setupProgress !== null) {
      return children
    }

}

export default SetupWrapper