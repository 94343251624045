import React from "react";
import { useTranslation } from "react-i18next";
import { CardHeader, Col, Modal, Row } from "reactstrap";
import { deleteSpecialTimeApi } from "../../../apis/Orders/onlineSettingsApis";
import toastAlert from "../../../helpers/toastAlert";
import useActions from "../../../hooks/useActions";
import useCreateUpdateModal from "../../../hooks/useCreateUpdateModal";
import useDeleteModal from "../../../hooks/useDeleteModal";
import useModal from "../../../hooks/useModal";
import useSelectItems from "../../../hooks/useSelectItems";
import DataTableContainer from "../../Common/DataTableContainer";
import CustomButton from "../../Custom/CustomButton";
import CustomTableHeading from "../../Custom/CustomTableHeading";
import PageTitle from "../../Page/PageTitle";
import OrderDateModal from "./OrderDateModal";

const OrderDateList = ({ specialDays, setData, setSpecialDays }) => {
  
  const { t } = useTranslation();

  const {
    isModalOpen,
    toggleModal,
    onAddClick,
    onUpdateClick,
    initValues,
    type,
  } = useCreateUpdateModal();

  const {
    isLoading: isDeleting,
    onLoading: onDeleting,
    onFinally: onFinallyDeleting,
  } = useActions();

  const { isDisable, getSelectedIds, selectItems } = useSelectItems();

  const {
    isModalOpen: isDeleteModalOpen,
    setIsModalOpen,
    openModal,
    closeModal,
  } = useModal();

  const onDeleteSubmit = async () => {
    onDeleting();
    const ids = getSelectedIds();
    try {
      await deleteSpecialTimeApi(ids);
      setSpecialDays((current) => {
        return current.filter((item) => !ids.includes(item.id));
      });
      selectItems([]);
      closeModal();
      toastAlert({ type: 'success' });
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      onFinallyDeleting();
    }
  };

  const columns = [
    {
      name: <CustomTableHeading>From</CustomTableHeading>,
      selector: (row) => row.date_from,
    },
    {
      name: <CustomTableHeading>To</CustomTableHeading>,
      selector: (row) => row.date_to,
    },
    {
      name: <CustomTableHeading>Start</CustomTableHeading>,
      selector: (row) => row.time_start,
    },
    {
      name: <CustomTableHeading>End</CustomTableHeading>,
      selector: (row) => row.time_end,
    },
    {
      name: <CustomTableHeading>Open</CustomTableHeading>,
      selector: (row) => t(row.open ? "Yes" : "No"),
    },
  ];
  return (
    <>
      <CardHeader>
        <div className="d-flex align-items-center justify-content-between">
          {t("Dates list")}
          <div className="d-flex align-items-center gap-2">
            <CustomButton onClick={onAddClick} size="sm">
              Create
            </CustomButton>
            <CustomButton
              color="danger"
              size="sm"
              disabled={isDisable}
              onClick={openModal}
            >
              Delete
            </CustomButton>
          </div>
        </div>
      </CardHeader>

      <DataTableContainer
        columns={columns}
        selectableRows
        modal={isDeleteModalOpen}
        handleDelete={onDeleteSubmit}
        isDeleting={isDeleting}
        setModal={setIsModalOpen}
        selectItems={selectItems}
        handleClick={(row) => {
          onUpdateClick(row);
        }}
        data={specialDays}
      />
      <Modal isOpen={isModalOpen} toggle={toggleModal} size="xl" centered>
        <OrderDateModal
          toggle={toggleModal}
          initValues={initValues}
          type={type}
          setData={setData}
        />
      </Modal>
    </>
  );
};

export default OrderDateList;
