import { useState } from "react";

export default function useCustomForm(initValues) {

  const [values, setValues] = useState(initValues);

  const handleChange = (e) => {

    const isBoolean = e.target.type === "checkbox";
    
    setValues((current) => {
      return {
        ...current,
        [e.target.name]: isBoolean ? e.target.checked : e.target.value,
      };
    });
  };

  const handleControlChange = (name, value) => {
    setValues((current) => {
      return {
        ...current,
        [name]: value,
      };
    });
  };

  return {
    values,
    handleChange,
    setValues,
    handleControlChange,
  };
}
