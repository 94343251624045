import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  createAdminApi,
  deleteAdminsApi,
  getAllAdminsApi,
  getAllRolesApi,
  updateAdminApi,
} from "../apis/Admin/adminApis";
import { getAllCouponsApi } from "../apis/Orders/couponApis";
import { getAllOrdersApi, getOrderStatusApi } from "../apis/Orders/ordersApi";
import { getAllTables } from "../apis/Orders/tablesApi";
import { getAllGaleriesApi } from "../apis/Settings/galleryApi";
import { getAllPagesMenuApi } from "../apis/Settings/pageMenuApi";
import { getAllSlidersApi } from "../apis/Settings/sliderApi";
import { getAllGroupsApi } from "../apis/users/groupsApis";
import { getAllUsersApi } from "../apis/users/usersApi";
import extractError from "../helpers/extractError";
import { renderErrorAsObject } from "../helpers/renderErrors";
import toastAlert from "../helpers/toastAlert";
import { getAllBranchesApi } from "../apis/Settings/branchesApi";
import { getAllRooms } from "../apis/Orders/roomsApi";
import { getAllTableServices } from "../apis/Orders/tableServicesApi";
import { getAllShelves } from "../apis/Orders/shelvesApi";
import { getAllBlackAndWhite } from "../apis/Orders/blackAndWhiteApi";

export const useGetGroups = (restOptions = {}) => {
  const [groups, setGroups] = useState([]);
  const query = useQuery(["groups"], getAllGroupsApi, {
    onSuccess(data) {
      setGroups(data.data.data);
    },
    ...restOptions,
  });

  const deleteGroups = (ids) => {
    setGroups((currentGroups) => {
      return currentGroups.filter((group) => !ids.includes(group.id));
    });
  };

  return {
    ...query,
    groups,
    deleteGroups,
  };
};

export const useGetUsers = ({ currentPage, pageSize, name }, restOptions = {}) => {
  const [users, setUsers] = useState({
    total: 0,
    list: [],
  });

  const query = useQuery(
    ["users"],
    () => getAllUsersApi({ page: currentPage, pageSize, name }),
    {
      onSuccess(data) {
        setUsers({
          list: data.data.data,
          total: data.data.meta?.total || 0,
        });
      },
      ...restOptions,
    }
  );

  const deleteUsers = (ids = []) => {
    setUsers((currentUsers) => {
      return {
        total: currentUsers.total - ids.length,
        list: currentUsers.list.filter((user) => !ids.includes(user.id)),
      };
    });
  };

  return {
    ...query,
    users,
    deleteUsers,
  };
};

export const useAdmins = (restOptions = {}) => {
  const [admins, setAdmins] = useState([]);

  const query = useQuery(["admins"], () => getAllAdminsApi(), {
    onSuccess(data) {
      setAdmins(data.data.data);
    },
    ...restOptions,
  });

  const createNewAdmin = async (body, actions) => {
    try {
      const { data } = await createAdminApi(body);
      setAdmins((current) => {
        return [...current, data.data];
      });
      toastAlert({ type: 'success' });
      actions.onSuccess();
    } catch (error) {
      actions.onError(renderErrorAsObject(extractError(error)));
    }
  };

  const updateAdmin = async (id, body, actions) => {
    try {
      const { data } = await updateAdminApi(id, body);
      const updatedAdmin = data.data;
      setAdmins((current) => {
        return current.map((admin) => {
          if (updatedAdmin.id === admin.id) {
            return updatedAdmin;
          }
          return admin;
        });
      });
      toastAlert({ type: 'success' });
      actions.onSuccess();
    } catch (error) {
      actions.onError(renderErrorAsObject(extractError(error)));
    }
  };

  const deleteAdmins = async (ids, actions) => {
    try {
      await deleteAdminsApi(ids);
      setAdmins((current) => {
        return current.filter((admin) => !ids.includes(admin.id));
      });
      actions.onSuccess();
    } catch (error) {
      actions.onFinally();
      toastAlert({ type: 'error' });
    }
  };

  return {
    ...query,
    admins,
    createNewAdmin,
    deleteAdmins,
    updateAdmin,
  };
};

export const useRoles = (restOptions = {}) => {
  const [roles, setRoles] = useState([]);

  const query = useQuery(["roles"], () => getAllRolesApi(), {
    onSuccess(data) {
      setRoles(data.data.data);
    },
    ...restOptions,
  });

  return {
    ...query,
    roles,
  };
};

export const useCoupons = () => {
  const [coupons, setCoupons] = useState([]);

  const query = useQuery(["coupons"], getAllCouponsApi, {
    onSuccess(data) {
      setCoupons(data.data.data);
    },
  });

  const deleteCoupon = (ids) => {
    setCoupons((current) => {
      return current.filter((item) => !ids.includes(item.id));
    });
  };

  return {
    ...query,
    coupons,
    deleteCoupon,
  };
};

export const useBranches = (params = {}) => {

  const [branches, setBranches] = useState([]);

  const query = useQuery(["branches"], () => getAllBranchesApi({ page_size: 'all', ...params }), {
    cacheTime: 0,
  });

  const createBranch = (data) => {
    setBranches([
      ...branches,
      data
    ])
  }

  const updateBranch = (id, data) => {
    setBranches(prev => prev.map(branch => {
      if (branch.id === id) return data
      return branch
    }))
  }

  const deleteBranches = (ids) => {
    setBranches((current) => {
      return current.filter((item) => !ids.includes(item.id));
    });
  };

  useEffect(() => {
    if (query.data) {
      setBranches(query.data.data.data);
    }
  }, [query.data]);

  return {
    ...query,
    branches,
    setBranches,
    createBranch,
    updateBranch,
    deleteBranches,
  };
};

export const useGallery = () => {

  const [gallery, setGallery] = useState([]);

  const query = useQuery(["gallery"], getAllGaleriesApi, {
    cacheTime: 0,
  });

  const deleteGalleries = (ids) => {
    setGallery((current) => {
      return current.filter((item) => !ids.includes(item.id));
    });
  };

  useEffect(() => {
    if (query.data) {
      setGallery(query.data.data.data);
    }
  }, [query.data]);

  return {
    ...query,
    gallery,
    deleteGalleries,
    setGallery,
  };
};

export const usePagesMenu = () => {
  const [pagesMenu, setPagesMenu] = useState([]);

  const query = useQuery(["pages-menu"], () => getAllPagesMenuApi({ page_size: 'all' }), {
    cacheTime: 0
  });

  const deletePagesManu = (ids) => {
    setPagesMenu((current) => {
      return current.filter((item) => !ids.includes(item.id));
    });
  };

  useEffect(() => {
    if (query.data) {
      setPagesMenu(query.data.data.data);
    }
  }, [query.data]);

  return {
    ...query,
    pagesMenu,
    setPagesMenu,
    deletePagesManu,
  };
};

export const useSlider = () => {
  
  const [sliders, setSliders] = useState([]);

  const query = useQuery(["sliders"], () => getAllSlidersApi({ page_size: 'all' }), {
    cacheTime: 0,
    onSuccess(data) {
      setSliders(data.data.data);
    },
  });

  const createSlider = (data) => {
    setSliders([
      ...sliders,
      data
    ])
  }

  const updateSlider = (id, data) => {
    setSliders(prev => prev.map(slider => {
      if (slider.id === id) return data
      return slider
    }))
  }

  const deleteSliders = (ids) => {
    setSliders((current) => {
      return current.filter((item) => !ids.includes(item.id));
    });
  };

  useEffect(() => {
    if (query.data) {
      setSliders(query.data.data.data);
    }
  }, [query.data]);

  return {
    ...query,
    sliders,
    createSlider,
    updateSlider,
    deleteSliders,
    setSliders,
  };
};

export const useGetOrderStatus = () => {

  const [status, setStatus] = useState([]);

  useQuery(["order-status"], getOrderStatusApi, {
    onSuccess({data: { data }}) {
      setStatus(data);
    },
  });

  return {
    status,
  };
};

export const useGetOrders = () => {
  const [orders, setOrders] = useState([]);

  const query = useQuery(
    ["orders", "recent"],
    () =>
      getAllOrdersApi({
        page: 1,
        page_size: 10,
      }),
    {
      onSuccess(data) {
        setOrders(data.data.data);
      },
    }
  );

  return {
    orders,
    ...query,
  };
};


export const useGetRooms = (params) => {

  const [rooms, setRooms] = useState(null);

  const query = useQuery(["rooms"], () => getAllRooms(params), {
    enabled: false,
    onSuccess(data) {
      setRooms(data.data.data);
    },
  });

  return {
    rooms,
    ...query,
  };
  
};

export const useGetTables = (params) => {
  const [tables, setTables] = useState(null);

  const query = useQuery(["tables"], () => getAllTables(params), {
    enabled: false,
    onSuccess(data) {
      setTables(data.data.data);
    },
  });

  return {
    tables,
    ...query,
  };
};

export const useGetTableServices = (params) => {

  const [tableServices, setTableServices] = useState(null);

  const query = useQuery(["table-services"], () => getAllTableServices(params), {
    enabled: false,
    onSuccess(data) {
      setTableServices(data.data.data);
    },
  });

  return {
    tableServices,
    ...query,
  };
};

export const useGetShelves = (params) => {

  const [shelves, setShelves] = useState(null);

  const query = useQuery(["shelves"], () => getAllShelves(params), {
    enabled: false,
    onSuccess(data) {
      setShelves(data.data.data);
    },
  });

  return {
    shelves,
    ...query,
  };
};

export const useGetBlackAndWhite = (params) => {

  const [blackAndWhite, setBlackAndWhite] = useState(null);

  const query = useQuery(["black-white"], () => getAllBlackAndWhite(params), {
    enabled: false,
    onSuccess(data) {
      setBlackAndWhite(data.data.data);
    },
  });

  return {
    blackAndWhite,
    ...query,
  };
};

