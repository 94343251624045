import React, { useEffect, useState } from 'react'
import { Card, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import BranchContacts from './Tabs/Contact';
import OpeningHoursTab from './Tabs/OpeningHours';
import BranchDeliverySettings from './Tabs/DeliverySettings';
import classnames from "classnames";
import { useTranslation } from 'react-i18next';
import BranchSpecialDays from './Tabs/SpecialDays';
import BranchZones from './Tabs/Zones';
import { useForm } from 'react-hook-form';
import CustomButton from '../../Custom/CustomButton';
import toastAlert from '../../../helpers/toastAlert';
import { copyBranchDefault, updateBranchSettingsApi } from '../../../apis/Settings/branchesApi';

const TABS = [
  {
    name: 'Settings',
    children: BranchDeliverySettings,
    type: 'settings',
  },
  {
    name: 'Contact',
    children: BranchContacts,
    type: 'contact',
  },
  {
    name: 'Opening Hours',
    children: OpeningHoursTab,
    type: 'opening',
  },
  {
    name: 'Special Days',
    children: BranchSpecialDays,
    type: 'dates',
  },
  {
    name: 'Zones',
    children: BranchZones,
  },
]

const BranchSettings = ({ initialValues }) => {

  const [defaultValues, setDefaultValues] = useState(null);

  const [submitting, setSubmitting] = useState(null);

  const [copying, setCopying] = useState(false);

  const { register, handleSubmit, setValue, watch } = useForm();

    const [activeTab, setActiveTab] = useState(0);

    const copyDefault = async (type) => {
      try {
        setCopying(type);
        const { data: { data }} = await copyBranchDefault(initialValues.id, {
          type
        });
        setDefaultValues(data);
        toastAlert({ type: 'success' })
      } catch (error) {
        toastAlert({ type: 'error' })
      } finally {
        setCopying(null);
      }
    }

    const submitForm = async (data) => {
      try {
        setSubmitting(true);
        const response = await updateBranchSettingsApi(initialValues.id, data);
        console.log('response', response);
        toastAlert({ type: 'success' })
      } catch (error) {
        toastAlert({ type: 'error' })
      } finally {
        setSubmitting(false);
      }
    }

    const { t } = useTranslation();

    useEffect(() => {
        setDefaultValues(initialValues);
    }, [initialValues])

    useEffect(() => {
      if (defaultValues) {
        setValue('contact', defaultValues.contact);
        setValue('settings', defaultValues.settings);
      }
    }, [defaultValues])

  if (!defaultValues) return <></>

  return (
    <Card className='p-3'>

        <Nav className="nav-tabs-custom card-header-tabs border-bottom-0 mb-3">
            {TABS.map((tab, index) => {
              return (
                <NavItem key={index}>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: activeTab === index })}
                    onClick={() => setActiveTab(index)}
                  >
                    {t(tab.name)}
                  </NavLink>
                </NavItem>
              );
            })}
        </Nav>

        <TabContent activeTab={activeTab}>
          {TABS.map((tab, index) => {
            return (
              <TabPane key={index} tabId={index}>
                {!!tab.type && 
                  <div className='d-flex justify-content-end mb-3'>
                    <CustomButton 
                      isLoading={copying === tab.type}
                      className="btn btn-primary"
                      onClick={() => copyDefault(tab.type)}
                    >
                      Copy default
                    </CustomButton>
                  </div>
                }
                {
                  <tab.children 
                    data={defaultValues}
                    register={register}
                    watch={watch}
                    setValue={setValue}
                  />
                }
              </TabPane>
            );
          })}
        </TabContent>

        <div className='d-flex mt-3'>
          <CustomButton
            type="button"
            className="btn btn-success"
            onClick={handleSubmit(submitForm)}
            isLoading={submitting}
          >
            Save
          </CustomButton>
        </div>

    </Card>
  )
}

export default BranchSettings