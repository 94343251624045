import React from 'react'
import { useSelector } from 'react-redux'
import CustomButton from '../Custom/CustomButton';
import { useDispatch } from 'react-redux';
import { notificationActions } from '../../redux/slices/notificationsSlice';

const NotificationSound = () => {

    const dispatch = useDispatch();

   const { sounds } = useSelector(state => state.notifications);

   const handleChange = (field) => {
    const body = {
        [field]: !sounds[field]
    };
    dispatch(notificationActions.toggleSound(body));
   }

  return (

    <div className='d-flex flex-column justify-content-center align-items-center gap-2 p-2'>
        <div className='d-flex gap-2'>
            <CustomButton
                type="button"
                className={sounds.newNotifications ? 'btn-soft-success' : 'btn-soft-danger'}
                onClick={() => handleChange('newNotifications')}
            >
                {sounds.newNotifications ? 'Disable' : 'Enable'} new notifications sound
            </CustomButton>
        </div>

        <div className='d-flex gap-2'>
            <CustomButton
                type="button"
                className={sounds.unseenNotifications ? 'btn-soft-success' : 'btn-soft-danger'}
                onClick={() => handleChange('unseenNotifications')}
            >
                {sounds.unseenNotifications ? 'Disable' : 'Enable'} unseen notifications sound
            </CustomButton>
        </div>
    </div>
  )
}

export default NotificationSound