import axiosInstance from "..";

export const getAuthenticatedAdmin = () => {
  return axiosInstance.get("/admin/auth");
};

export const loginApi = (body) => {
  return axiosInstance.post("/admin/login", body);
};

export const getAllAdminsApi = () => {
  return axiosInstance.get("/admins", {
    params: {
      page_size: "all",
    },
  });
};

export const createAdminApi = (body) => {
  return axiosInstance.post("/admins", body);
};

export const updateAdminApi = (id, body) => {
  return axiosInstance.post(`/admins/${id}`, body);
};

export const deleteAdminsApi = (ids = []) => {
  return axiosInstance.delete(`/admins`, {
    params: {
      ids,
    },
  });
};

export const getAllRolesApi = () => {
  return axiosInstance.get("/roles");
};
