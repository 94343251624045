import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux';
import { Col, Modal, ModalBody, Row } from 'reactstrap'
import { componentItemRqBody } from '../../../../helpers/requests/Food/componentItemRqBody';
import useActions from '../../../../hooks/useActions';
import { createFoodAction, updateFoodAction } from '../../../../redux/actions/foodActions';
import SelectFile from '../../../Common/SelectFile';
import CustomButton from '../../../Custom/CustomButton';
import CustomInput from '../../../Custom/CustomInput';
import CustomLabel from '../../../Custom/CustomLabel';

const ItemModal = ({ isOpen, toggleModal, initialValues, setInitialValues,  type, language, refetchItems, componentId, afterSubmit }) => {

    const {register, handleSubmit, reset} = useForm({
    });

    const {isLoading, onLoading, onError, onFinally} = useActions();

    const [icon, setIcon] = useState({
      url: '',
      name: '',
      file: null
    })

    const dispatch = useDispatch();

    const submitForm = (data) => {
      const requestBody = componentItemRqBody(data);
      onLoading();

      const formData = new FormData();

      Object.keys(requestBody).forEach(key => {
          formData.append(key, requestBody[key]);
      })
      formData.append('component_id', componentId);
      const file = icon.file
      if (file) {
          const blob = new Blob([file], {type: file.type})
          formData.append('icon', blob, file.name);
      }
      if (type === 'create') {
          dispatch(createFoodAction({
              field: 'componentItems',
              data: formData,
              language
          }, {
          onSuccess(data) {
              if (afterSubmit) {
                afterSubmit(data);
              }
              else {
                refetchItems();
                toggleModal();
              }
              onFinally();
              setIcon({
                url: icon.url,
                name: icon.name,
                file: null
            })
          },
          onError
          }))
      } else {
          dispatch(updateFoodAction({
              field: 'componentItems',
              id: initialValues.id,
              data: formData,
              language
          }, {
              onSuccess() {
                  refetchItems();
                  onFinally();
                  toggleModal();
              },
              onError
          }))
      }
  }

    useEffect(() => {
        if (initialValues) {
            const {icon, file, ...rest} = initialValues
            reset(rest);
        }
    }, [initialValues])

    useEffect(() => {
        if (!isOpen) {
            reset({
                name: '',
                price: '',
                price_extra: '',
                price_half: '',
                price_half_extra: '',
            });
            setIcon({
              url: '',
              file: null
            })
            setInitialValues(null);
        }
    }, [isOpen])

    useEffect(() => {
      if (initialValues?.icon) {
          setIcon({
              id: initialValues.file.id,
              url: initialValues.icon,
              name: initialValues.file?.name,
              file: null,
          })
      }
    }, [initialValues])

  return (

    <React.Fragment>
        <Modal 
            centered 
            isOpen={isOpen}
            toggle={toggleModal}
        >
            <ModalBody>
                <Row className='gy-4'>
                    <Col lg={6}>
                        <CustomLabel htmlFor="name" className='form-label' translatable >Name</CustomLabel>
                        <CustomInput name="name" {...register('name')} type="text" className='form-control' id="name" />
                    </Col>

                    <Col lg={6}>
                        <CustomLabel htmlFor="price" className='form-label'>Price</CustomLabel>
                        <CustomInput name="price" {...register('price')} type="number" className='form-control' id="price" />
                    </Col>

                    <Col lg={6}>
                        <CustomLabel htmlFor="extra-price" className='form-label'>Extra Price</CustomLabel>
                        <CustomInput name="extra-price" {...register('price_extra')} type="number" className='form-control' id="extra-price" />
                    </Col>

                    <SelectFile
                        type='image'
                        max={1}
                        display
                        selectedFiles={icon}
                        setSelectedFiles={setIcon}
                        initialValues={initialValues}
                    />

                    <Col lg={12}>
                        <CustomButton
                            onClick={handleSubmit(submitForm)}
                            className="btn btn-success"
                            isLoading={isLoading}
                        >
                            Save
                        </CustomButton>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    </React.Fragment>
    
  )
}

export default ItemModal