import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { deleteGroupsApi } from "../../../apis/users/groupsApis";
import ListHeader from "../../../Components/Common/ListHeader";
import CustomButton from "../../../Components/Custom/CustomButton";
import CustomInput from "../../../Components/Custom/CustomInput";
import PageContainer from "../../../Components/Layout/PageContainer";
import GroupsTable from "../../../Components/Users/Groups/Table";
import toastAlert from "../../../helpers/toastAlert";
import useActions from "../../../hooks/useActions";
import useFilterData, { FILTER_TYPES } from "../../../hooks/useFilterData";
import useModal from "../../../hooks/useModal";
import { useGetGroups } from "../../../hooks/useQueryHelpers";
import useSelectItems from "../../../hooks/useSelectItems";
import { getPathname } from "../../../Layouts/LayoutMenuData";

const Groups = () => {
  const { groups, refetch, isLoading, isRefetching, deleteGroups } =
    useGetGroups();

  const {
    isLoading: isDeleting,
    onLoading: onDeleting,
    onFinally,
  } = useActions();

  const [searchQuery, setSearchQuery] = useState("");

  const { isDisable, selectItems, getSelectedIds } = useSelectItems();
  const { isModalOpen, setIsModalOpen, openModal, closeModal } = useModal();

  const data = useFilterData(groups, {
    name: {
      type: FILTER_TYPES.MATCH,
      key: "name",
      value: searchQuery,
    },
  });

  const onDeleteClick = async () => {
    onDeleting();
    try {
      const ids = getSelectedIds();
      await deleteGroupsApi(ids);
      closeModal();
      deleteGroups(ids);
      selectItems([]);
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      onFinally();
    }
  };

  return (
    <PageContainer isLoading={isLoading}>
      <Row className="gy-4">
        <Col xs={12}>
          <ListHeader
            isDisable={isDisable}
            openModal={openModal}
            pathname="users/groups/create"
            query={searchQuery}
            setQuery={setSearchQuery}
          />
        </Col>
        <Col xs={12}>
          <GroupsTable
            data={data}
            modal={isModalOpen}
            setModal={setIsModalOpen}
            onDeleteClick={onDeleteClick}
            selectItems={selectItems}
            isDeleting={isDeleting}
          />
        </Col>
      </Row>
    </PageContainer>
  );
};

export default Groups;
