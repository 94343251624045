import React from "react";
import { useSelector } from "react-redux";

const OrderGreenComponents = ({ green, greenIcon }) => {

  const { paymentSettings } = useSelector(state => state.settings);

  const symbol = paymentSettings?.settings?.view_currency;

  return (
    green.length > 0 && (
      <div className="order-item-food-component">
        <img src={greenIcon} alt="" style={{}} />
        <div
          className=""
          style={{
            gap: 2,
          }}
        >
          {green.map((item, idx) => {
            return (
              <div
                key={idx}
                className={`mb-0 fs-13 text-success`}
                style={{
                  fontWeight: 500,
                }}
              >
                <p className="mb-0">
                  {item.qty > 1 && `${item.qty} x `}
                  {!!item.extra
                    ? `Extra ${item.name} (${
                        item.price + (item.extra_price || 0)
                      }${symbol})`
                    : `${item.name} (${item.price}${symbol})`}
                </p>
                {/* {renderItem(item)} */}
              </div>
            );
          })}
        </div>
      </div>
    )
  );
};

export default OrderGreenComponents;
