import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'
import CustomInput from '../../../Custom/CustomInput'
import { useQuery } from '@tanstack/react-query'
import { getStripeWebhooks, createStripeWebhooks, deleteStripeWebhooks } from '../../../../apis/Settings/paymentApi'
import CustomButton from '../../../Custom/CustomButton'
import LoadingComponent from '../../../Common/Loading'
import toastAlert from '../../../../helpers/toastAlert'

const StripeWebhook = () => {

    const [data, setData] = useState(null);
    const [fetched, setFetched] = useState(false);
    const [creating, setCreating] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const hostname = location.hostname?.split('.')?.length === 3 ? location.hostname?.split('.').slice(1).join('.') : location.hostname;

    const [placeholderUrl] = useState(`https://server.${hostname}/api/v1/payment/strip/webhook_listen`)

    const { refetch, isLoading, isRefetching } = useQuery(['stripe-webhooks'], () => getStripeWebhooks(), {
        retry: false,
        cacheTime: 0,
        enabled: false,
        onSuccess({ data: { data }}) {
            if(data[0]) setData(data[0]);
        },
    })

    const createWebhook = async () => {
        if (data?.url) return;
        setCreating(true);
        try {
            const { data } =  await createStripeWebhooks({ url: placeholderUrl });
            setData(data);
            toastAlert({ type: 'success' })
        } catch (error) {
            toastAlert({ type: 'error' })
        } finally {
            setCreating(false);
        }
    }

    
    const deleteWebhook = async () => {
        if (!data?.id) return;
        setDeleting(true);
        try {
            await deleteStripeWebhooks({ id: data?.id });
            setData(null);
            toastAlert({ type: 'success' })
        } catch (error) {
            toastAlert({ type: 'error' })
        } finally {
            setDeleting(false);
        }
    }

  return (
    
    <Row className='gy-3'>

        <Col lg={12}>
            <CustomButton onClick={() => {
                setFetched(true);
                refetch();
            }}>
                Check Webhook
            </CustomButton>
        </Col>

        {fetched &&
            <LoadingComponent loading={isLoading || isRefetching} styles={{ minHeight: 'auto' }}>
                <Col lg={6} className='d-flex gap-2'>
                    
                    <CustomInput 
                        type="text"
                        name="client_id_test"
                        value={data?.url || placeholderUrl}
                        disabled
                    />

                    <CustomButton
                        type="button"
                        className={`btn-success d-flex gap-1 align-items-center`}
                        onClick={createWebhook}
                        disabled={data?.url}
                        isLoading={creating}
                    >
                        <i className="ri-add-line fs-16"></i>
                    </CustomButton>

                    
                    <CustomButton
                        type="button"
                        className={`btn-danger d-danger gap-1 align-items-center`}
                        onClick={deleteWebhook}
                        disabled={!data?.id}
                        isLoading={deleting}
                    >
                        <i className="ri-delete-bin-4-line fs-16"></i>
                    </CustomButton>

                </Col>

                <Col lg={12} className='d-flex gap-2'>
                    {data?.enabled_events?.map((item, index) => (
                        <div key={index} className={`p-3 border text-white text-capitalize bg-success`}>
                            {item.split('.').slice(1).join('.')}
                        </div>
                    ))}
                </Col>
                
            </LoadingComponent>
        }

    </Row>
  )
}

export default StripeWebhook