export const extractIds = (inputString) => {
  
    const idPattern = /([a-zA-Z]+)-(\d+)/g;

    const idMatches = [...inputString.matchAll(idPattern)];
    const ids = {};

    for (const match of idMatches) {
      const name = match[1];
      const id = parseInt(match[2]);
      ids[name] = id;
    }
    return ids;
}