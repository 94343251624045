import React from 'react';
import ReactApexChart from "react-apexcharts";
import { useTranslation } from 'react-i18next';

const CategoriesChart = ({ data }) => {

    const { t } = useTranslation();

    var options = {
        labels: data.map(cat => cat.name),
        chart: {
            type: "donut",
        },

        plotOptions: {
            pie: {
                size: 100,
                offsetX: 0,
                offsetY: 0,
                donut: {
                    size: "75%",
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            fontSize: '18px',
                            offsetY: -5,
                        },
                        value: {
                            show: true,
                            fontSize: '20px',
                            color: '#343a40',
                            fontWeight: 500,
                            offsetY: 5,
                            formatter: function (val) {
                                return `${val} ${t('Units Sold')}`;
                            }
                        },
                        total: {
                            show: true,
                            fontSize: '13px',
                            label: t('Total Units Sold'),
                            color: '#9599ad',
                            fontWeight: 500,
                            formatter: function (w) {
                                return w.globals.seriesTotals.reduce(function (a, b) {
                                    return a + b;
                                }, 0);
                            }
                        }
                    }
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: true,
            position: 'left',
        },
        yaxis: {
            labels: {
                formatter: function (value) {
                    return `${value} ${t('Units')}`;
                }
            }
        },
        stroke: {
            lineCap: "round",
            width: 2
        },
    };
    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={data.map(cat => cat.score)}
                type="donut"
                height="350"
                className="apex-charts"
            />
        </React.Fragment>
    );
};


export default CategoriesChart;