import React from 'react'
import CustomLabel from '../../../Custom/CustomLabel'

const MaxPrice = ({register}) => {

  return (
    <div className="form-check form-switch">
        <input  {...register('half_max_price')} className="form-check-input fs-16" type="checkbox" role="switch" id="half_max_price" />
        <CustomLabel className="form-check-label" htmlFor="half_max_price">Take Maximum Price</CustomLabel>
    </div>
  )
}

export default MaxPrice