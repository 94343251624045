import React from 'react'
import { Col, Row } from 'reactstrap';
import CustomLabel from '../../Custom/CustomLabel';
import CustomInput from '../../Custom/CustomInput';

const PusherConfig = ({ prefix, register }) => {

  return (

    <Row className="gy-3 p-3">

        <Col lg={6}>
            <CustomLabel>
                App ID
            </CustomLabel>
            <CustomInput
                {...register(`${prefix}.app_id`)}
                type="text"
            />
        </Col>

        <Col lg={6}>
            <CustomLabel>
                App Key
            </CustomLabel>
            <CustomInput 
                {...register(`${prefix}.app_key`)}
                type="text"
            />
        </Col>

        <Col lg={6}>
            <CustomLabel>
                App Secret
            </CustomLabel>
            <CustomInput
                {...register(`${prefix}.app_secret`)} 
                type="text"
            />
        </Col>

        <Col lg={6}>
            <CustomLabel>
                App Cluster
            </CustomLabel>
            <CustomInput
                {...register(`${prefix}.app_cluster`)} 
                type="text"
            />
        </Col>

    </Row>
  )
}

export default PusherConfig