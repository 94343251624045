export const imageToDataUrl = async (imageUrl) => {
    if (!imageUrl) return null
    try {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const dataUrl = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result);
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
        return dataUrl;
      } catch (error) {
        console.error('Error converting image to data URL:', error);
        return null;
      }
}