import { getKeysSettings, setKeysSettings } from "../../apis/Settings/keysApi";
import { getPaymentSettings, setPaymentSettings, setPaymentSettingsLogo } from "../../apis/Settings/paymentApi";
import { dataUrlToFile } from "../../helpers/dataUrlToFile";
import { isBase64 } from "../../helpers/isBase64";
import toastAlert from "../../helpers/toastAlert";
import { settingsActions } from "../slices/settingsSlice";

export const getPaymentSettingsAction = (data) => async (dispatch) => {
  try {
    const { data: { data: response } } = await getPaymentSettings();
    dispatch(settingsActions.setPaymentSettings(response));
    if (data?.onSuccess) {
      data?.onSuccess();
    }
  } catch (error) {
    if (data?.onError) {
      data?.onError(error);
    }
  }
};

export const setPaymentSettingsAction = (data) => async (dispatch) => {
  try {
    const { data: { data: response } } = await setPaymentSettings(data.data);
    dispatch(settingsActions.setPaymentSettings(response));
    await Promise.all(data.data.datas.filter(item => isBase64(item.logo)).map(async (item) => {
      const formData = new FormData();
      const file = await dataUrlToFile(item.logo);
      const blob = new Blob([file], {type: file.type});
      formData.append('logo', blob);
      return await setPaymentSettingsLogo(item.id, formData);
    }))
    if (data.onSuccess) {
      data.onSuccess();
    }
    if (data?.options?.notification !== undefined && data?.options?.notification !== null) {
        data?.options?.notification && toastAlert({ type: 'success' });
    } else {
      toastAlert({ type: 'success' });
    }
  } catch (error) {
    console.log('error', error);
    if (data.onError) {
      data.onError(error);
    }
    toastAlert({ type: 'error' });
  }
};


export const getKeysSettingsActions = (data) => async (dispatch) => {
  try {
    const { data: { data: response } } = await getKeysSettings();
    dispatch(settingsActions.setKeysSettings(response));
    if (data?.onSuccess) {
      data?.onSuccess(response);
    }
  } catch (error) {
    if (data?.onError) {
      data?.onError(error);
    }
  }
};

export const setKeysSettingsAction = (data) => async (dispatch) => {
  try {
    const { data: { data: response } } = await setKeysSettings(data.data);
    dispatch(settingsActions.setKeysSettings(response));
    if (data.onSuccess) {
      data.onSuccess(response);
    }
    toastAlert({ type: 'success' });
  } catch (error) {
    if (data.onError) {
      data.onError(error);
    }
    toastAlert({ type: 'error' });
  }
};