import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getFoodDataAction } from '../../../../redux/actions/foodActions';
import CustomLabel from '../../../Custom/CustomLabel';

const Doneness = ({foodType, initialValues, setValue}) => {

  const dispatch = useDispatch();

  const [selected, setSelected] = useState(null);

  const {
    doneness: {list: doneness}
  } = useSelector(state => state.food);


  const handleSelection = (id) => {
    if (selected?.includes(id)) setSelected(prev => prev.filter(item => item !== id))
    else setSelected([...(selected || []), id])
  }

  useEffect(() => {
    dispatch(getFoodDataAction({
        field: 'doneness',
        params: {
        page_size: 1000
        }
    }))
  }, [])

  useEffect(() => {
    if (selected) {
      setValue('cooking_levels', selected);
    } else {
      setValue('cooking_levels', null);
    }
    return () => {
      setValue('cooking_levels', null);
    }
  }, [selected])

  useEffect(() => {
    if (initialValues?.cooking_levels) {
        setSelected(initialValues.cooking_levels.map(item => item.id));
    }
  }, [foodType, initialValues])

  return (
    <React.Fragment>
      <div>
        <CustomLabel>Doneness</CustomLabel>
        <div className='selectable-tabs-container'>
          {doneness.map(done => (
              <div
                key={done.id} 
                className={`selectable-tab doneness-tab ${selected?.includes(done.id) ? 'selected-tab' : ''}`}
                onClick={() => handleSelection(done.id)}
               >
                {done.name}
              </div>
            ))}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Doneness