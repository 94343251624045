import React from "react";
import { useTranslation } from "react-i18next";

const CustomOption = ({ children, ...rest }) => {

  const {t} = useTranslation();

  return <option {...rest}>{typeof children === 'string' ? t(children) : children}</option>;
};

export default CustomOption;
