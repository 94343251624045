import React from "react";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";

const Footer = () => {
  
  const { footer } = useSelector((state) => state.settings);

  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid>
          <Row>
            <Col sm={6}>{footer.footer}</Col>
            <Col sm={6}>
              <div className="text-sm-end d-none d-sm-block">
                {footer.copyright}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
