import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import CustomLabel from '../../Custom/CustomLabel'
import { useQuery } from '@tanstack/react-query'
import { getColorsApi, setColorsApi } from '../../../apis/Settings/webSettingsApi'
import { useForm } from 'react-hook-form'
import LoaderComponent from '../../Common/LoaderComponent'
import CustomButton from '../../Custom/CustomButton'
import toastAlert from '../../../helpers/toastAlert'

const ColorsTab = () => {

    const [initialValues, setInitialValues] = useState(null);

    const { register, handleSubmit, reset } = useForm();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const { isLoading, isRefetching } = useQuery(['colors'], getColorsApi, {
        onSuccess({data: { data }}) {
            setInitialValues(data);
        }
    })

    const submitForm = async (data) => {
        setIsSubmitting(true);
        try {
            const { data: { data: resData }} = await setColorsApi(data)
            console.log('response', resData);
            toastAlert({ type: 'success' })
        } catch (error) {
            toastAlert({ type: 'error' })
            console.log('error', error);
        } finally {
            setIsSubmitting(false);
        }
    }

    useEffect(() => {
        if (initialValues) reset(initialValues)
    }, [initialValues])

  return (
    <form onSubmit={handleSubmit(submitForm)}>
        <Row className='p-3 gy-3'>
            <LoaderComponent isLoading={isLoading || isRefetching}>
                <Col lg={3}>
                    <div>
                        <CustomLabel htmlFor="btn-text-color" className="form-label">Active color</CustomLabel>
                        <input type="color" {...register('active_color')} className="form-control form-control-color w-100"/>
                    </div>
                </Col>

                <Col lg={3}>
                    <div>
                        <CustomLabel htmlFor="btn-text-color" className="form-label">Active font color</CustomLabel>
                        <input type="color" {...register('active_font_color')}  className="form-control form-control-color w-100"/>
                    </div>
                </Col>

                <Col lg={3}>
                    <div>
                        <CustomLabel htmlFor="btn-text-color" className="form-label">Active select box color</CustomLabel>
                        <input type="color" {...register('active_select_box_color')}  className="form-control form-control-color w-100"/>
                    </div>
                </Col>

                <Col lg={12}>
                    <CustomButton
                        className="btn btn-success"
                        isLoading={isSubmitting}
                    >   
                        Save
                    </CustomButton>
                </Col>
            </LoaderComponent>
        </Row>
    </form>
  )
}

export default ColorsTab