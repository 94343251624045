import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";
import DeleteModal from "./DeleteModal";

const DataTableContainer = ({
  selectableRows,
  data,
  selectItems,
  modal,
  setModal,
  columns,
  handleDelete,
  isDeleting,
  paginationServer,
  navigateToPage,
  paginationTotalRows,
  handleClick,
  path,
  pathType,
  noPagination,
  expandableRows,
  expandableRowComponent,
  expandableRowExpanded,
  selectableRowDisabled,
  loading,
  pageSize,
  setPageSize,
  conditionalRowStyle,
  onSort,
  sortDirection,
}) => {
  // const selectItems = (items) => {
  // setItemsToDelete(items);
  // };

  const { t } = useTranslation();

  const history = useHistory();
  const [loadingState, setLoadingState] = useState(true);

  const customStyles = () => {
    if (path || handleClick)
      return {
        rows: {
          style: {
            cursor: "pointer",
            overflow: "visible",
            "&:hover": {
              filter: "brightness(95%)",
            },
          },
        },
      };
  };

  const getLoadingState = () => {
    if (typeof loading === "boolean") {
      setLoadingState(loading);
    } else if (data?.length === 0) {
      setTimeout(() => {
        setLoadingState(false);
      }, 4000);
    } else {
      setLoadingState(false);
    }
    return loadingState;
  };

  useEffect(() => {
    getLoadingState();
  }, [data, loading]);


  return (
    <React.Fragment>
      {modal && (
        <DeleteModal
          show={modal}
          onCloseClick={() => {
            if (selectItems) {
              selectItems([]);
            }
            setModal(false);
          }}
          onDeleteClick={handleDelete}
          isDeleting={isDeleting}
        />
      )}
      <DataTable
        columns={columns}
        pagination={!noPagination}
        paginationServer={paginationServer}
        paginationPerPage={pageSize || undefined}
        onChangePage={(page) => {
          if (paginationServer) {
            navigateToPage(page);
            window.scrollTo({
              top: 50,
              behavior: "smooth",
            });
          }
        }}
        noDataComponent={
          <div
            className="no-data-component"
          >
            <p style={{ margin: 0, marginBottom: 0 }}>
              {t("There are no records to display")}
            </p>
          </div>
        }
        expandableRowsComponent={(row) =>
          expandableRowComponent && expandableRowComponent(row)
        }
        expandableRowExpanded={(row) => {
          if (expandableRowExpanded) return expandableRowExpanded(row);
        }}
        data={data}
        paginationTotalRows={paginationTotalRows || undefined}
        expandableRows={expandableRows}
        selectableRows={selectableRows}
        onSelectedRowsChange={(items) => {
          if (selectItems) selectItems(items.selectedRows);
        }}
        selectableRowDisabled={(row) => {
          if (selectableRowDisabled) return selectableRowDisabled(row);
        }}
        customStyles={customStyles()}
        onRowClicked={(row) => {
          if (!handleClick && !path) return;
          if (handleClick) return handleClick(row);
          return history.push(`${path}/${pathType || "update"}/${row.id}`);
        }}
        progressPending={loadingState}
        progressComponent={
          <div className="progress-component">
            <Spinner color="primary" className="my-4" />
          </div>
        }
        conditionalRowStyles={conditionalRowStyle}
        onChangeRowsPerPage={
          setPageSize &&
          ((e) => {
            setPageSize(e);
            window.scrollTo({
              top: 50,
              behavior: "smooth",
            });
          })
        }
        sortServer={!!onSort}
        onSort={onSort && onSort}
        defaultSortAsc={sortDirection}
        paginationRowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
        persistTableHead={true}
      />
    </React.Fragment>
  );
};

export default DataTableContainer;
