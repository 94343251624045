import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Col, Row } from "reactstrap";
import CustomLabel from "../../Custom/CustomLabel";
import Flatpickr from "react-flatpickr";
import { format } from "date-fns";
import { errorMessage } from "../../../helpers/errorMessage";
import moment from "moment";

const days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const CouponDateTab = ({ register, errors, initialValues = null, setValue }) => {

  const [date, setDate] = useState({
    start: '',
    end: ''
  });
  const [time, setTime] = useState({
    start: '00:00',
    end: '23:59'
  })

  const handleDateChange = (date) => {
    const start = moment(date[0]).format("YYYY-MM-DD");
    const end = date[1] ? moment(date[1]).format("YYYY-MM-DD") : '';
    setDate({
      start,
      end
    })
  }

  const handleTimeChange = (field, value) => {
    const formattedValue = moment(value).format("HH:mm");
    setTime(prev => ({
      ...prev,
      [field]: formattedValue
    }))
  }

  useEffect(() => {
    setValue('start_date', date.start);
    setValue('end_date', date.end);
  }, [date])

  useEffect(() => {
    setValue('start_time', time.start);
    setValue('end_time', time.end);
  }, [time])

  useEffect(() => {
    if (initialValues) {
      setDate({
        start: initialValues.start_date ?? '',
        end: initialValues.end_date ?? ''
      })

      setTime({
        start: initialValues.start_time ?? '',
        end: initialValues.end_time ?? ''
      })
    }
  }, [initialValues])

  return (
    <Row className="gy-2">

      <Col md={7}>
        <CustomLabel>Date</CustomLabel>
        <Flatpickr
          className="form-control"
          options={{
            mode: "range",
          }}
          value={[date.start, date.end]}
          onChange={handleDateChange}
        />
        {errorMessage(errors, "start_date")}
        {errorMessage(errors, "end_date")}
      </Col>

      <Col md={6}>
        <CustomLabel>Start time</CustomLabel>
        <Flatpickr
          className="form-control"
          name="start_time"
          options={{
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
            time_24hr: true,
            mode: "time",
            maxTime: time.end
          }}
          value={time.start}
          onChange={([date]) => handleTimeChange('start', date)}
        />
        {errorMessage(errors, "start_time")}
      </Col>

      <Col md={6}>
        <CustomLabel>End time</CustomLabel>
        <Flatpickr
          className="form-control"
          name="end_time"
          options={{
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
            time_24hr: true,
            mode: "time",
            minTime: time.start
          }}
          value={time.end}
          onChange={([date]) => handleTimeChange('end', date)}
        />
        {errorMessage(errors, "end_time")}
      </Col>

      <Col md={6}>
        <CustomLabel>Days</CustomLabel>

        {days.map((day) => {
          return (
            <div key={day} className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                name={day}
                id={`day-${day}`}
                {...register(day)}
              />
              <CustomLabel className="mb-0 text-capitalize" htmlFor={`day-${day}`}>
                {day}
              </CustomLabel>
            </div>
          );
        })}
      </Col>
    </Row>
  );
};

export default CouponDateTab;
