import axiosInstance from "..";

export const getAllGroupsApi = () => {
  return axiosInstance.get("/user_groups", {
    params: {
      page_size: "all",
    },
  });
};

export const getOneGroupApi = (id) => {
  return axiosInstance.get(`/user_groups/${id}`);
};

export const createGroupApi = (data) => {
  return axiosInstance.post("/user_groups", data);
};

export const updateGroupApi = (id, data) => {
  return axiosInstance.post(`/user_groups/${id}`, data);
};

export const deleteGroupsApi = (ids) => {
  return axiosInstance.delete("/user_groups", {
    data: {
      ids,
    },
  });
};
