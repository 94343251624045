import React from 'react'
import SortComponentsForm from './Form';

const SortComponents = ({ components, setComponents }) => {

  return (
    <SortComponentsForm 
        components={components}
        setComponents={setComponents}
    />
  )
}

export default SortComponents