import React, { useCallback, useState } from "react";
import { GoogleMap, Polygon, Marker, LoadScript, DrawingManager } from "@react-google-maps/api";
import generateUniqueID from "../../../../helpers/generateUniqueID";
import { getColorPalette } from "../../../../helpers/getColorPalette";
import useDeleteModal from "../../../../hooks/useDeleteModal";
import DeleteModal from "../../../Common/DeleteModal";
import { deleteZone } from "../../../../apis/Orders/deliverySettingsApis";
import toastAlert from "../../../../helpers/toastAlert";
import { useTranslation } from "react-i18next";

const libraries = ["places", "drawing"];

const MapContainer = ({ data, setData, selectedZone, setSelectedZone, centerCoords, shopLocation, apiKey, branchId, branches }) => {

    const { t } = useTranslation();

    const [polygonsObjects, setPolygonObjects] = useState([]);
    const [map, setMap] = useState(null);
    const [drawingMode, setDrawingMode] = useState(null);
    const [idToDelete, setIdToDelete] = useState(null);
    const {load, isLoading, modal, setModal, callback} = useDeleteModal();

    const drawingManagerOptions = {
        drawingControl: true,
        drawingControlOptions: {
          position: 2,
          drawingModes: drawingMode,
        },
        polygonOptions: {
          strokeWeight: 3,
          clickable: false,
          editable: true,
          zIndex: 1,
        },
      };

    const handleMapClick = () => {
        setDrawingMode('polygon');
    }
    const onPolygonload = (id, polygon) => {
        setPolygonObjects(prev => [
            ...prev,
            {
                id: id,
                polygon
            }
        ])
    }

    const createdPolygon = (polygon) => {
        const pallete = getColorPalette(data, data?.length);
        const points = polygon.getPath().getArray().map((item, sort) => ({
            sort,
            latitude: item.lat(),
            longitude: item.lng()
        }))
        const newId = generateUniqueID();
        setData([
            ...data,
            {
                id: newId,
                name: '',
                price: '',
                tax_id: '',
                branche_id: branchId ?? '',
                min_order: '',
                stroke_color: pallete[0],
                fill_color: pallete[1],
                isNew: true,
                points,

            }
        ])
        setSelectedZone(newId)
        setDrawingMode(null);
        if (polygon.setMap) {
            polygon.setMap(null);
        }
    }

    const onPolygonEdit = (polygonId) => {
        console.log('editing', polygonId);
        const polygon = polygonsObjects.find(polygon => polygon.id === polygonId)?.polygon;
        const points = polygon?.getPath().getArray().map((item, sort) => ({
            sort,
            latitude: item.lat(),
            longitude: item.lng()
        }))
        setData(data.map((plg) => {
            if (plg.id === polygonId) {
                return {
                    ...plg,
                    points
                };
            }
            return plg;
        }))
        setSelectedZone(polygonId)
    }


    const onDeletePolygon = async () => {
        load();
        if (!data.find(item => item.id === idToDelete).isNew) {
            await deleteZone([idToDelete]);
        }
        setData(data.filter((polygon) => polygon.id !== idToDelete));
        setPolygonObjects(prev => prev.filter((polygon) => polygon.id !== idToDelete));
        setIdToDelete(null);
        toastAlert({ type: 'success' });
        callback()
    }

    const onLoad = useCallback(function callback(map) {
        setMap(map)
    }, [])

  return (
    
   <>
    <DeleteModal
        show={modal}
        onCloseClick={() => {
            setModal(false);
        }}
        onDeleteClick={onDeletePolygon}
        isDeleting={isLoading}
    /> 
    <LoadScript
        googleMapsApiKey={apiKey}
        libraries={libraries}
        onLoad={onLoad}
        onError={() => console.log("Error loading Google Maps API")}
    >
        <GoogleMap
            mapContainerStyle={{
                height: "400px",
                width: "100%",
            }}
            center={branchId ?(branches.find(branch => branch.id === branchId)?.coords ?? centerCoords) : centerCoords}
            zoom={12}
            version="weekly"
            onClick={handleMapClick}
            onRightClick={() => {
                setDrawingMode(null);
                setSelectedZone(null);
            }}
        >
            <DrawingManager
                drawingMode={drawingMode}
                onPolygonComplete={createdPolygon}
                options={drawingManagerOptions}
            />

            {branches?.filter(branch => branchId ? (branchId === branch.id) : branch.id).map((branch, index) => (
                <Marker
                    key={index}
                    position={branch.coords}
                    icon={{ scaledSize: window.google && new window.google.maps.Size(60, 60) }}
                    options={{
                        label: {
                            text: `${t('Branch')}: ${branch.name}`,
                            className: 'google-maps-marker-label'
                        }
                    }}
                />
            ))}

            {shopLocation &&
                <Marker
                    position={shopLocation}
                />
            }

            {data?.map((item, index) => (
                <Polygon
                    key={item.id}
                    path={item.points.map(point => ({
                        lat: +point.latitude,
                        lng: +point.longitude
                    }))}
                    onLoad={(plg) => onPolygonload(item.id, plg)}
                    options={{
                        fillColor: item.fill_color,
                        strokeColor: item.stroke_color,
                        strokeWeight: 3,
                        zIndex: index
                    }}
                    editable={selectedZone === item.id}
                    draggable={selectedZone === item.id}
                    onClick={() => setSelectedZone(item.id)}
                    onMouseUp={() => {
                        if (selectedZone === item.id) onPolygonEdit(item.id)
                    }}
                    onDragEnd={() => {
                        if (selectedZone === item.id) onPolygonEdit(item.id)
                    }}
                    onRightClick={(e) => {
                        setTimeout(() => {
                            setIdToDelete(item.id);
                            setModal(true);
                        })
                    }}
                    onDblClick={handleMapClick}
                    onUnmount={() => setPolygonObjects(prev => prev.filter(plg => plg.id !== item.id))}
                />
            ))}

        </GoogleMap>
    </LoadScript>
   </>
  );
};

export default MapContainer;
