import React from "react";
import DataTableContainer from "../../Common/DataTableContainer";
import StatusToggle from "../../Common/StatusToggle";
import CustomTableHeading from "../../Custom/CustomTableHeading";

const HomeSlidersTable = ({
  data,
  modal,
  setModal,
  isDeleting,
  handleDelete,
  selectItems,
}) => {
  
  const columns = [
    {
      name: <CustomTableHeading>Title</CustomTableHeading>,
      selector: (row) => row.title,
    },

    {
      name: <CustomTableHeading>Area</CustomTableHeading>,
      selector: (row) => row.area,
    },
    {
      name: <CustomTableHeading>Active</CustomTableHeading>,
      selector: (row) => <StatusToggle type='sliders' id={row.id} field="active" status={row.active} />,
    },
  ];

  return (
    <DataTableContainer
      data={data}
      selectableRows
      selectItems={selectItems}
      modal={modal}
      setModal={setModal}
      isDeleting={isDeleting}
      handleDelete={handleDelete}
      columns={columns}
      path="home-sliders"
      noPagination
    />
  );
};

export default HomeSlidersTable;
