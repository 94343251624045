import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Col } from "reactstrap";
import { getAppApi, setAppApi } from "../../../apis/Settings/webSettingsApi";
import toastAlert from "../../../helpers/toastAlert";
import useActions from "../../../hooks/useActions";
import useCustomForm from "../../../hooks/useCustomForm";
import LoaderComponent from "../../Common/LoaderComponent";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import WebSettingsBody from "./WebSettingsBody";
import WebSettingsFooter from "./WebSettingsFooter";

const AppTab = () => {
  const { values, handleChange, setValues } = useCustomForm({
    active: false,
    version: "",
    android_url: "",
    ios_url: "",
    delivery_android: "",
    delivery_ios: ""
  });

  const { isLoading: isSubmitting, onLoading, onFinally } = useActions();

  const { isLoading } = useQuery(["web-settings", "app"], () => getAppApi(), {
    onSuccess(data) {
      setValues(data.data.data);
    },
  });

  const onSubmit = async () => {
    onLoading();
    try {
      await setAppApi(values);
      toastAlert({ type: 'success' });
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      onFinally();
    }
  };

  return (
    <LoaderComponent isLoading={isLoading}>
      <WebSettingsBody>
        
        <Col md={6}>
          <CustomLabel>Version</CustomLabel>
          <CustomInput
            name="version"
            value={values.version}
            onChange={handleChange}
          />
        </Col>

        <Col md={6}>

          <CustomLabel className="form-check-label" htmlFor="active">
            Active
          </CustomLabel>

          <div className="form-check form-switch">
            <input
              name="active"
              className="form-check-input fs-16"
              type="checkbox"
              role="switch"
              id="active"
              checked={values.active}
              onChange={handleChange}
            />
          </div>
        </Col>

        <Col md={6}>
          <CustomLabel>Android url</CustomLabel>
          <CustomInput
            name="android_url"
            value={values.android_url}
            onChange={handleChange}
          />
        </Col>

        <Col md={6}>
          <CustomLabel>IOS url</CustomLabel>
          <CustomInput
            name="ios_url"
            value={values.ios_url}
            onChange={handleChange}
          />
        </Col>

        <Col md={6}>
          <CustomLabel>Delivery Android</CustomLabel>
          <CustomInput
            name="delivery_android"
            value={values.delivery_android}
            onChange={handleChange}
          />
        </Col>

        <Col md={6}>
          <CustomLabel>Delivery IOS</CustomLabel>
          <CustomInput
            name="delivery_ios"
            value={values.delivery_ios}
            onChange={handleChange}
          />
        </Col>

      </WebSettingsBody>
      <WebSettingsFooter isSubmitting={isSubmitting} onSubmit={onSubmit} />
    </LoaderComponent>
  );
};

export default AppTab;
