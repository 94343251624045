import React, { useState } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import HomeSliderForm from "../../../Components/Settings/HomeSliders/Form";
import PageContainer from "../../../Components/Layout/PageContainer";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getOneSliderApi } from "../../../apis/Settings/sliderApi";
import { useFetchByLang } from "../../../hooks/useFetchByLang";

const UpdateHomeSlider = () => {

  const { id } = useParams();

  const [initValues, setInitValues] = useState(null);

  const { refetch, isLoading, isRefetching } = useQuery(["sliders", id], () => getOneSliderApi(id, language), {
    enabled: false,
    cacheTime: 0,
    onSuccess({data: { data }}) {
      setInitValues(data);
    },
  });


  const { language, setLanguage } = useFetchByLang(refetch);

  return (
    <PageContainer isLoading={isLoading || isRefetching}>
      <BreadCrumb
        pageTitle="Home Sliders"
        title="update"
        to="settings/home-sliders"
      />
      {initValues && (
        <HomeSliderForm 
          type="update" 
          initialValues={initValues} 
          language={language}
          setLanguage={setLanguage}
        />
      )}
    </PageContainer>
  );
};

export default UpdateHomeSlider;
