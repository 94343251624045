import React from 'react'
import CustomButton from '../../Custom/CustomButton'
import { useTranslation } from 'react-i18next'

const CreateButton = ({className, children, ...props}) => {

  const { t } = useTranslation();

  return (
    <CustomButton
        className={`btn-success d-flex gap-1 align-items-center ${className}`}
        {...props}
    >
        <i className="ri-add-line fs-16"></i>
        {t(children || 'Create') }
    </CustomButton>
  )
}

export default CreateButton