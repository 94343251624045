import React from "react";
import { useTranslation } from "react-i18next";
import OrderInfoItem from "./OrderInfoItem";

const OrderPaymentDetails = ({ prop, value }) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex align-items-center mb-2">
      <div className="flex-shrink-0">
        <p className="text-muted mb-0">{t(prop)}:</p>
      </div>
      <div className="flex-grow-1 ms-2">
        <h6 className="mb-0">{t(value)}</h6>
      </div>
    </div>
  );
};

export default OrderPaymentDetails;
