import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { getOneCouponApi } from "../../../apis/Orders/couponApis";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import PageContainer from "../../../Components/Layout/PageContainer";
import CouponForm from "../../../Components/Orders/Coupons/CouponForm";

const UpdateCoupon = () => {
  const { id } = useParams();

  const [initialValues, setInitialValues] = useState(null);


  const setCouponValues = (data) => {
    let { products, selected_users, ...rest } = data;
    products = products?.map(item => item.id) ?? [];
    selected_users = selected_users?.map(item => item.id) ?? [];
    setInitialValues({
      ...rest,
      products,
      selected_users
    })
  }

  const { isLoading } = useQuery(["coupons", id], () => getOneCouponApi(id), {
    onSuccess({ data: { data }}) {
      setCouponValues(data)
    },
  });

  return (
    <PageContainer isLoading={isLoading}>
      <BreadCrumb title="Update" pageTitle="Coupons" to={"orders/coupons"} />
      {initialValues && 
        <CouponForm 
          type="Update" 
          initialValues={initialValues} 
          setCouponValues={setCouponValues}
        />
      }
    </PageContainer>
  );
};

export default UpdateCoupon;
