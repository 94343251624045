export const reorderList = (list, itemId, newIndex) => {
    let newList = [...list];
    const itemIndex = newList.findIndex(item => item.id === itemId);

    if (itemIndex === -1) {
        console.log("Item not found in the list.");
        return;
    }
    const [item] = newList.splice(itemIndex, 1);

    newList.splice(newIndex, 0, item);
    return newList
}



export const insertAtIndex = (list, item, index) => {
    let newArray = [...list];
    newArray.splice(index, 0, item);
    return newArray;
}