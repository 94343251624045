import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Card, Col, Row } from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import ComponentsTable from '../../../Components/Food/Components/Table'
import PageContainer from '../../../Components/Layout/PageContainer'
import { objectFilter } from '../../../helpers/objectFilter'
import useDeleteModal from '../../../hooks/useDeleteModal'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import usePagination from '../../../hooks/usePagination'
import { getPathname } from '../../../Layouts/LayoutMenuData'
import { deleteFoodAction, getFoodDataAction } from '../../../redux/actions/foodActions';
import debounce from "lodash.debounce";
import Restricted from '../../../Components/Guard/Restricted'
import DeleteButton from '../../../Components/Common/Buttons/DeleteButton'
import CreateButton from '../../../Components/Common/Buttons/CreateButton'
import SortButton from '../../../Components/Common/Buttons/SortButton'

const Components = () => {

  const { currentPage, navigateToPage} = usePagination();

  const {selectedItems, setSelectedItems, load, isLoading, modal, setModal, callback, setIsLoading} = useDeleteModal();
  
  const [name, setName] = useState(null);

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const {values: {
      componentsFilter: localFilterValues
  }, setNewValues: setLocalFilterValues} = useLocalStorage({
      componentsFilter: {
        page_size: 10
      }
  })

  const getComponents = (byName) => {
    if (byName) navigateToPage(1);
    setLoading(true);
    dispatch(getFoodDataAction({
      field: 'components',
      params: objectFilter({
        page: byName ? 1 : currentPage,
        ...localFilterValues,
        name
      })
    }, {
      onSuccess() {
        setLoading(false);
      },
      onError(error) {
        console.log('error', error);
        setLoading(false);
      }
    }))
  }

  const setPageSize = (val) => {
    setLocalFilterValues({
        componentsFilter: {
          page_size: val
        }
    })
  }

  const handleDelete = () => {
      load();
      const ids = selectedItems.map(item => item.id);
      dispatch(deleteFoodAction({
        field: 'components',
        data: {
          ids
        }
      }, {
        onSuccess() {
          getComponents();
          callback();
        },
        onError() {
          setIsLoading(false);
        }
      }))
  }

  const debounceSearch = useMemo(() => {
    return debounce(() => getComponents(true), 200);
  }, [localFilterValues, currentPage, name]);

  useEffect(() => {
    if (name !== null) debounceSearch();
  }, [name])

  useEffect(() => {
    getComponents();
  }, [localFilterValues, currentPage])

  return (
    <PageContainer>
        <BreadCrumb pageTitle="Food" title="Components"/>

        <Row className='justify-content-between gy-3'>

          <Col md={3} xs={12} className="d-flex gap-2">

            <Link to={getPathname('food/components/create')}>
              <CreateButton />
            </Link>

            <Restricted allowed={['super-admin', 'developer']}>
              <DeleteButton
                  disabled={!selectedItems.length}
                  onClick={() => setModal(true)}
              />
            </Restricted>

            <SortButton to='food/components/sort' />

          </Col>
          
        </Row>

        <Card className='mt-3'>
          <ComponentsTable 
              loading={loading}
              navigateToPage={navigateToPage}
              pageSize={localFilterValues.page_size}
              setPageSize={setPageSize}
              setSelectedItems={setSelectedItems}
              modal={modal}
              setModal={setModal}
              isLoading={isLoading}
              handleDelete={handleDelete}
              name={name}
              setName={setName}
          />
        </Card>

    </PageContainer>
  )
}

export default Components