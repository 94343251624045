import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { foodApi } from '../../../apis/Food/api'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import ComponentForm from '../../../Components/Food/Components/Form'
import PageContainer from '../../../Components/Layout/PageContainer'
import { useFetchByLang } from '../../../hooks/useFetchByLang'

const UpdateComponent = () => {

  const {id} = useParams();

  const [initialValues, setInitialValues] = useState(null);

  const {isLoading, isRefetching, refetch} = useQuery(['one-component'], () => foodApi.components.getOne(id, language), {
    enabled: false,
    cacheTime: 0,
    onSuccess({data: {data}}) {
      setInitialValues(data);
    }
  })

  const { language, setLanguage } = useFetchByLang(refetch);
    
  return (
    <PageContainer isLoading={isLoading || isRefetching}>
        <BreadCrumb pageTitle="Components" title="Update" to="food/components"/>
        {initialValues && 
          <ComponentForm
            type="update"
            initialValues={initialValues}
            language={language}
            setLanguage={setLanguage}
          />
        }
    </PageContainer>
  )
}

export default UpdateComponent