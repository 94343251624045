import React from "react";
import HorizontalLayout from "./HorizontalLayout";

const Navbar = () => {

  return (
    <React.Fragment>
      <div className="app-menu navbar-menu">
          <div id="scrollbar">
              <div id="two-column-menu"></div>
              <ul className="navbar-nav" id="navbar-nav">
                <HorizontalLayout />
              </ul>
          </div>
      </div>
    </React.Fragment>
  );
};

export default Navbar;
