import React from 'react'
import useActions from '../../../hooks/useActions';
import { setSocialLogin } from '../../../apis/Settings/socialLoginsApi';
import { Col, Row } from 'reactstrap';
import CustomLabel from '../../Custom/CustomLabel';
import CustomInput from '../../Custom/CustomInput';
import CustomButton from '../../Custom/CustomButton';
import toastAlert from '../../../helpers/toastAlert';

const SocialLoginForm = ({data, setData}) => {

    const { isLoading, onLoading, onFinally } = useActions();
    
    const handleChange = (field, value) => {
        setData(prev => prev.map(item => {
          if (item.id === data.id) return {
            ...item,
            [field]: value
          }
          return item
        }))
    }

    const submitForm = async () => {
        onLoading();
        const {id, name, ...rest} = data;
        const requestBody = rest
        try {
          await setSocialLogin(id, requestBody);
          toastAlert({ type: 'success' });
        } catch (error) {
          toastAlert({ type: 'error' });
        } finally {
          onFinally();
        }
    }


  return (

    <Row className="gy-3 p-3">
        <Col lg={6}>
            <CustomLabel>
                Client ID
            </CustomLabel>
            <CustomInput
                type="text"
                value={data.client_id}
                onChange={(e) => handleChange('client_id', e.target.value)}
            />
        </Col>

        <Col lg={6}>
            <CustomLabel>
                Client Secret
            </CustomLabel>
            <CustomInput 
                type="text"
                name="client_secret"
                value={data.client_secret}
                onChange={(e) => handleChange('client_secret', e.target.value)}
            />
        </Col>

        <Col lg={6}>
            <CustomLabel>
                Redirect
            </CustomLabel>
            <CustomInput 
                type="text"
                name="redirect"
                value={data.redirect}
                onChange={(e) => handleChange('redirect', e.target.value)}
            />
        </Col>

        <Col lg={12}>
            <div className="form-check form-switch">
                <input 
                    className="form-check-input fs-16"
                    type="checkbox" 
                    role="switch" 
                    id="login-status" 
                    checked={data.status}
                    onChange={(e) => handleChange('status', e.target.checked)}
                    />
                <CustomLabel 
                    className="form-check-label fs-15" 
                    htmlFor="login-status"
                >
                    Active
                </CustomLabel>
            </div>
    
        </Col>

        <Col lg={12}>
            <CustomButton
                className="btn-success"
                type='button'
                onClick={submitForm}
                isLoading={isLoading}
            >
                Save
            </CustomButton>
        </Col> 

    </Row>
  )
}

export default SocialLoginForm