import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom'
import { Card } from 'reactstrap';
import { foodApi } from '../../../../apis/Food/api';
import { useFormModal } from '../../../../helpers/useFormModal';
import useDeleteModal from '../../../../hooks/useDeleteModal';
import usePagination from '../../../../hooks/usePagination';
import { deleteFoodAction } from '../../../../redux/actions/foodActions';
import DataTableContainer from '../../../Common/DataTableContainer';
import TableImage from '../../../Common/TableImage';
import CustomButton from '../../../Custom/CustomButton';
import CustomTableHeading from '../../../Custom/CustomTableHeading';
import ItemModal from './ItemModal';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';
import { getPathname } from '../../../../Layouts/LayoutMenuData';

const ComponentItems = ({ language }) => {

    const { id } = useParams();

    const [items, setItems] = useState(null);

    const {selectedItems, setSelectedItems, isLoading: isDeleting, modal: deleteModal, setModal: setDeleteModal, callback} = useDeleteModal();

    const {isOpen: formModalOpen, toggleModal: toggleFormModal, type,  openCreateModal, openUpdateModal} = useFormModal();

    const {currentPage, navigateToPage} = usePagination();

    const [initialValues, setInitialValues] = useState(null);

    const dispatch = useDispatch();

    const {values: {
        componentItemsFilter: localFilterValues
    }, setNewValues: setLocalFilterValues} = useLocalStorage({
        componentItemsFilter: {
          page_size: 10
        }
    })

    const { isLoading: itemsLoading, isRefetching, refetch } = useQuery(['component-items'], () => foodApi.componentItems.getAll({
        component_id: id,
        page: currentPage,
        ...localFilterValues,
        lang: language

    }), {
        enabled: false,
        cacheTime: 0,
        onSuccess({data}) {
            setItems({
              list: data.data,
              total: data.meta?.total || null
            });
        }
    })

    const handleDelete = () => {
        const ids = selectedItems.map(item => item.id);
        dispatch(deleteFoodAction({
          field: 'componentItems',
          data: {
            ids
          }
        }, {
          onSuccess() {
            callback();
            refetch();
          },
        }))
    }

    const setPageSize = (val) => {
      setLocalFilterValues({
          componentItemsFilter: {
            page_size: val
          }
      })
    }

    const columns = [

        {
            name: <CustomTableHeading>ID</CustomTableHeading>,
            selector: row => row.id || '-',
            grow: -1,
        },

        {
            selector: row => <TableImage src={row.icon} />,
        },

        {
            name: <CustomTableHeading>Name</CustomTableHeading>,
            selector: row => row.name || '-',
        },

        {
            name: <CustomTableHeading>Price</CustomTableHeading>,
            selector: row => row.price || '-',
        },

        {
            name: <CustomTableHeading>Extra Price</CustomTableHeading>,
            selector: row => row.price_extra || '-',
        },

        {
            name: <CustomTableHeading>Half Price</CustomTableHeading>,
            selector: row => row.price_half || '-',
        },

        {
            name: <CustomTableHeading>Half Extra Price</CustomTableHeading>,
            selector: row => row.price_half_extra || '-',
        },

    ]

  useEffect(() => {
    if (language) refetch();
  }, [currentPage, localFilterValues, language])

  return (

    <div>
        <ItemModal 
            isOpen={formModalOpen}
            toggleModal={toggleFormModal}
            type={type}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            refetchItems={refetch}
            language={language}
            componentId={id}
        />
      
        <div className='hstack gap-2'>

            <CustomButton
                className="btn-success"
                onClick={() => openCreateModal()}
                >
              Add Items
            </CustomButton>

            <CustomButton
              className="btn-danger"
              disabled={!selectedItems.length}
              onClick={() => setDeleteModal(true)}
            >
              Delete Items
            </CustomButton>

            <Link to={getPathname(`food/components/sort-items/${id}`)}>
                <CustomButton
                  className="btn-secondary"
                >
                Sort Items
              </CustomButton>
            </Link>

        </div>

        <Card className='mt-3'>
            <DataTableContainer 
                data={items?.list || []}
                columns={columns}
                paginationServer
                paginationTotalRows={items?.total}
                navigateToPage={navigateToPage}
                pageSize={localFilterValues.page_size}
                setPageSize={setPageSize}
                selectableRows
                handleClick={(row) => openUpdateModal(setInitialValues(row))}
                loading={itemsLoading || isRefetching}
                selectItems={setSelectedItems}
                modal={deleteModal}
                setModal={setDeleteModal}
                isDeleting={isDeleting}
                handleDelete={handleDelete}
            />
        </Card>
    </div>
  )
}

export default ComponentItems