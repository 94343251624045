import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Col, Row } from "reactstrap";
import { getSeoApi, setSeoApi } from "../../../apis/Settings/webSettingsApi";
import toastAlert from "../../../helpers/toastAlert";
import useActions from "../../../hooks/useActions";
import useCustomForm from "../../../hooks/useCustomForm";
import LoaderComponent from "../../Common/LoaderComponent";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import CustomTextArea from "../../Custom/CustomTextArea";
import SetupActions from "../SetupActions";

const SeoSetup = ({ id, next, skip }) => {

  const { values, handleChange, setValues } = useCustomForm({
    title: "",
    keywords: "",
    description: "",
  });

  const { onLoading, isLoading: isSubmitting, onFinally } = useActions();

  const { isLoading } = useQuery(["web-settings", "seo"], getSeoApi, {
    onSuccess(data) {
      setValues(data.data.data);
    },
  });

  const onSubmit = async () => {
    onLoading();
    try {
      await setSeoApi(values);
      next(id);
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      onFinally();
    }
  };

  return (
    <LoaderComponent isLoading={isLoading}>
      <Row className="gy-3">
        <Col md={6}>
          <CustomLabel>Title</CustomLabel>
          <CustomInput
            name="title"
            value={values.title}
            onChange={handleChange}
          />
        </Col>
        <Col md={6}>
          <CustomLabel>Keywords</CustomLabel>
          <CustomInput
            name="keywords"
            value={values.keywords}
            onChange={handleChange}
          />
        </Col>
        <Col xs={12}>
          <CustomLabel>Description</CustomLabel>
          <CustomTextArea
            rows={3}
            name="description"
            value={values.description}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <SetupActions id={id} submit={onSubmit} skip={skip} isLoading={isSubmitting} />
    </LoaderComponent>
  );
};

export default SeoSetup;
