import React from "react";
import { CardBody, Row } from "reactstrap";

const WebSettingsBody = ({ children, className = "gy-4" }) => {
  return (
    <CardBody>
      <Row className={className}>{children}</Row>
    </CardBody>
  );
};

export default WebSettingsBody;
