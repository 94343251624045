import axiosInstance from "..";

export const getAllBranchesApi = (query) => {
  return axiosInstance.get('/branches', {
    params: {
      lang: localStorage.getItem("I18N_LANGUAGE") || 'en',
      ...query
    }
  });
};

export const getOneBranchApi = (id, lang) => {
  return axiosInstance.get(`/branches/${id}?lang=${lang}`);
};

export const createBranchApi = (body, lang) => {
  return axiosInstance.post(`/branches?lang=${lang}`, body);
};

export const updateBranchApi = (id, body, lang) => {
  return axiosInstance.post(`/branches/${id}?lang=${lang}`, body);
};

export const updateBranchSettingsApi = (id, body) => {
  return axiosInstance.post(`/branches/update/${id}`, body);
};

export const copyBranchDefault = (id, body) => {
  return axiosInstance.post(`/branches/copy/${id}`, body);
};

export const deleteBranchesApi = (ids = []) => {
  return axiosInstance.delete("/branches", {
    data: {
      ids,
    },
  });
};
