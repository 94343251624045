import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import {
  addNewSliderApi,
  updateSliderApi,
} from "../../../apis/Settings/sliderApi";
import { formatExample } from "../../../helpers/formatExample";
import formatFormData from "../../../helpers/formatFormData";
import toastAlert from "../../../helpers/toastAlert";
import useActions from "../../../hooks/useActions";
import CustomButton from "../../Custom/CustomButton";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import CustomOption from "../../Custom/CustomOption";
import CreateSliderTabs from "./Create/Tabs";

const HomeSliderModal = ({isOpen, toggleModal, type, initialValues = null, createSlider, updateSlider}) => {

  const { register, handleSubmit, setValue, reset } = useForm();

  const { isLoading, onLoading, onFinally } = useActions();

  const [media, setMedia] = useState({
    url: "",
    file: null,
  });
  

  const submitForm = async (data) => {
    onLoading();
    let body = { ...data };
    if (media.file) {
      body.file = media.file;
    }
    const formData = formatFormData(body);
    try {
      if (type === "create") {
        const {data: { data: response }} = await addNewSliderApi(formData, 'en');
        createSlider(response);
        toggleModal();
      } else {
        const { data: response } = await updateSliderApi(initialValues.id, formData, 'en');
        if (response?.data?.file) {
          setMedia({
            ...response.data.file,
            url: response.data.file.link,
          });
        }
        updateSlider(initialValues.id, response.data)
        toastAlert({ type: 'success' });
        toggleModal();
      }
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      onFinally();
    }
  };

  const clearForm = () => {
    reset({
        area: '',
        title: '',
        sup_title: '',
        sub_title: '',
        active: '',
        text: '',
        link: '',
        ios_active: '',
        android_active: '',
    });
    setMedia({
      url: "",
      file: null,
    })
  }

  useEffect(() => {
    if (initialValues) {
      reset((() => {
          const { id, file, ...rest } = initialValues;
          reset(rest);
          if (file) {
            setMedia({ ...file, url: file.link })
          }
      })())
    } 
  }, [initialValues])

  return (
    <React.Fragment>
        <Modal
            centered 
            isOpen={isOpen}
            toggle={toggleModal}
            onClosed={clearForm}
            size='xl'
        >
            <ModalBody>
              <form onSubmit={handleSubmit(submitForm)}>
                  
                <Row className="gy-4 mt-1">
                  <Col md={6}>
                    <CustomLabel>Area</CustomLabel>
                    <select className="form-select" {...register("area")}>
                      <CustomOption value="top">Top</CustomOption>
                      <CustomOption value="bottom">Bottom</CustomOption>
                    </select>
                  </Col>

                  <Col md={6}>
                    <CustomLabel translatable>Title</CustomLabel>
                    <CustomInput 
                      type="text" {...register("title")} 
                      placeholder={formatExample("SUPER OFFER")}
                    />
                  </Col>

                  <Col md={6}>
                    <CustomLabel translatable>Sup Title</CustomLabel>
                    <CustomInput 
                      type="text" 
                      {...register("sup_title")} 
                      placeholder={formatExample("30% off of everything !!")}
                    />
                  </Col>

                  <Col md={6}>
                    <CustomLabel translatable>Sub Title</CustomLabel>
                    <CustomInput
                      type="text" 
                      {...register("sub_title")} 
                      placeholder={formatExample("Hurry up and order now.")}
                      />
                  </Col>

                  <Col md={6}>
                    <div className="form-check form-switch mb-2">
                      <input
                        {...register("active")}
                        className="form-check-input fs-16"
                        type="checkbox"
                        role="switch"
                        id="active"
                      />
                      <CustomLabel className="form-check-label" htmlFor="active">
                        Active
                      </CustomLabel>
                    </div>
                  </Col>
                </Row>

                <CreateSliderTabs
                  media={media}
                  setMedia={setMedia}
                  register={register}
                  setValue={setValue}
                />

                <Row className="mt-5">
                  <Col>
                    <CustomButton isLoading={isLoading} className="btn-success">
                      {type === "create" ? "Create" : "Update"}
                    </CustomButton>
                  </Col>
                </Row>
              </form>
            </ModalBody>
        </Modal>
    </React.Fragment>
    
  )
}

export default HomeSliderModal