import axiosInstance from "..";

export const getAllGaleriesApi = () => {
  return axiosInstance.get('/galleries', {
    params: {
      lang: localStorage.getItem("I18N_LANGUAGE") || 'en',
    }
  });
};

export const getOneGalleryApi = (id, lang) => {
  return axiosInstance.get(`/galleries/${id}?lang=${lang}`);
};

export const addNewGalleryApi = (body, lang) => {
  return axiosInstance.post(`/galleries?lang=${lang}`, body);
};

export const updateGalleryApi = (id, body, lang) => {
  return axiosInstance.post(`/galleries/${id}?lang=${lang}`, body);
};

export const deleteGalleriesApi = (ids = []) => {
  return axiosInstance.delete("/galleries", {
    data: {
      ids,
    },
  });
};

export const addImageToGallery = (id, data) => {
  return axiosInstance.post(`/galleries/addImageDetail/${id}`, data);
};

export const deleteImageFromGallery = (id) => {
  return axiosInstance.delete(`/galleries/imageDetail/${id}`);
};

