import React from "react";
import { Link } from "react-router-dom";
import { getPathname } from "../../Layouts/LayoutMenuData";
import CustomButton from "../Custom/CustomButton";
import Restricted from "../Guard/Restricted";
import CreateButton from "./Buttons/CreateButton";
import DeleteButton from "./Buttons/DeleteButton";

const ListHeader = ({
  openModal,
  isDisable,
  pathname,
  afterChildren,
}) => {
  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex gap-2">
        <Link to={getPathname(pathname)}>
          <CreateButton />
        </Link>
        <Restricted allowed={['super-admin', 'developer']}>
          <DeleteButton  onClick={openModal} disabled={isDisable}/>
        </Restricted>
        {afterChildren}
      </div>
      <div>
      </div>
    </div>
  );
};

export default ListHeader;
