import React, { useState } from 'react'
import CustomButton from '../Custom/CustomButton'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { readAllNotifications } from '../../redux/actions/notificationsActions';

const MarkAsSeen = () => {

    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const markAsSeen = async () => {
        setLoading(true);
        dispatch(readAllNotifications({
            onSuccess() {
                setLoading(false);
            },
            onError() {
                setLoading(false);
            }
        }))
    }

    const { t } = useTranslation();

  return (
    <div className="my-3 text-center">
        <CustomButton
            type="button"
            isLoading={loading}
            onClick={markAsSeen}
            className="btn btn-soft-info waves-effect waves-light"
        >
            {t("Mark all as seen")}
            {" "}
            <i className="ri-eye-line align-middle"></i>
        </CustomButton>
    </div>
  )
}

export default MarkAsSeen