import React, { useCallback, useEffect, useState } from "react";
import { GoogleMap, Marker, LoadScript, DirectionsRenderer } from "@react-google-maps/api";
import deliveryLocationImg from '../../../../assets/images/icons/delivery-location.png'
import userLocationImg from '../../../../assets/images/icons/user-location.png'
import shopLocationImg from '../../../../assets/images/icons/shop-location.png'
import { useSelector } from "react-redux";
import { isEmpty } from "../../../../helpers/objectFilter";
import Pusher from 'pusher-js';
import { useTranslation } from "react-i18next";

const TrackOrderMap = ({ shopLocation, apiKey, order }) => {

    const { t } = useTranslation();

    const [map, setMap] = useState(null);

    const [directions, setDirections] = useState(null);

    const [pusher, setPusher] = useState(null);

    const [channel, setChannel] = useState(null);

    const { keysSettings } = useSelector(state => state.settings);

    const [pusherConfig, setPusherConfig] = useState(null);

    const [centerCoords, setCenterCoords] = useState(null);

    const [shopCoords, setShopCoords] = useState(null);

    const [agentCoords, setAgentCoords] = useState(null);

    const [agentName, setAgentName] = useState(null);

    const [deliveryCoords, setDeliveryCoords] = useState(null);

    const onLoad = useCallback(function callback(map) {
        setMap(map)
    }, [])

    // Register pusher to listen for agent location change event

    useEffect(() => {

      if (!pusher && pusherConfig) {

        const pusher = new Pusher(pusherConfig?.app_key, {
          cluster: pusherConfig?.cluster,
          forceTLS: true,
          encrypted: true,
        });

        setPusher(pusher);
          
        if (pusher && !channel) {
          const channel = pusher.subscribe("restaurant-delivery");
          setChannel(channel);
          channel.bind("map-changed", (data) => {
            const message = data.message;
            if (message.orders.includes(order.id)) {
              setAgentCoords({
                lat: message.lat,
                lng: message.lang
              })
              if (!agentName) setAgentName(message.delivery_name);
            }
          });

        }

      }

      () => {
        channel?.unbind();
        pusher?.disconnect();
        setChannel(null);
        setPusher(null);
      }

    }, [pusher, pusherConfig])

    // Get Pusher Configurations
    
    useEffect(() => {
      if (!isEmpty(keysSettings) && pusherConfig === null) {
        setPusherConfig({
          app_key: keysSettings.pusher_web?.app_key ?? 'eu',
          cluster: keysSettings.pusher_web?.app_cluster || ''
        }) 
      }
    }, [keysSettings])


    // Get shop coords

    useEffect(() => {
      let location = { lat: 62.058473593872534, lng: 9.501845677652613 };

      if (shopLocation) {
          const coords = shopLocation.split(',');
          if (coords?.length === 2) {
            location = { lat: +coords[0], lng: +coords[1] };
          }
      }
      setShopCoords(location);
      setAgentCoords(location);
      setCenterCoords(location);

    }, [shopLocation])

    // Get Delivery address coords

    useEffect(() => {
      if (order.address?.lat_long) {
        const coords = order.address.lat_long.split(',');
        if (coords?.length === 2) setDeliveryCoords({ lat: +coords[0], lng: +coords[1] });
      }
    }, [order])


    // Get the directions based on shop, agent and delivery address locations

    useEffect(() => {
      if (window.google && shopCoords && agentCoords && deliveryCoords) {
        const directionsService = new window.google.maps.DirectionsService();

        directionsService.route({
          origin: shopCoords,
          destination: deliveryCoords,
          waypoints: [{ location: agentCoords }],
          travelMode: "DRIVING"
        }, (result, status) => {
          if (status === "OK") {
            setDirections(result);
          } else {
            console.error("Directions request failed:", status);
          }
        }
      );
      }
  }, [window.google, shopCoords, agentCoords, deliveryCoords]);

  if (!centerCoords) return <></>

  return (
    <LoadScript
        googleMapsApiKey={apiKey}
        onLoad={onLoad}
    >
        <GoogleMap
            mapContainerStyle={{
                height: "100vh",
                width: "100%",
            }}
            center={centerCoords}
            zoom={20}
            version="weekly"
        >

        {shopCoords && 
          <Marker 
            position={shopCoords} 
            icon={{
              url: shopLocationImg,
              scaledSize: window.google && new window.google.maps.Size(60, 60),        
            }}
            options={{
              label: {
                text: t('Shop'),
                className: 'google-maps-marker-label'
              }
            }}
          />
        }

        {agentCoords && 
          <Marker 
            position={agentCoords} 
            icon={{
              url: deliveryLocationImg,
              scaledSize: window.google && new window.google.maps.Size(60, 60),        
            }}
            options={{
              label: {
                text: agentName ?? t('Delivery Agent'),
                className: 'google-maps-marker-label'
              }
            }}
          />
        }

        {deliveryCoords && 
          <Marker 
            position={deliveryCoords} 
            icon={{
              url: userLocationImg,
              scaledSize: window.google && new window.google.maps.Size(60, 60),        
            }}
            options={{
              label: {
                text: `${t('Client at')} ${order.address.address}`,
                className: 'google-maps-marker-label'
              }
            }}
          />
        }

        { directions && 
          <DirectionsRenderer directions={directions} 
            options={{
              suppressMarkers: true
            }} 
          />
         }


        </GoogleMap>
    </LoadScript>
  );
};

export default TrackOrderMap;
