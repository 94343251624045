import React from 'react'
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import CustomLabel from '../../../../../Custom/CustomLabel';

const OptionsSelection = ({selectedItems, setSelectedItems}) => {

  const {
    options: {list: options}, 
    allergy: {list: allergy}, 
  } = useSelector(state => state.food);

  const handleChange = (field, id) => {
    setSelectedItems({
      ...selectedItems,
      [field]: selectedItems[field].map(item => {
        if (item.id === id) return {
          ...item,
          selected: !item.selected
        }
        return item
      })
    })
  }

  return (
    <Row>
      <Col>
        <CustomLabel className="fs-16">Options</CustomLabel>
        <div className='selectable-tabs-container'>
          {options && options.map(option => (
            <div
              key={option.id} 
              className={`selectable-tab option-tab ${selectedItems.options?.find(opt => opt.id === option.id)?.selected ? 'selected-tab' : ''} py-1`}
              onClick={() => handleChange('options', option.id)}
            >
              {option.icon && <img src={option.icon} alt={option.icon} />}
              {option.name}
            </div>
          ))}
        </div>
      </Col>
      <Col>
        <CustomLabel className="fs-16">Allergy</CustomLabel>
        <div className='selectable-tabs-container'>
          {allergy && allergy.map(aller => (
            <div
              key={aller.id} 
              className={`selectable-tab allergy-tab ${selectedItems.allergies?.find(al => al.id === aller.id)?.selected ? 'selected-tab' : ''} py-1`}
              onClick={() => handleChange('allergies', aller.id)}
            >
              {aller.icon && <img src={aller.icon} alt={aller.icon}/>}
              {aller.code} - {aller.name}
            </div>
          ))}
        </div>
      </Col>
    </Row>
  )
}

export default OptionsSelection