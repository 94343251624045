import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import defaultAvatar from "../../../assets/images/users/default.jpg";
import { useTranslation } from "react-i18next";

const TopCustomers = ({data}) => {
  
  const [trending, setTrending] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      setTrending(data.top_users)
    }
  }, [data])

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">{t('Top Customers')}</h4>
        </CardHeader>
        <CardBody className="trending-menus-list p-0">
          <ul className="list-group list-group-flush border-dashed mb-0">
            {(trending || []).map((item, key) => (

              <li
                className="list-group-item d-flex align-items-center gap-2"
                key={key}
              >

                <Link
                  to={getPathname(`users/update/${item.id}`)}
                  target="_blank"
                >
                  <div className="flex-shrink-0">
                    <img
                      src={item.image?.link || defaultAvatar}
                      className="avatar-xs rounded-circle"
                      alt=""
                    />
                  </div>
                </Link>

                <div className="flex-grow-1">
                  <Link
                    to={getPathname(`users/update/${item.id}`)}
                    target="_blank"
                  >
                    <h6 className="fs-14 mb-1 text-capitalize text-truncate" style={{maxWidth: '100px'}} title={item.name}>{item.name}</h6>
                    <p className="text-muted mb-0 fs-13">{
                      item.orders_count ? `${t('Total Orders')}: ${item.orders_count} ` : ''}
                    </p>
                  </Link>
                </div>

                <div className="flex-shrink-0 text-end">
                  <h6 className="fs-15 mb-1">#{key + 1}</h6>
                </div>

              </li>

            ))}
          </ul>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default TopCustomers;
