import React from "react";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";


const CustomLabel = ({ translatable, className, children, ...rest }) => {
  
  const { t } = useTranslation();

  return <div className="d-flex align-items-center gap-2 mb-2">

    <Label {...rest} className={`mb-0 ${className}`}>
        {typeof children === 'string' ? t(children) : children}
    </Label>

    {translatable && 
        <i title="Translatable" className="ri-translate fs-15"></i>
    }
  </div>;
};

export default CustomLabel;
