import axiosInstance from "..";

export const getAllLanguagesApi = (query) => {
  return axiosInstance.get("/languages", {
    params: query
  });
};

export const getOneLanguageApi = (code) => {
  return axiosInstance.get(`/languages/${code}`);
};

export const addNewLanguageApi = (body) => {
  return axiosInstance.post("/languages", body);
};

export const updateLanguageApi = (code, body) => {
  return axiosInstance.patch(`/languages/${code}`, body);
};

export const deleteLangaugeApi = (code) => {
  return axiosInstance.delete(`/languages/${code}`);
};

export const getAllKeysApi = (whatFor) => {
  return axiosInstance.get(`${whatFor}/langkey`);
};

export const addNewKeyApi = (whatFor, code, body) => {
  return axiosInstance.post(`${whatFor}/langkey/${code}`, body);
};

export const updateKeyApi = (whatFor, code, body) => {
  return axiosInstance.patch(`${whatFor}/langkey/${code}`, body);
};

export const deleteKeyApi = (whatFor, key) => {
  return axiosInstance.delete(`${whatFor}/langkey`, {
    data: {
      key,
    },
  });
};
