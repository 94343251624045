import axiosInstance from "..";

export const getAllTableServices = (params) => {
  return axiosInstance.get("/table_services", {
    params
  });
};

export const getOneTableService = (id) => {
  return axiosInstance.get(`/table_services/${id}`);
};

export const createTableService = (data) => {
  return axiosInstance.post(`/table_services`, data);
};

export const updateTableService = (id, data) => {
    return axiosInstance.post(`/table_services/${id}`, data);
};

export const deleteTableService = (data) => {
    return axiosInstance.delete(`/table_services`, {
        data
    });
};

