import React from 'react'
import CustomLabel from '../../../Custom/CustomLabel';
import { Row, Col } from 'reactstrap'

const BranchDeliverySettings = ({ register }) => {

  return (
    <Row>
        
        <Col lg={3}>
          <div className="form-check form-switch">
            <input
              name="active"
              className="form-check-input fs-16"
              type="checkbox"
              role="switch"
              {...register("meny_close_cart")}
            />
            <CustomLabel>Close all websites</CustomLabel>
          </div>
        </Col>

        <Col lg={3}>
            <div className="form-check form-switch">
                <input
                    name="active"
                    className="form-check-input fs-16"
                    type="checkbox"
                    role="switch"
                    {...register("settings.open_order")}
                />
                <CustomLabel>Open Order</CustomLabel>
            </div>
        </Col>

        <Col lg={3}>
            <div className="form-check form-switch">
                <input
                    name="active"
                    className="form-check-input fs-16"
                    type="checkbox"
                    role="switch"
                    {...register("settings.select_order_time")}
                />
                <CustomLabel>Open select order time</CustomLabel>
            </div>
        </Col>

        <Col lg={3}>
            <div className="form-check form-switch">
                <input
                    name="active"
                    className="form-check-input fs-16"
                    type="checkbox"
                    role="switch"
                    {...register("settings.qr_code_order")}
                />
                <CustomLabel>Active QR Code</CustomLabel>
            </div>
        </Col>
            
        <Col lg={3}>
            <div className="form-check form-switch">
                <input
                    name="active"
                    className="form-check-input fs-16"
                    type="checkbox"
                    role="switch"
                    {...register("settings.takeaway")}
                />
                <CustomLabel>Open takeaway</CustomLabel>
            </div>
        </Col>

        <Col lg={3}>
            <div className="form-check form-switch">
                <input
                    name="active"
                    className="form-check-input fs-16"
                    type="checkbox"
                    role="switch"
                    {...register("settings.delivery")}
                />
                <CustomLabel>Open delivery</CustomLabel>
            </div>
        </Col>

    </Row>
  )
}

export default BranchDeliverySettings