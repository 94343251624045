import React, { useEffect, useState } from 'react'
import PageContainer from '../../../Components/Layout/PageContainer'
import { Card, CardBody, CardHeader } from 'reactstrap';
import Nestable from 'react-nestable';
import { foodApi } from '../../../apis/Food/api';
import toastAlert from '../../../helpers/toastAlert';
import CustomButton from '../../../Components/Custom/CustomButton';
import { useParams } from 'react-router';
import { useQueries } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import Text from '../../../Components/Custom/Text';

const SortComponentItems = () => {

    const { id } = useParams();

    const [component, setComponent] = useState(null);

    const [items, setItems] = useState(null);

    const { activeLang } = useSelector(state => state.language)

    const [sortedList, setSortedList] = useState(null);

    const [submitting, setSubmitting] = useState(false);

    const [{ refetch: refetchComponent }, { refetch: refetchItems }] = useQueries({
        queries: [
          {
            queryKey: ['one-component'],
            enabled: false,
            queryFn: () => foodApi.components.getOne(id, activeLang),
            onSuccess({data: { data }}) {
                setComponent(data)
            }
          },
          {
            queryKey: ['all-component-items'],
            enabled: false,
            queryFn: () => foodApi.componentItems.getAll({
                component_id: id,
                page_size: 'all',
                lang: activeLang
            }),
            onSuccess({data: { data }}) {
                setItems(data);
            }
          },
    
        ],
    });

    const submitForm = async () => {
        setSubmitting(true);
        const requestBody = sortedList?.map((item, index) => ({
            id: item.id,
            sort: index + 1
        }))
        try {
            await foodApi.sortItems({
                type: 'component_items',
                items: requestBody
            });
            toastAlert({ type: 'success' });
        } catch (error) {
            toastAlert({ type: 'error' });
        } finally {
            setSubmitting(false);
        }
    }

    useEffect(() => {
        if (items?.length) {
        setSortedList(items?.map(item => ({
            id: item.id,
            name: item.name,
            image: item.icon || null,
            class: 'nested-1'
        })))
        }
    }, [items])

    useEffect(() => {
        if (activeLang) {
            refetchComponent();
            refetchItems();
        }
    }, [activeLang])

  return (
    <PageContainer>
       <Card>
          <CardHeader className='d-flex align-items-center justify-content-between'>
              <span className='fs-18 fw-bold'><Text>Sort Items</Text> ({component?.name})</span>

              <div className='hstack gap-2'>
                <CustomButton
                  type="button"
                  className="btn btn-dark"
                  onClick={() => window.history.go(-1)}
                >
                    Back
                </CustomButton>

                <CustomButton
                  type="button"
                  className="btn btn-success"
                  onClick={submitForm}
                  isLoading={submitting}
                >
                    Save
                </CustomButton>
              </div>
              
          </CardHeader>

          <CardBody>
              {sortedList && (
                <Nestable
                  items={sortedList}
                  renderItem={({item}) => {
                    return (
                        <div className="item-title-container">
                            {item.image && <img src={item.image} alt={item.image} />}
                            <span>{item.name}</span>
                        </div>
                    )
                  }}
                  onChange={({ items }) => {
                    setSortedList(items);
                  }}
                  maxDepth={1}
                />
              )}
          </CardBody>
       </Card>
    </PageContainer>
  )
}

export default SortComponentItems