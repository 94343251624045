import axiosInstance, { axiosInstanceV2 } from "..";

export const getPaymentSettings = () => {
  return axiosInstance.get("/order_payment/methods");
};

export const setPaymentSettings = (data) => {
  return axiosInstance.post(`/order_payment/methods`, data);
};

export const setPaymentSettingsLogo = (id, data) => {
  return axiosInstance.post(`/order_payment/methods/${id}`, data);
};

export const getCurrencies = () => {
  return axiosInstance.get(`/settings/currency`);
}

export const getVippsWebhook = () => {
  return axiosInstanceV2.get('/payment/vipps/payement/webhook');
}

export const fixVippsWebhook = (data) => {
  return axiosInstanceV2.post("/payment/vipps/payement/webhook", data);
}

export const deleteVippsWebhook = (data) => {
  return axiosInstanceV2.delete("/payment/vipps/payement/webhook", {
    data
  });
}

export const getStripeWebhooks = () => {
  return axiosInstance.get('/payment/strip/webhooks');
}

export const createStripeWebhooks = (data) => {
  return axiosInstance.post("/payment/strip/webhooks", data);
}

export const deleteStripeWebhooks = (data) => {
  return axiosInstance.post("/payment/strip/delete_webhooks", data);
}