import React, { useEffect, useState } from 'react'
import PageContainer from '../../../Components/Layout/PageContainer'
import { useDispatch, useSelector } from 'react-redux'
import { getFoodDataAction } from '../../../redux/actions/foodActions';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Nestable from 'react-nestable';
import { foodApi } from '../../../apis/Food/api';
import toastAlert from '../../../helpers/toastAlert';
import CustomButton from '../../../Components/Custom/CustomButton';
import Text from '../../../Components/Custom/Text';

const SortCategories = () => {

  const dispatch = useDispatch();

  const [sortedList, setSortedList] = useState(null);

  const [submitting, setSubmitting] = useState(false);

  const { categories: { list: categories } } = useSelector(state => state.food);

  const submitForm = async () => {
    setSubmitting(true);
    const requestBody = sortedList?.map((item, index) => ({
      id: item.id,
      sort: index + 1
    }))
    try {
      await foodApi.sortItems({
        type: 'categories',
        items: requestBody
      });
      toastAlert({ type: 'success' });
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      setSubmitting(false);
    }

  }

  useEffect(() => {
    if (categories?.length) {
      setSortedList(categories.map(category => ({
        id: category.id,
        name: category.name,
        image: category.icon || null,
        class: 'nested-1'
      })))
    }
  }, [categories])

  useEffect(() => {
    dispatch(getFoodDataAction({
      field: 'categories',
      params: {
        page_size: 'all'
      }
    }))
  }, [])

  return (
    <PageContainer>
       <Card>
          <CardHeader className='d-flex align-items-center justify-content-between'>
              <span className='fs-18 fw-bold'><Text>Sort Categories</Text></span>

              <div className='hstack gap-2'>
                <CustomButton
                  type="button"
                  className="btn btn-dark"
                  onClick={() => window.history.go(-1)}
                >
                    Back
                </CustomButton>

                <CustomButton
                  type="button"
                  className="btn btn-success"
                  onClick={submitForm}
                  isLoading={submitting}
                >
                    Save
                </CustomButton>
              </div>

          </CardHeader>

          <CardBody>
              {sortedList && (
                <Nestable
                  items={sortedList}
                  renderItem={({item}) => {
                    return (
                      <div className="item-title-container">
                          {item.image && <img src={item.image} alt={item.image} />}
                          <span>{item.name}</span>
                      </div>
                    )
                  }}
                  onChange={({ items }) => {
                    setSortedList(items);
                  }}
                  maxDepth={1}
                />
              )}
          </CardBody>
       </Card>
    </PageContainer>
  )
}

export default SortCategories