import React from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from 'reactstrap'
import CustomLabel from '../../../../../Custom/CustomLabel';
import CustomInput from '../../../../../Custom/CustomInput';
import Checkbox from 'react-three-state-checkbox';

const SizesTable = ({item, categoryId, setData}) => {

    const { t } = useTranslation();

    const handleSelectAll = () => {
        setData(prev => prev.map(category => {
            if (category.category_id === categoryId) return {
                ...category,
                entrees: category.entrees.map(entree => {
                    if (entree.has_sizes && entree.id === item.id) return {
                        ...entree,
                        entrees_sizes: entree.entrees_sizes.map(size => ({
                            ...size,
                            selected: !entree.entrees_sizes.every(size => size.selected),
                            default_size: entree.entrees_sizes.every(size => size.selected) ? false : size.default_size
                        }))
                    }
                    return entree
                })
            }
            return category
        }))
    }

    const handleSizeChange = (itemId, sizeId, field, value) => {
        setData(prev => prev.map(category => {
            if (category.category_id === categoryId) return {
                ...category,
                entrees: category.entrees.map(item => {
                    if (item.id === itemId) return {
                        ...item,
                        entrees_sizes: item.entrees_sizes.map(size => {
                            if (size.size_id === sizeId) return {
                                ...size,
                                [field]: value,
                                ...(field === 'selected' ? 
                                {
                                    default_size: value ? size.default_size : false
                                }
                                : {})
                            }
                            return size
                        })
                    }
                    return item
                })
            }
            return category
        }))
    }

    
    const handleCheckChange = (itemId, value) => {
        setData(prev => prev.map(category => {
            if (category.category_id === categoryId) return {
                ...category,
                entrees: category.entrees.map(item => {
                    if (item.id === itemId) return {
                        ...item,
                        entrees_sizes: item.entrees_sizes.map(size => ({
                            ...size,
                            default_size: size.default_size ? false : +size.size_id === +value
                        }))
                    }
                    return item
                })
            }
            return category
        }))
    }

  return (
    <Table>

        <thead>
            <tr>
                <th>
                    <Checkbox
                        className="form-check-input"
                        checked={item.entrees_sizes?.every((size) => size.selected)}
                        indeterminate={item.entrees_sizes.some((size) => size.selected) && !item.entrees_sizes.every((size) => size.selected)}
                        onChange={() => {
                            if (item.selected) handleSelectAll();
                        }}
                        disabled={!item.selected}
                    />
                </th>

                <th>
                    {t("Size")}
                </th>

                <th>
                    {t("Default")}
                </th>

                <th>{t("Price")}</th>

            </tr>
        </thead>

        <tbody>
            {item.entrees_sizes.map(size => (
                <tr key={size.size_id}>
                    <td>
                        <input
                            type="checkbox"
                            id={`selected-size-${size.size_id}`}
                            className="form-check-input"
                            checked={size.selected}
                            onChange={e => {
                                if (item.selected) {
                                    handleSizeChange(item.id, size.size_id, 'selected', e.target.checked)
                                }
                            }}
                            disabled={!item.selected}
                        />
                    </td>

                    
                    <td>

                        <CustomLabel 
                            className="form-check-label mb-0" 
                            htmlFor={`default-size-${size.size_id}`}
                        >
                            {size.size}
                        </CustomLabel>
                    </td>

                    <td className='form-check form-switch'>
                        <input 
                            className="form-check-input fs-16" 
                            type="checkbox" 
                            role="switch" 
                            id={`default-size-${size.size_id}`} 
                            checked={item.selected && size.default_size}
                            onChange={() => {
                                if (item.selected && size.selected) handleCheckChange(item.id, size.size_id)
                            }}
                            disabled={!item.selected || !size.selected}
                        />
                    </td>

                    <td>
                        <CustomInput
                            placeholder="Price"
                            value={size.price ?? ''}
                            onChange={e => {
                                if (item.selected && size.selected) handleSizeChange(item.id, size.size_id, 'price', e.target.value)
                            }}
                            disabled={!item.selected || !size.selected}
                        />
                    </td>

                </tr>
            ))}

        </tbody>
    </Table>
  )
  
}

export default SizesTable