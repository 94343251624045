import * as yup from "yup";

export const updateUserSchema = yup.object().shape({
  username: yup.string().required("Required"),
  name: yup.string().required("Required"),
  email: yup
    .string()
    .required("Required")
    .email("Please provide a valid email"),
  phone: yup.string().required("Required"),
});

export const createUserSchema = updateUserSchema.shape({
  password: yup
    .string()
    .required("Required")
    .min(8, "Password must be at least 8 characters"),
});
