import * as yup from "yup";

const couponSchema = (discount) => {

  return yup.object().shape({
    code: yup.string().required("Required"),
    ...(discount === 'discount_rate' ? {
      discount_rate: yup.string().required("Required"),
    } : {
      qr_amount: yup.string().required("Required"),
    }),
    count_use: yup.string().required("Required"),
    min_price: yup.string().required("Required"),
    start_date: yup.string().required("Required"),
    end_date: yup.string().required("Required"),
    start_time: yup.string().required("Required"),
    end_time: yup.string().test(
      "same_dates_test",
      "Start and end time cannot be equal",
      function () {
        const { start_time, end_time } = this.parent;
        return start_time !== end_time;
      }
    ),
  });
}

export default couponSchema
