import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
} from "reactstrap";
import PageContainer from "../../Components/Layout/PageContainer";
import classnames from "classnames";
import FooterTab from "../../Components/Settings/WebSettings/FooterTab";
import SocialMediaTab from "../../Components/Settings/WebSettings/SocialMediaTab";
import SeoTab from "../../Components/Settings/WebSettings/SeoTab";
import AppTab from "../../Components/Settings/WebSettings/AppTab";
import ContactTab from "../../Components/Settings/WebSettings/ContactTab";
import SetupTab from "../../Components/Settings/WebSettings/SetupTab";
import OpeningHoursTab from "../../Components/Settings/WebSettings/OpeningHoursTab";
import { useTranslation } from "react-i18next";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import ColorsTab from "../../Components/Settings/WebSettings/ColorsTab";
import GlobalSettingsTab from "../../Components/Settings/WebSettings/GlobalSettingsTab";

const TABS = [
  {
    name: "Footer",
    children: <FooterTab />,
  },
  {
    name: "Social Media",
    children: <SocialMediaTab />,
  },
  {
    name: "Setup",
    children: <SetupTab />,
  },
  {
    name: "Contact",
    children: <ContactTab />,
  },
  {
    name: "Opening Hours",
    children: <OpeningHoursTab />,
  },
  {
    name: "SEO",
    children: <SeoTab />,
  },
  {
    name: "App",
    children: <AppTab />,
  },
  {
    name: "Colors",
    children: <ColorsTab />,
  },
  {
    name: "Global Settings",
    children: <GlobalSettingsTab />,
  },
];

const WebSettings = () => {
  
  const [activeTab, setActiveTab] = useState(1);

  const { t } = useTranslation();

  const toggleActiveTab = (tab) => {
    setActiveTab(tab);
  };

  return (
    <PageContainer>
      <BreadCrumb pageTitle="Settings" title="Web Settings"/>
      <Card>
        <CardHeader>
          <Nav className="nav-tabs-custom card-header-tabs border-bottom-0">
            {TABS.map((tab, index) => {
              const i = index + 1;
              return (
                <NavItem key={tab.name}>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === i,
                    })}
                    onClick={() => {
                      toggleActiveTab(i);
                    }}
                  >
                    {t(tab.name)}
                  </NavLink>
                </NavItem>
              );
            })}
          </Nav>
        </CardHeader>
        <TabContent activeTab={activeTab}>
          {TABS.map((tab, index) => {
            const i = index + 1;
            return (
              <TabPane key={tab.name} tabId={i}>
                {tab.children}
              </TabPane>
            );
          })}
        </TabContent>
      </Card>
    </PageContainer>
  );
};

export default WebSettings;
