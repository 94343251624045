import React, { useState } from "react";
import { Button } from "reactstrap";

const LanguageKey = ({ index, word, onEditKey, onDeleteKey }) => {
  const [value, setValue] = useState(word.value);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(word.key)
  }

  return (
    <tr>
      <td>{index + 1}</td>
      <td onClick={copyToClipboard} className="cursor-pointer">{word.key}</td>
      <td>
        <input
          type="text"
          value={value}
          onChange={handleChange}
          className="form-control"
        />
      </td>
      <td>
        <Button
          className="rounded-pill btn-soft-success me-2"
          onClick={() => onEditKey(word.key, value)}
        >
          <i className="ri-file-edit-line"></i>
        </Button>

        <Button className="rounded-pill  btn-soft-danger" onClick={onDeleteKey}>
          <i className="ri-delete-bin-line"></i>
        </Button>
      </td>
    </tr>
  );
};

export default LanguageKey;
