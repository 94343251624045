import React from "react";
import CommonForm from "./CommonForm";

const ReadyTab = ({ values, handleChange }) => {
  return (
    <CommonForm
      handleChange={handleChange}
      values={values}
      name="ready"
      checkboxName="ready_send_sms"
      inputName="ready_sms_text"
      keywordsName="ready_sms_keywords"
    />
  );
};

export default ReadyTab;
