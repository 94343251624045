import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { CardBody, Col, Row } from "reactstrap";
import {
  getSetupApi,
  getTimeZones,
  setSetupApi,
} from "../../../apis/Settings/webSettingsApi";
import formatFormData from "../../../helpers/formatFormData";
import toastAlert from "../../../helpers/toastAlert";
import useActions from "../../../hooks/useActions";
import useCustomForm from "../../../hooks/useCustomForm";
import useStateFile from "../../../hooks/useStateFile";
import { settingsActions } from "../../../redux/slices/settingsSlice";
import LoaderComponent from "../../Common/LoaderComponent";
import SelectFile from "../../Common/SelectFile";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import WebSettingsFooter from "./WebSettingsFooter";

const SetupTab = () => {

  const { values, handleChange, setValues } =
    useCustomForm({
      name: "",
      link: "",
      google_analitics: "",
      google_rank: "",
      country_code: "",
      time_zone: "",
      active_color: "",
      active_font_color: "",
      active_select_box_color: "",
    });

  const [timeZones, setTimeZones] = useState(null);

  const dispatch = useDispatch();

  const { file: logo, setFile: setLogo } = useStateFile();
  const { file: footer_logo, setFile: setFooterLogo } = useStateFile();
  const { file: fav_icon, setFile: setFavIcon } = useStateFile();
  const { file: product, setFile: setProduct } = useStateFile();
  const { file: coupon, setFile: setCoupon } = useStateFile();

  const { isLoading: isSubmitting, onLoading, onFinally } = useActions();

  const { isLoading } = useQuery(["web-settings", "setup"], getSetupApi, {
    onSuccess(data) {
      const {
        name,
        link,
        logo,
        google_analitics,
        google_rank,
        footer_logo,
        fav_icon,
        product,
        coupon,
        time_zone,
        country_code,
      } = data.data.data;
      dispatch(
        settingsActions.setSetup({
          name,
          link,
          country_code,
          logo: logo?.link,
          fav_icon: fav_icon?.link,
        })
      );
      setValues({ 
        name,
        link,
        country_code,
        google_analitics: google_analitics || "", time_zone, google_rank: google_rank || "", 
      });
      setLogo({ ...logo, url: logo?.link });
      setFooterLogo({ ...footer_logo, url: footer_logo?.link });
      setFavIcon({ ...fav_icon, url: fav_icon?.link });
      setProduct({ ...product, url: product?.link });
      setCoupon({ ...coupon, url: coupon?.link });
    },
  });

  useQuery(["timezones"], getTimeZones, {
    onSuccess({data: { data }}) {
      setTimeZones(data);
    },
  });

  const onSubmit = async () => {
    onLoading();
    let files = {};
    const listFiles = [
      { name: "logo", file: logo },
      { name: "footer_logo", file: footer_logo },
      { name: "fav_icon", file: fav_icon },
      { name: "product", file: product },
      { name: "coupon", file: coupon },
    ];
    listFiles.forEach((file) => {
      if (file.file.file) {
        files[file.name] = file.file.file;
      }
    });
    const { ...rest } = values;
    const body = {
      ...rest,
      ...files,
    };
    const formData = formatFormData(body);
    try {
      const { data } = await setSetupApi(formData);
      const {
        name,
        link,
        logo,
        google_analitics,
        google_rank,
        time_zone,
        footer_logo,
        fav_icon,
        product,
        coupon,
        country_code,
      } = data.data;
      setValues({ name, google_analitics: google_analitics || "", time_zone, google_rank: google_rank || "" });
      setLogo({ ...logo, url: logo?.link });
      setFooterLogo({ ...footer_logo, url: footer_logo?.link });
      setFavIcon({ ...fav_icon, url: fav_icon?.link });
      setProduct({ ...product, url: product?.link });
      setCoupon({ ...coupon, url: coupon?.link });

      dispatch(
        settingsActions.setSetup({
          name,
          link,
          country_code,
          logo: logo?.link,
          fav_icon: fav_icon?.link,
        })
      );
      toastAlert({ type: 'success' });
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      onFinally();
    }
  };

  return (
    <LoaderComponent isLoading={isLoading}>

      <CardBody>
        <Row className="gy-2">

          <Col md={6}>
            <CustomLabel>Website Name</CustomLabel>
            <CustomInput
              name="name"
              value={values.name}
              onChange={handleChange}
            />
          </Col>

          <Col md={6}>
            <CustomLabel>Website Link</CustomLabel>
            <CustomInput
              name="link"
              value={values.link}
              onChange={handleChange}
            />
          </Col>

          <Col md={6}>
            <CustomLabel>Country Code</CustomLabel>
            <CustomInput
              name="country_code"
              value={values.country_code}
              onChange={handleChange}
            />
          </Col>

          <Col md={6}>
            <CustomLabel>Timezone</CustomLabel>
            <select
              className="form-select"
              name="time_zone"
              value={values.time_zone}
              onChange={handleChange}
            >
              <option value=""></option>
              {timeZones?.map((timezone) => {
                return (
                  <option key={timezone.id} value={timezone.name}>
                    {timezone.name}
                  </option>
                );
              })}
            </select>
          </Col>

          <Col md={6}>
            <CustomLabel>Google Analitics</CustomLabel>
            <CustomInput
              name="google_analitics"
              value={values.google_analitics}
              onChange={handleChange}
            />
          </Col>

          <Col md={6}>
            <CustomLabel>Google Rank</CustomLabel>
            <CustomInput
              name="google_rank"
              value={values.google_rank}
              onChange={handleChange}
            />
          </Col>

        </Row>

        <Row className="mt-3 gy-4">

          <Col lg={2} md={3}>
            <CustomLabel>Logo</CustomLabel>
            <SelectFile
              type="image"
              max={1}
              display
              selectedFiles={logo}
              setSelectedFiles={setLogo}
            />
          </Col>

          <Col lg={2} md={3}>
            <CustomLabel>Footer logo</CustomLabel>
            <SelectFile
              type="image"
              max={1}
              display
              selectedFiles={footer_logo}
              setSelectedFiles={setFooterLogo}
            />
          </Col>

          <Col lg={2} md={3}>
            <CustomLabel>Fav icon</CustomLabel>
            <SelectFile
              type="image"
              max={1}
              display
              selectedFiles={fav_icon}
              setSelectedFiles={setFavIcon}
            />
          </Col>

          <Col lg={2} md={3}>
            <CustomLabel>Product</CustomLabel>
            <SelectFile
              type="image"
              max={1}
              display
              selectedFiles={product}
              setSelectedFiles={setProduct}
            />
          </Col>

          <Col lg={2} md={3}>
            <CustomLabel>Coupon</CustomLabel>
            <SelectFile
              type="image"
              max={1}
              display
              selectedFiles={coupon}
              setSelectedFiles={setCoupon}
            />
          </Col>

        </Row>
      </CardBody>

      <WebSettingsFooter isSubmitting={isSubmitting} onSubmit={onSubmit} />

    </LoaderComponent>
  );
};

export default SetupTab;
