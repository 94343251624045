import QRCode from 'qrcode';
import i18n from "../i18n";
import store from '../redux';
import { imageToDataUrl } from './imageToDataUrl';
import selectBorder from '../assets/images/select.png'

export const  printQRCode = async (url, name) => {

    const defaultOptions = {
        scale: 10,
        color: {
          dark: '#000000',
        }
    };
      
    const { scale, color } = { ...defaultOptions };

    const canvas = await QRCode.toCanvas(url, { scale, color });

    const urlData = canvas.toDataURL();

    const win = window.open('', 'Test test', 'height=1000, width=1000');

    const { settings: { setup } } = store.getState();

    const logo = await imageToDataUrl(setup.logo);

    win.document.write(
        /*html*/`
        <html>

            <head>
                <title>${i18n.t('Table')} ${name}</title>
                <link rel="preconnect" href="https://fonts.googleapis.com">
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                <link href="https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap" rel="stylesheet">

                <style>
                    
                    * {
                        margin: 0;
                        padding: 0;
                        box-sizing: border-box;
                        font-family: 'Bebas Neue', sans-serif;
                    }

                    body { 
                        display: grid;
                        place-items: center;
                        height: 100Vh;
                    }

                    .container {
                        width: 60%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .title {
                        min-height: 120px;
                        min-width: 120px;
                        padding: .3em;
                        aspect-ratio: 1;
                        border-radius: 50%;
                        background-color: rgb(9, 9, 10);
                        font-size: 4rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: white;
                        font-weight: 900;
                    }

                    .qr-code-container {
                        width: 500px; 
                        height: 500px;
                        position: relative;
                        isolation: isolate;
                    }

                    .qr-code-image {
                        height: 100%;
                        width: 100%;
                        z-index: -1;
                        aspect-ratio: 1;
                    }  

                    .qr-code-logo {
                        position: absolute;
                        width: 100px;
                        aspect-ratio: 1;
                        background-color: black;
                        overflow: hidden;
                        border-radius: 1rem;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .qr-code-logo img {
                        width: 80%;
                        height: 80%;
                        object-fit: contain;
                    }

                    .qr-code-border {
                        position: absolute;
                        inset: 0;
                    }

                    .qr-code-border img {
                        width: 100%;
                        height: 100%;
                    }

                    .info {
                        margin-top: 2rem;
                    }

                    .info {
                        font-size: 3rem;
                        font-weight: 900;
                        text-align: center;
                    }

                </style>

            </head>

            <body>
                <div class="container">

                    <div class="title">
                        ${name}
                    </div>

                    <div class="qr-code-container" >
                        <img 
                            class="qr-code-image" 
                            src="${urlData}"
                            onload="window.print(); window.close()"
                        >

                        ${
                            !!logo && /*html*/`
                                <div class="qr-code-logo">
                                    <img src="${logo}" alt="${setup.name}" />
                                </div>
                            `
                        }

                        <div class="qr-code-border">
                            <img src="${selectBorder}" />
                        </div>
                    </div>

                    <h3 class="info">
                        SCAN THE QR CODE TO ORDER
                    </h3>
                </div>
            </body>

        </html>
    `);

}
  