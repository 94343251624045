import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
//i18n
import i18n from "../../i18n";
import { useSelector } from "react-redux";
import { getCountryFlag } from "../../helpers/getCountryFlag";

const SelectLanguage = ({language, onChange}) => {

  const { languages } = useSelector((state) => state.language);

  const [langFlags, setLangFlags] = useState([]);

  const lang = languages.find((lang) => lang.code === language) || languages?.[0];


  const [isLanguageDropdown, setIsLanguageDropdown] = useState(false);

  const toggleLanguageDropdown = () => {
    setIsLanguageDropdown(!isLanguageDropdown);
  };

  const fetchLangFlag = async () => {
      const data = await Promise.all(languages.map(async (lang) => {
        try {
          const image = await getCountryFlag(lang.country_code);
          return {
            code: lang.code,
            flag: image.default
          }
        } catch (error) {
          return {
            code: lang.code,
            flag: null
          }
        }
      })
      )
      setLangFlags(data);
  }

  useEffect(() => {
    if (languages.length) {
      fetchLangFlag()
    }
  }, [lang])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isLanguageDropdown}
        toggle={toggleLanguageDropdown}
      >
        <DropdownToggle
          className="btn btn-icon btn-ghost-secondary rounded-circle"
          tag="button"
          type="button"
        >
          {lang ?
            (() => {
                const language = langFlags.find(language => language.code === lang.code);
                if (language?.flag) {
                  return (
                    <img
                      src={language?.flag}
                      alt={lang.code}
                      height="20"
                      className="rounded"
                    />
                  )
                } else if (language?.flag === null) return lang.code
            })()
          : null
          }
        </DropdownToggle>
        <DropdownMenu >
          {languages.map((language) => {
            return (
              <DropdownItem
                key={language.code}
                onClick={() => onChange(language.code)}
                className={`notify-item ${
                    language === language.code ? "active" : "none"
                }`}
              >
                {(() => {
                  const ln = langFlags.find(ln => ln.code === language.code);
                  if (ln?.flag) return (
                    <img
                      src={ln?.flag}
                      alt=""
                      className="me-2 rounded"
                      height="18"
                    />
                  ) 
                })()}
                <span className="align-middle">{i18n.t(language.name)}</span>
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default SelectLanguage;
