import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Card } from "reactstrap";
import { isAllowed } from "../../../helpers/isAllowed";
import DataTableContainer from "../../Common/DataTableContainer";
import CustomInput from "../../Custom/CustomInput";
import CustomTableHeading from "../../Custom/CustomTableHeading";

const Table = ({
  data,
  selectItems,
  modal,
  setModal,
  onDeleteClick,
  isDeleting,
  navigateToPage,
  setPageSize,
  isRefetching,
  query,
  setQuery
}) => {
  const { t } = useTranslation();

  const { user } = useSelector(state => state.admin);

  const columns = [
    {
      name: <CustomTableHeading>ID</CustomTableHeading>,
      selector: (row) => row.id,
    },
    {
      name:      
      <CustomInput
        placeholder="Name"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        data-filtered={!!query}
      />,
      selector: (row) => row.name,
    },
    {
      name: <CustomTableHeading>Email</CustomTableHeading>,
      selector: (row) => row.email,
    },
    {
      name: <CustomTableHeading>Created at</CustomTableHeading>,
      selector: (row) => row.created_at ? moment(row.created_at).format("D MMM, YYYY") : '-',
    },

  ];
  

  return (
    <Card>
      <DataTableContainer
        selectableRows={isAllowed(user)}
        data={data.list}
        paginationTotalRows={data.total}
        isDeleting={isDeleting}
        handleDelete={onDeleteClick}
        modal={modal}
        setPageSize={setPageSize}
        paginationServer
        loading={isRefetching}
        setModal={setModal}
        selectItems={selectItems}
        navigateToPage={navigateToPage}
        columns={columns}
        path="users"
      />
    </Card>
  );
};

export default Table;
