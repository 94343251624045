import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { foodApi } from '../../../apis/Food/api'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import DoughAndBreadForm from '../../../Components/Food/DoughAndBread/Form'
import PageContainer from '../../../Components/Layout/PageContainer'
import { useFetchByLang } from '../../../hooks/useFetchByLang'

const UpdateDoughAndBread = () => {

  const {id} = useParams();

  const [initialValues, setInitialValues] = useState(null);

  const {isLoading, isRefetching, refetch} = useQuery(['one-dough-bread'], () => foodApi.doughAndBread.getOne(id, language), {
    enabled: false,
    cacheTime: 0,
    onSuccess({data: {data}}) {
      setInitialValues(data);
    }
  })

  const { language, setLanguage } = useFetchByLang(refetch);
    
  return (
    <PageContainer isLoading={isLoading || isRefetching}>
      <BreadCrumb pageTitle="Dough and Bread" title="Update" to="food/dough-bread"/>
      {initialValues &&
        <DoughAndBreadForm 
          type="update"
          initialValues={initialValues}
          language={language}
          setLanguage={setLanguage}
        />
      }
    </PageContainer>
  )
}

export default UpdateDoughAndBread