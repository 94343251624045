import React from "react";
import CommonForm from "./CommonForm";

const OrderTab = ({ values, handleChange }) => {
  return (
    <>
      <CommonForm
        handleChange={handleChange}
        values={values}
        name="order"
        checkboxName="order_send_sms"
        inputName="order_sms_text"
        keywordsName="order_sms_keywords"
      />
    </>
  );
};

export default OrderTab;
