import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import CustomLabel from '../../Custom/CustomLabel'
import { useQuery } from '@tanstack/react-query'
import { getGlobalSettingsApi, setGlobalSettingsApi } from '../../../apis/Settings/webSettingsApi'
import { useForm } from 'react-hook-form'
import LoaderComponent from '../../Common/LoaderComponent'
import CustomButton from '../../Custom/CustomButton'
import toastAlert from '../../../helpers/toastAlert'
import CustomOption from '../../Custom/CustomOption'

const GlobalSettingsTab = () => {

    const [initialValues, setInitialValues] = useState(null);

    const { register, handleSubmit, reset } = useForm();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const { isLoading, isRefetching } = useQuery(['global-settings'], getGlobalSettingsApi, {
        onSuccess({data: { data }}) {
            setInitialValues(data);
        }
    })

    const submitForm = async (data) => {
        setIsSubmitting(true);
        try {
            await setGlobalSettingsApi(data)
            toastAlert({ type: 'success' })
        } catch (error) {
            toastAlert({ type: 'error' })
        } finally {
            setIsSubmitting(false);
        }
    }

    useEffect(() => {
        if (initialValues) reset(initialValues)
    }, [initialValues])

  return (
    <form onSubmit={handleSubmit(submitForm)}>
        <Row className='p-3 gy-3'>
            <LoaderComponent isLoading={isLoading || isRefetching}>

                <Col lg={6}>
                    <CustomLabel>Logo side</CustomLabel>
                    <select className='form-select' {...register('logo_side')}>
                        <CustomOption value="">Select...</CustomOption>
                        {[
                            {
                                label: 'Top',
                                value: 'top'
                            },
                            {
                                label: 'Bottom',
                                value: 'bottom'
                                }
                        ].map((side, index) => (
                                <CustomOption key={index} value={side.value}>{side.label}</CustomOption>
                            ))}
                    </select>
                </Col>

                <Col lg={6} className='d-flex align-items-end'>
                    <div className="form-check form-switch">
                        <input {...register('login_guest')} className="form-check-input fs-16" type="checkbox" role="switch" id="login_as_guest" />
                        <CustomLabel className="form-check-label" htmlFor="login_as_guest">Login as guest</CustomLabel>
                    </div>
                </Col>

                <Col lg={6}>
                    <div className="form-check form-switch">
                        <input {...register('ticket_min')} className="form-check-input fs-16" type="checkbox" role="switch" id="ticket_min" />
                        <CustomLabel className="form-check-label" htmlFor="ticket_min">Minimalized ticket</CustomLabel>
                    </div>
                </Col>

                <Col lg={6}>
                    <div className="form-check form-switch">
                        <input {...register('show_food_number')} className="form-check-input fs-16" type="checkbox" role="switch" id="show_food_number" />
                        <CustomLabel className="form-check-label" htmlFor="show_food_number">Show food number</CustomLabel>
                    </div>
                </Col>

                <Col lg={12}>
                    <CustomButton
                        className="btn btn-success"
                        isLoading={isSubmitting}
                    >   
                        Save
                    </CustomButton>
                </Col>
            </LoaderComponent>
        </Row>
    </form>
  )
}

export default GlobalSettingsTab