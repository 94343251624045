import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Card, Col, Row } from 'reactstrap'
import { formatExample } from '../../../helpers/formatExample'
import useActions from '../../../hooks/useActions'
import { getPathname } from '../../../Layouts/LayoutMenuData'
import { createFoodAction, getFoodDataAction, updateFoodAction } from '../../../redux/actions/foodActions'
import CustomButton from '../../Custom/CustomButton'
import CustomInput from '../../Custom/CustomInput'
import CustomLabel from '../../Custom/CustomLabel'
import ComponentItems from './Update/Items'
import SelectLanguage from '../../Common/SelectLanguage'

const ComponentForm = ({type, initialValues = null, language, setLanguage, afterSubmit, preSelectedType}) => {

    const {register, handleSubmit, reset, setValue} = useForm();
    
    const dispatch = useDispatch();

    const history = useHistory();

    const {isLoading, onLoading, onFinally, onError} = useActions();

    const [checkedFoodTypes, setCheckedFoodTypes] = useState([]);

    const { foodTypes: { list: foodTypes } } = useSelector(state => state.food);

    const submitForm = (data) => {
        onLoading();
        if (type === 'create') {
            dispatch(createFoodAction({
                field: 'components',
                data: data,
                language
            }, {
            onSuccess(data) {
                onFinally();
                if (afterSubmit) afterSubmit(data)
                else history.push(getPathname('food/components'))
            },
            onError
            }))
        } else {
            dispatch(updateFoodAction({
                field: 'components',
                id: initialValues.id,
                data: data,
                language
            }, {
                onSuccess() {
                    onFinally();
                },
                onError
            }))
        }
    }

    const handleCheckChange = (id) => {
        if (checkedFoodTypes.includes(id)) {
            setCheckedFoodTypes(prev => prev.filter(typeId => typeId !== id));
        } else {
            setCheckedFoodTypes(prev => ([
                ...prev,
                id
            ]))
        }
    }

    useEffect(() => {
        dispatch(getFoodDataAction({
            field: 'foodTypes'
        }))
    }, [])

    useEffect(() => {
        setValue('types', checkedFoodTypes);
    }, [checkedFoodTypes])
    
    useEffect(() => {
        if (initialValues) {
            reset((() => {
                const {types, ...rest} = initialValues;
                return rest
            })())
            setCheckedFoodTypes(initialValues.types.map(type => type.id));
        }
    }, [initialValues])
    
    useEffect(() => {
        if (type === 'create' && preSelectedType) {
            handleCheckChange(preSelectedType)
        }
    }, [preSelectedType])

  return (

    <React.Fragment>
        <Row className='gy-3'>
            <Col lg={type === 'create' ? 12 : 3}>
                    <Card className='p-3'>
                        {type === 'update' && 
                            <div className='d-flex justify-content-end'>
                                <SelectLanguage
                                    language={language}
                                    onChange={lang => setLanguage(lang)}
                                /> 
                            </div>
                        }
                            <Row className='gy-4 mt-1'>
                                <Col lg={12} md={6} sm={6}>
                                    <CustomLabel translatable>
                                        Name
                                    </CustomLabel>

                                    <CustomInput 
                                        type="text"
                                        {...register('name')}
                                        placeholder={formatExample("Bread")}
                                    />
                                </Col>
                            </Row>

                            <Row className='mt-1 gy-3'>
                                <Col>
                                    <div className='border py-2 px-3' style={{width: 'max-content'}}>
                                        <CustomLabel className="form-check-label mb-2" htmlFor="active">Type</CustomLabel>
                                        <div 
                                            style={{
                                                display: 'grid',
                                                // gridTemplateColumns: 'repeat(3, 1fr)'
                                            }}
                                        >
                                            {foodTypes.map(type => (
                                                <div
                                                    className="form-check form-check-primary"
                                                    key={type.id}
                                                >
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id={`type-${type.id}`}
                                                        checked={checkedFoodTypes.includes(type.id)}
                                                        onChange={(e) => handleCheckChange(type.id)}
                                                    />
                                                    <CustomLabel className='form-check-label' htmlFor={`type-${type.id}`}>{type.name}</CustomLabel>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Col>

                                <Col lg={12}>
                                    <CustomButton
                                        type="button"
                                        className="btn-success"
                                        isLoading={isLoading}
                                        onClick={handleSubmit(submitForm)}
                                    >
                                        {type === 'create' ? 'Create' : 'Update'}
                                    </CustomButton>
                                </Col>

                            </Row>
                    </Card>
            </Col>

            {type === 'update' && 
                <Col lg={9}>
                    <ComponentItems 
                        language={language}
                    />
                </Col>
            }

        </Row>


    </React.Fragment>
  )
}

export default ComponentForm