import React from "react";
import { useSelector } from "react-redux";
import { Card } from "reactstrap";
import { isAllowed } from "../../../helpers/isAllowed";
import DataTableContainer from "../../Common/DataTableContainer";
import CustomTableHeading from "../../Custom/CustomTableHeading";

const GroupsTable = ({
  data,
  onDeleteClick,
  selectItems,
  isDeleting,
  modal,
  setModal,
}) => {

  const { user } = useSelector(state => state.admin);
  
  const columns = [
    {
      name: <CustomTableHeading>ID</CustomTableHeading>,
      selector: (row) => row.id,
    },
    {
      name: <CustomTableHeading>Name</CustomTableHeading>,
      selector: (row) => row.name,
    },
    {
      name: <CustomTableHeading>Code</CustomTableHeading>,
      selector: (row) => row.code,
    },
    {
      name: <CustomTableHeading>Created at</CustomTableHeading>,
      selector: (row) => row.created_at,
    },
  ];

  return (
    <Card>
      <DataTableContainer
        data={data}
        columns={columns}
        setModal={setModal}
        selectableRows={isAllowed(user)}
        modal={modal}
        selectItems={selectItems}
        handleDelete={onDeleteClick}
        isDeleting={isDeleting}
        path="groups"
      />
    </Card>
  );
};

export default GroupsTable;
