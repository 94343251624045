import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Card, CardBody, Col, Row } from 'reactstrap'
import CustomButton from '../../Custom/CustomButton'
import CustomLabel from '../../Custom/CustomLabel'
import Flatpickr from 'react-flatpickr';
import moment from 'moment'
import { zReportPdf, printPdf } from '../../../helpers/pdfHelper'
import { reportRqBody } from '../../../helpers/requests/Reports/ReportRqBody'
import { reportsApi } from '../../../apis/Reports/api'
import toastAlert from '../../../helpers/toastAlert'
import { errorMessage } from '../../../helpers/errorMessage'
import { useSelector } from 'react-redux'

const ReportsForm = () => {

    const [displayHTML, setDisplayHTML] = useState(null);

    const {register, handleSubmit, control, formState: { errors }, getValues} = useForm({
        defaultValues: {
            report_type: "vat",
            payment: {
                online: true,
                cash: true,
            },
            delivery_type: {
                takeaway: true,
                delivery: true,
                restaurant: true
            },
            platform: {
                App: true,
                Web: true,
                Mweb: true,
                POS: true,
            },
            daily: {
                date_from: moment(Date.now()).format("YYYY-MM-DD"),
            },
            monthly: {
                date_from: moment().startOf('month').format("YYYY-MM-DD"),
                date_to: moment().endOf('month').format("YYYY-MM-DD"),
            }
        },
    });

    const { paymentSettings } = useSelector(state => state.settings);

    const [loading, setLoading] = useState({
        daily: {
            display: false,
            printOld: false,
            print: false,
        },
        monthly: {
            display: false,
            printOld: false,
            print: false
        }
    })


    const submitForm = async (type, period, data) => {
        setLoading({
            ...loading,
            [period]: {
                ...loading[period],
                [type]: true
            }
        })
        try {
            const requestBody = reportRqBody(data, period);
            if (type === 'printOld') {
                const { data: resData}  = await reportsApi.reports.getData(requestBody);
                zReportPdf({ data: resData, period, settings: {
                    paymentSettings
                }})
            } else if (type === 'display') {
                setDisplayHTML(null);
                const {data: resData} = await reportsApi.reports.display(requestBody);
                setDisplayHTML(resData);
            } else if (type === 'print') {
                const { data: resData } = await reportsApi.reports.print(requestBody);
                printPdf(resData);
            }
        } catch (error) {
            toastAlert({ type: 'error' });
        } finally {
            setLoading({
                ...loading,
                [period]: {
                    ...loading[period],
                    [type]: false
                }
            })
        }
    }

  return (
    <form>
        <Card>
            <CardBody>

                <Row className='gy-3'>
                    <Col lg={6}>

                         <div>
                            <CustomLabel className="fs-16">Report</CustomLabel>
                        </div>

                        <div className='d-flex gap-3 w-100 border p-3'>
                            <div className="form-check">
                                <div className='form-check-option'>
                                    <input {...register('report_type')} value="vat" className="form-check-input " type="radio" id="vat" name="report_type"/>
                                    <CustomLabel className="form-check-label" htmlFor="vat">Vat</CustomLabel>
                                </div>
                            </div>

                            <div className="form-check">
                                <div className='form-check-option'>
                                    <input {...register('report_type')} value="product" className="form-check-input" type="radio" id="product" name="report_type"/>
                                    <CustomLabel className="form-check-label" htmlFor="product">Products</CustomLabel>
                                </div>
                        
                            </div>

                            <div className="form-check">
                                <div className='form-check-option'>
                                    <input {...register('report_type')} value="user" className="form-check-input" type="radio" id="user" name="report_type"/>
                                    <CustomLabel className="form-check-label" htmlFor="user">User</CustomLabel>
                                </div>
                            </div>

                            <div className="form-check">
                                <div className='form-check-option'>
                                    <input {...register('report_type')} value="delivery" className="form-check-input" type="radio" id="delivery" name="report_type"/>
                                    <CustomLabel className="form-check-label" htmlFor="delivery">Delivery</CustomLabel>
                                </div>
                            </div>

                        </div>

                    </Col>

                    <Col lg={6}>

                        <div>
                            <CustomLabel className="fs-16">Payment Method</CustomLabel>
                        </div>

                        <div className='d-flex gap-3 border p-3'>
                            <div className="form-check">
                                <div className='form-check-option'>
                                    <input {...register('payment.online')} className="form-check-input" type="checkbox" id="online_payment"/>
                                    <CustomLabel className="form-check-label" htmlFor="online_payment">Online</CustomLabel>
                                </div>
                            </div>
                        
                            <div className="form-check">
                                <div className='form-check-option'>
                                    <input {...register('payment.cash')} className="form-check-input" type="checkbox" id="cash"/>
                                    <CustomLabel className="form-check-label" htmlFor="cash">Cash</CustomLabel>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col lg={6}>
                        <div>
                            <CustomLabel className="fs-16">Delivery</CustomLabel>
                        </div>

                        <div className='d-flex gap-3 border p-3'>

                            <div className="form-check">
                                <div className='form-check-option'>
                                    <input {...register('delivery_type.takeaway')} className="form-check-input" type="checkbox" id="takeaway"/>
                                    <CustomLabel className="form-check-label" htmlFor="takeaway">Takeaway</CustomLabel>
                                </div>
                            </div>

                            <div className="form-check">
                                <div className='form-check-option'>
                                    <input {...register('delivery_type.delivery')} className="form-check-input" type="checkbox" id="delivery"/>
                                    <CustomLabel className="form-check-label" htmlFor="delivery">Delivery</CustomLabel>
                                </div>
                            </div>

                            <div className="form-check">
                                <div className='form-check-option'>
                                    <input {...register('delivery_type.restaurant')} className="form-check-input" type="checkbox" id="restaurant"/>
                                    <CustomLabel className="form-check-label" htmlFor="restaurant">Restaurant</CustomLabel>
                                </div>
                            </div>

                        </div>
                    </Col>

                    
                    <Col lg={6}>
                        
                        <div>
                            <CustomLabel className="fs-16">Platform</CustomLabel>
                        </div>

                        <div className='d-flex gap-3 border p-3'>

                            <div className="form-check">
                                <div className='form-check-option'>
                                    <input {...register('platform.Web')} className="form-check-input" type="checkbox" id="web"/>
                                    <CustomLabel className="form-check-label" htmlFor="web">Web</CustomLabel>
                                </div>
                            </div>

                            <div className="form-check">
                                <div className='form-check-option'>
                                    <input {...register('platform.App')} className="form-check-input" type="checkbox" id="app"/>
                                    <CustomLabel className="form-check-label" htmlFor="app">App</CustomLabel>
                                </div>
                            </div>

                            
                            <div className="form-check">
                                <div className='form-check-option'>
                                    <input {...register('platform.Mweb')} className="form-check-input" type="checkbox" id="mweb"/>
                                    <CustomLabel className="form-check-label" htmlFor="mweb">Mobile Web</CustomLabel>
                                </div>
                            </div>

                            <div className="form-check">
                                <div className='form-check-option'>
                                    <input {...register('platform.POS')} className="form-check-input" type="checkbox" id="POS"/>
                                    <CustomLabel className="form-check-label" htmlFor="POS">POS</CustomLabel>
                                </div>
                            </div>

                        </div>
                    </Col>

                </Row>

                <Row className='mt-3 g-3 justify-content-center'>

                        <Col lg={5}>
                            <div className='p-3 border d-flex flex-column align-items-center'>

                                <CustomLabel className="fs-18 mb-3">
                                    Daily Report
                                </CustomLabel>

                                <div>
                                    <div>
                                        <CustomLabel className="fs-16">Date</CustomLabel>
                                    </div>
                                    
                                    <div>
                                        <Controller 
                                            control={control}
                                            name="daily.date_from"
                                            render={({ name, field: { onChange } }) =>       
                                            <Flatpickr
                                                name={name}
                                                className="form-control"
                                                placeholder='From'
                                                options={{
                                                    dateFormat: "d M, Y",
                                                }}
                                                value={moment(getValues('daily.date_from')).format(("D MMM, YYYY"))}
                                                onChange={(dates) => onChange(moment(dates[0]).format("YYYY-MM-DD"))}
                                            />
                                        }
                                        />
                                        {errorMessage(errors, 'date_from')}
                                    </div>

                                </div>


                                <div className='d-flex gap-2 mt-4'>
                                    <CustomButton
                                        type="button"
                                        className="btn-success"
                                        onClick={handleSubmit((data) => submitForm('display', 'daily', data))}
                                        isLoading={loading.daily.display}
                                    >
                                        Display
                                    </CustomButton>

                                    {/* <CustomButton
                                        type="button"
                                        className="btn-success"
                                        onClick={handleSubmit((data) => submitForm('printOld', 'daily', data))}
                                        isLoading={loading.daily.printOld}
                                    >
                                        Print old
                                    </CustomButton> */}

                                    <CustomButton
                                        type="button"
                                        className="btn-success"
                                        onClick={handleSubmit((data) => submitForm('print', 'daily', data))}
                                        isLoading={loading.daily.print}
                                    >
                                        Print
                                    </CustomButton>
                                </div>
                            </div>
                        </Col>

                        <Col lg={5}>
                            <div className='p-3 border d-flex flex-column align-items-center'>

                                <CustomLabel className="fs-18 mb-3">
                                    Monthly Report
                                </CustomLabel>

                                <div>
                                    <div>
                                        <CustomLabel className="fs-16">Date</CustomLabel>
                                    </div>
                                    
                                    <div className='d-flex gap-3'>

                                        <div>
                                            <Controller 
                                                control={control}
                                                name="monthly.date_from"
                                                render={({ name, field: { onChange } }) =>       
                                                <Flatpickr
                                                    name={name}
                                                    className="form-control"
                                                    placeholder='From'
                                                    options={{
                                                        dateFormat: "d M, Y",
                                                    }}
                                                    value={moment(getValues('monthly.date_from')).format(("D MMM, YYYY"))}
                                                    onChange={(dates) => onChange(moment(dates[0]).format("YYYY-MM-DD"))}
                                                />
                                            }
                                            />
                                            {errorMessage(errors, 'date_from')}
                                        </div>

                                        <div>
                                            <Controller 
                                                control={control}
                                                name="monthly.date_to"
                                                render={({ name, field: { onChange } }) =>       
                                                <Flatpickr
                                                    name={name}
                                                    className="form-control"
                                                    placeholder='To'
                                                    options={{
                                                        dateFormat: "d M, Y",
                                                    }}
                                                    value={moment(getValues('monthly.date_to')).format(("D MMM, YYYY"))}
                                                    onChange={(dates) => onChange(moment(dates[0]).format("YYYY-MM-DD"))}
                                                />
                                                }
                                            />
                                        </div>
                                        
                                    </div>

                                </div>


                                <div className='d-flex gap-2 mt-4'>
                                    <CustomButton
                                        type="button"
                                        className="btn-success"
                                        onClick={handleSubmit((data) => submitForm('display', 'monthly', data))}
                                        isLoading={loading.monthly.display}
                                    >
                                        Display
                                    </CustomButton>


                                    {/* <CustomButton
                                        type="button"
                                        className="btn-success"
                                        onClick={handleSubmit((data) => submitForm('printOld', 'monthly', data))}
                                        isLoading={loading.monthly.printOld}
                                    >
                                        Print old
                                    </CustomButton> */}

                                    <CustomButton
                                        type="button"
                                        className="btn-success"
                                        onClick={handleSubmit((data) => submitForm('print', 'monthly', data))}
                                        isLoading={loading.monthly.print}
                                    >
                                        Print
                                    </CustomButton>

                                </div>
                            </div>
                        </Col>
                </Row>
            </CardBody>
        </Card>
        
        {!!displayHTML &&
            <Card className='p-3'>
                <div 
                    dangerouslySetInnerHTML={{
                        __html: displayHTML
                    }}
                />
            </Card>
        }
    </form>
  )
}

export default ReportsForm