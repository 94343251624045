import { format } from "date-fns";
import Flatpickr from "react-flatpickr";
import { deleteOpeningHourTimeApi } from "../../../apis/Orders/onlineSettingsApis";
import toastAlert from "../../../helpers/toastAlert";
import useActions from "../../../hooks/useActions";
import CustomButton from "../../Custom/CustomButton";

const DayTimeRow = ({
  time,
  openingHour,
  handleTimeChange,
  setOpeningHours,
  forBranch
}) => {
  const { isLoading, onLoading, onFinally } = useActions();

  const deleteTime = async (openingHourID, timeID) => {

    const time = openingHour.times.find((time) => time.id === timeID);

    try {
      if (!time?.isNew && !forBranch) {
        onLoading();
        await deleteOpeningHourTimeApi(timeID);
        toastAlert({ type: 'success' });
      }
      setOpeningHours((current) => {
        return current.map((item) => {
          if (item.id === openingHourID) {
            return {
              ...item,
              times: item.times.filter((time) => time.id !== timeID),
            };
          }
          return item;
        });
      });
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      onFinally();
    }
  };

  return (
    <>
      <td
        style={{
          paddingBottom: 6,
          paddingTop: 6,
        }}
      >
        <Flatpickr
          className="form-control"
          autoFocus={false}
          options={{
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
            time_24hr: true,
            mode: "time",
          }}
          value={time.start}
          onChange={([date]) => {
            handleTimeChange({
              id: openingHour.id,
              timeID: time.id,
              key: "start",
              value: format(date, "HH:mm"),
            });
          }}
        />
      </td>
      <td
        style={{
          paddingBottom: 6,
          paddingTop: 6,
        }}
      >
        <Flatpickr
          className="form-control"
          autoFocus={false}
          options={{
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
            time_24hr: true,
            mode: "time",
          }}
          value={time.end}
          onChange={([date]) => {
            handleTimeChange({
              id: openingHour.id,
              timeID: time.id,
              key: "end",
              value: format(date, "HH:mm"),
            });
          }}
        />
      </td>
      <td>
        <CustomButton
          onClick={() => deleteTime(openingHour.id, time.id)}
          isLoading={isLoading}
          color="danger"
          size="sm"
        >
          Delete
        </CustomButton>
      </td>
    </>
  );
};

export default DayTimeRow;
