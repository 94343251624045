import { foodActions } from "../slices/foodSlice";
import { foodApi } from "../../apis/Food/api";
import toastAlert from "../../helpers/toastAlert";

const noLangFields = ['vat']

export const getFoodDataAction = (query, actions) => async (dispatch) => {
  try {
    const { data } = await foodApi[query.field].getAll({
      ...(noLangFields.includes(query.field) ? {} : {
        lang: localStorage.getItem("I18N_LANGUAGE") || 'en'
      }),
      ...query?.params,
    });
    dispatch(
      foodActions.setData({
        field: query.field,
        data: data.data,
        total: data.meta?.total || null,
      })
    );
    if (actions?.onSuccess) {
      actions?.onSuccess();
    }
  } catch (error) {
    console.log("error", error);
    if (actions?.onError) {
      actions.onError(error.response);
    }
  }
};

export const createFoodAction = (data, actions) => async (dispatch) => {
  try {
    const { data: resData } = await foodApi[data.field].create(data.data, data.language && data.language);
    dispatch(
      foodActions.createData({
        field: data.field,
        data: resData.data,
      })
    );
    if (data.data.isFood && (data.data.image || data.data.menu_image)) {
      const formData = new FormData();

      if (data.data.image) {
        const file = data.data.image;
        const blob = new Blob([file], { type: file.type });
        formData.append("image", blob, file.name);
        formData.append("is_image_transparent", data.data.is_image_transparent);
      }

      if (data.data.menu_image) {
        const file = data.data.menu_image;
        const blob = new Blob([file], { type: file.type });
        formData.append("menu_image", blob, file.name);
        formData.append("is_menu_image_transparent", data.data.is_menu_image_transparent);
      }

      await foodApi.menu.file.update(resData.data.id, formData);
    }
    if (actions?.onSuccess) {
      actions.onSuccess(resData.data);
    }
    toastAlert({ type: 'success' });
  } catch (error) {
    if (actions?.onError) {
      actions.onError(error.response);
    }
    toastAlert({ type: 'error' });
  }
};

export const updateFoodAction = (data, actions) => async (dispatch) => {
  try {
    const { data: resData } = await foodApi[data.field].update(
      data.id,
      data.data,
      data.language
    );
    dispatch(
      foodActions.updateData({
        field: data.field,
        id: data.id,
        data: resData.data,
      })
    );
    if (data.data.isFood && (data.data.image || data.data.menu_image)) {
      const formData = new FormData();

      if (data.data.image) {
        const file = data.data.image;
        const blob = new Blob([file], { type: file.type });
        formData.append("image", blob, file.name);
        formData.append("is_image_transparent", data.data.is_image_transparent);
      }

      if (data.data.menu_image) {
        const file = data.data.menu_image;
        const blob = new Blob([file], { type: file.type });
        formData.append("menu_image", blob, file.name);
        formData.append("is_menu_image_transparent", data.data.is_menu_image_transparent);
      }

      await foodApi.menu.file.update(resData.data.id, formData);
    }
    if (actions?.onSuccess) {
      actions.onSuccess(resData.data);
    }
    toastAlert({ type: 'success' });
  } catch (error) {
    if (actions?.onError) {
      actions.onError(error.response);
    }
    toastAlert({ type: 'error' });
  }
};

export const deleteFoodAction = (data, actions) => async (dispatch) => {
  try {
    await foodApi[data.field].delete(data.data);
    dispatch(
      foodActions.deleteData({
        field: data.field,
        ids: data.data.ids,
      })
    );
    if (actions?.onSuccess) {
      actions.onSuccess();
    }
    toastAlert({ type: 'success' });
  } catch (error) {
    if (actions?.onError) {
      actions.onError(error.response);
    }
    toastAlert({ type: 'error' });
  }
};

// Printers

export const getAllPrintersAction = (actions) => async (dispatch) => {
  if (actions?.onLoading) {
    actions.onLoading();
  }
  try {
    const { data } = await foodApi.printers.getAll({
      lang: localStorage.getItem("I18N_LANGUAGE") || 'en',
    });
    dispatch(
      foodActions.setData({
        total: null,
        field: "printers",
        data: data.data,
      })
    );
  } finally {
    if (actions?.onFinally) {
      actions.onFinally();
    }
  }
};

export const deletePrintersAction = (ids, actions) => async (dispatch) => {
  actions.onLoading();

  try {
    await foodApi.printers.delete({ ids });
    dispatch(
      foodActions.deleteData({
        field: "printers",
        ids,
      })
    );
    actions.onSuccess();
  } catch (error) {
    actions.onFinally();
    toastAlert({ type: 'error' });
  }
};
