import { useState } from "react";
import useModal from "./useModal";

export default function useCreateUpdateModal() {
  const { isModalOpen, openModal, toggleModal } = useModal();

  const [type, setType] = useState("Create");
  const [initValues, setInitValues] = useState(null);

  const onAddClick = () => {
    setType("Create");
    openModal();
  };

  const onUpdateClick = (values) => {
    setInitValues(values);
    setType("Update");
    openModal();
  };

  return {
    onAddClick,
    onUpdateClick,
    toggleModal,
    isModalOpen,
    type,
    initValues,
  };
}
