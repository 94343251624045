export const renderErrorAsString = (error) => {
  if (!error) return null;
  if (typeof error === "string") {
    return error;
  }
  return "Something went wrong";
};

export const renderErrorAsArray = (error) => {
  if (!error) return null;
  if (Array.isArray(error)) {
    return error;
  }
  return [];
};

export const renderErrorAsObject = (error) => {
  if (!error) return null;
  if (!Array.isArray(error) && typeof error === "object") {
    return error;
  }
  return {};
};
