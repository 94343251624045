import { useState } from "react";

export default function useSelectItems() {
  const [selectedItems, setSelectedItems] = useState([]);

  const isDisable = selectedItems.length === 0;

  const selectItems = (items) => {
    setSelectedItems(items);
  };

  const getSelectedIds = () => {
    return selectedItems.map((item) => item.id);
  };

  return {
    selectedItems,
    isDisable,
    selectItems,
    getSelectedIds,
  };
}
