import axiosInstance from "..";

export const getSocialLogins = () => {
  return axiosInstance.get("/dash/oauth");
};

export const getOneSocialLogin = (id) => {
  return axiosInstance.post(`/dash/${id}`);
};

export const setSocialLogin = (id, data) => {
    return axiosInstance.post(`/dash/oauth/${id}`, data);
  };
