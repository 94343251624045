import { parse, format, isBefore, isEqual, isWithinInterval } from "date-fns";

export default function validateOpeningHour({ dayName, times = [] }) {
  for (let i = 0; i < times.length; i++) {
    const time = times[i];

    const startTime = parse(time.start.slice(0, 5), "HH:mm", new Date());
    const endTime = parse(time.end.slice(0, 5), "HH:mm", new Date());

    // console.log(startTime);

    if (isBefore(endTime, startTime)) {
      return `Start time must be less than end time on ${dayName} at time ${
        i + 1
      }`;
    }

    for (let j = i + 1; j < times.length; j++) {
      const nextTime = times[j];
      const startTimeNext = parse(
        nextTime.start.slice(0, 5),
        "HH:mm",
        new Date()
      );
      const endTimeNext = parse(nextTime.end.slice(0, 5), "HH:mm", new Date());
      if (isBefore(endTimeNext, startTimeNext)) {
        return `Start time must be less than end time on ${dayName} at time ${
          j + 1
        }`;
      }
      if (
        isWithinInterval(startTime, {
          start: startTimeNext,
          end: endTimeNext,
        }) ||
        isWithinInterval(endTime, { start: startTimeNext, end: endTimeNext }) ||
        isWithinInterval(startTimeNext, { start: startTime, end: endTime }) ||
        isWithinInterval(endTimeNext, { start: startTime, end: endTime })
      ) {
        return `Times conflict on ${dayName}`;
      }
    }
  }

  return null;
}
