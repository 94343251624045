import React from 'react'
import SetupActions from '../SetupActions'
import { useBranches } from '../../../hooks/useQueryHelpers'
import BootstrapTable from '../../Common/BootstrapTable';
import CustomButton from '../../Custom/CustomButton';
import useFormModal from '../../../hooks/useFormModal';
import BranchModal from './Modal';
import StatusIcon from '../../Common/StatusIcon';

const BranchesSetup = ({ id, next, skip }) => {

  const { branches, createBranch, updateBranch } = useBranches();

  const { isOpen: modalOpened, toggleModal, type, openModal, initialValues } = useFormModal();

  const columns = [
    {
      name: 'Name',
      selector: row => row.name,
    },

    {
      name: 'Active',
      selector: row => <StatusIcon status={row.active} />,
    }
  ]

  return (
    <div>

      <BranchModal 
          type={type}
          isOpen={modalOpened}
          toggleModal={toggleModal}
          initialValues={initialValues}
          createBranch={createBranch}
          updateBranch={updateBranch}
      />

      <div className='hstack gap-2'>
          <CustomButton 
            className="btn-success"
            onClick={() => openModal("create")}
          >
            Create
          </CustomButton>
      </div>

      <div className='mt-3'>
        <BootstrapTable 
          columns={columns}
          data={branches}
          clickableRows
          onRowClick={(row) => openModal('update', row)}
        />
      </div>

      <SetupActions id={id} submit={() => next(id)} skip={skip} />

    </div>
  )
}

export default BranchesSetup